import React, {Fragment, useEffect, useState} from "react";
import {Card, Empty, notification, Segmented, Table, Typography} from "antd";
import {AppstoreOutlined, BorderOutlined} from '@ant-design/icons'
import {ColumnsType} from "antd/es/table";
import {Column} from "@ant-design/plots";
import {
    DataType,
    dateColumn, fillColor, formattedCriticalJointsConfigs,
    formattedPreliminaryInspectionConfigs,
    formattedTotalInspectionConfigs, PASS_PERCENTAGE
} from "../../utils/reportConstants";
import {processInspectionPassingData} from "../../utils/report";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";
import {ColumnGroupType} from "antd/lib/table/interface";


interface TotalInspectionBody {
    range: string
    trusses: number
    total_out: number
    total_out_percent: number
}

interface PreliminaryInspectionBody {
    range: string
    preliminary_out: number,
    preliminary_out_percent: number,
    dimensions: number
    lumber: number
    plate: number
    teeth_gap: number
    preliminary_rotation: number
    mbr_gap: number
}

interface CriticalJointsBody {
    range: string
    joints: number
    detailed_out: number
    detailed_out_percent: number
    size: number
    gauge: number
    zero_tol_poly_available: number
    zero_tol_polygon: number
    detailed_rotation: number
}

interface InspectionPassingBody {
    range: string
    inspections: number
    preliminary: number
    joints: number
}

interface WeeklySummaryReportProps {
    totalInspections: TotalInspectionBody[]
    preliminaryInspections: PreliminaryInspectionBody[]
    criticalJoints: CriticalJointsBody[]
    inspectionPassing: InspectionPassingBody[]
}

const WeeklySummaryReport: React.FunctionComponent<WeeklySummaryReportProps> = ({
                                                                                    totalInspections,
                                                                                    preliminaryInspections,
                                                                                    criticalJoints,
                                                                                    inspectionPassing
                                                                                }) => {
    const [data, setData] = useState<any>([])
    const [layout, setLayout] = useState<string>('full')

    const load = () => {
        let tableData = []
        for(let i=0; i<totalInspections.length; i++) {
            if(
                totalInspections[i].range===preliminaryInspections[i].range &&
                totalInspections[i].range===criticalJoints[i].range
            ) {
                tableData.push({...totalInspections[i], ...preliminaryInspections[i], ...criticalJoints[i]})
            } else {
                notification.error({message: <Trans i18nKey={'weekly_summary_report.date_range_mismatch'}>Found mismatch in date range counts</Trans>})
            }
        }
        setData(tableData)
    }

    useEffect(() => {
        load()
    }, [])

    const columns: DataType[] = [
        dateColumn,
        {
            key: 'total_inspections',
            title: 'Total Inspections',
            children: formattedTotalInspectionConfigs
        },
        {
            key: 'preliminary_inspections',
            title: 'Preliminary Inspections',
            children: formattedPreliminaryInspectionConfigs
        },
        {
            key: 'critical_joints',
            title: 'Critical Joints',
            children: formattedCriticalJointsConfigs
        }
    ]

    const { Title } = Typography
    const level = 5

    const {t} = useTranslation();

    return <Fragment>
        <Segmented
            style={{margin: 10}}
            options={[
                {
                    label: <Trans i18nKey={'weekly_summary_report.full_view'}>Full View</Trans>,
                    value: 'full',
                    icon: <BorderOutlined/>,
                },
                {
                    label: <Trans i18nKey={'weekly_summary_report.split_view'}>Split View</Trans>,
                    value: 'split',
                    icon: <AppstoreOutlined/>,
                },
            ]}
            onChange={value => setLayout(value as string)}
        />
        {layout==='full' && <div style={{overflowX: 'auto', margin: 10}}>
            <Table
                columns={columns?.map(col => {return {
                    ...col,
                    title: t(`weekly_summary_report.${col.key}`),
                    children: col.children?col.children
                        .map((c:any)=>{return {
                            ...c,
                            title: t(`weekly_summary_report.${c.key}`, c.title)
                        }}):undefined
                }})}
                dataSource={data}
                bordered
                size="middle"
            />
        </div>}
        {layout==='split' && <div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}><Trans i18nKey={'weekly_summary_report.total_inspections'}>Total Inspections</Trans></Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedTotalInspectionConfigs].map(col => {return {
                        ...col,
                        title: t(`weekly_summary_report.${col.key}`)
                    }})}
                    dataSource={totalInspections as any}
                />
            </div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}><Trans i18nKey={'weekly_summary_report.preliminary_inspections'}>Preliminary Inspections</Trans></Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedPreliminaryInspectionConfigs].map(col => {return {
                        ...col,
                        title: t(`weekly_summary_report.${col.key}`)
                    }})}
                    dataSource={preliminaryInspections as any}
                />
            </div>
            <div style={{marginBottom: 25}}>
                <Title level={level} style={{textAlign: "center", margin: 10, marginBottom: 20}}><Trans i18nKey={'weekly_summary_report.critical_joints'}>Critical Joints</Trans></Title>
                <Table
                    bordered
                    columns={[dateColumn, ...formattedCriticalJointsConfigs].map(col => {return {
                        ...col,
                        title: t(`weekly_summary_report.${col.key}`)
                    }})}
                    dataSource={criticalJoints as any}
                />
            </div>
        </div>}
        <Card title={<Trans i18nKey={'weekly_summary_report.pass_percentage'}>PASS_PERCENTAGE</Trans>}>
            {inspectionPassing?<Column
                color={fillColor}
                style={{margin: 10}}
                isGroup={true}
                seriesField={'name'}
                xField={'range'}
                yField={'percent'}
                yAxis={{
                    title: {
                        text: `${t('weekly_summary_report.percentage', 'Percentage %')}`
                    }
                }}
                meta={{
                    'name':{
                        formatter: (value: any, index?: number) => `${t(`weekly_summary_report.${value}`, value)}`
                    }
                }}
                data={processInspectionPassingData(inspectionPassing)}
            />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Card>
    </Fragment>
}

export default WeeklySummaryReport