import React, {FunctionComponent} from "react";
import {Tag} from "antd";
import {configs, fetchSpanData} from "../../api";
import {ConfigResponse, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {handleScroll} from "../../utils/util";
import {useLocation} from "react-router-dom";
import CustomPageHeader from "./CustomPageHeader";
import {END_DATE_VALUE, LOCATION_ID_VALUE, START_DATE_VALUE} from "../../utils/constants";
import {Trans} from "../lib/CustomTransComponent";

interface SpanBreakdownProps {
    location_id: number | undefined
}

const SpanBreakdown: FunctionComponent<SpanBreakdownProps> = ({location_id = LOCATION_ID_VALUE}) => {
    const location: {pathname: string, state: {[key: string]: string}} = useLocation()

    return <TableV2
        header={
            <CustomPageHeader
                title={<Trans i18nKey="span_breakdown" defaults="Span Breakdown" />}
                start_date={location?.state?.start_date}
                end_date={location?.state?.end_date}
                quarter_date={location?.state?.quarter_date}
                quarter_name={location?.state?.quarter_name}
                weeks={location?.state?.weeks}
                remove_back_button={location?.state?.start_date === undefined}
            />
        }
        schema="span_breakdown"
        fetchConfig={async (schema: string) => {
            const response = (await configs(schema)) || {};
            response.data.rows = response.data.rows.map((row: any) => {
                if (row.name) {
                    row.title = <Trans i18nKey={row.name} defaults={row.title} />;
                }
                return row;
            });
            return response as ConfigResponse;
        }}
        fetchData={async (request) => {
            const direction = request?.options?.inbuilt?.sort.direction || 'ascend';
            handleScroll();
            if (location_id) {
                const payload = {
                    location_id,
                    orderBy: [direction],
                    start_date: location?.state?.start_date || START_DATE_VALUE,
                    end_date: location?.state?.end_date || END_DATE_VALUE,
                };
                const response = await fetchSpanData(payload);
                return response as Response;
            } else {
                return {
                    data: {
                        statusCode: 200,
                        rows: [],
                    },
                };
            }
        }}
        customRenderer={{
            span_conforming: (value, row, index, column) => (
                <Tag color={value ? 'success' : 'error'}>
                    {value ? <Trans i18nKey={`passed`} defaults={`Passed`} /> : <Trans i18nKey={`failed`} defaults={`Failed`} />}
                </Tag>
            ),
        }}
        scroll={{ y: 'calc(100vh - 441px)', x: '100%' }}
    />
}

export default SpanBreakdown;
