import React, {Fragment, useEffect, useState} from 'react';
import {Alert, Col, PageHeader, Row, Space, Typography, Tag, Button} from "antd";
import {Page, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {TableFilter} from "./filters/TableFilter";

import {fetch, configs, getCompanyCSV, getLocationCSV} from "../api";
import {MapToFilter} from "./filters/mapping";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import CustomDropDown from "./lib/CustomDropDown";
import moment from "moment";
import { Register } from './Register';
import {useLocation} from "react-router-dom";
import {getOrdering} from "./filters/ordering";
import {LOCATION_EDIT} from "../utils/constants";
import { ConfigsContext } from './configs/ConfigsProvider';
import {handleScroll} from "../utils/util";
import {writeToCSV} from "../utils/functions";
import {Trans} from "./lib/CustomTransComponent";


interface LocationsTableHeaderProps{
    schema:string
    row?:number
    initialFilter?: {[key:string]: any}
    fetchCompanies: () => void
    fetchLocations: () => void
}

const fetchLocationData = async (values: {[key:string]: any}, page?: Page, {column='id', direction='asc'}:{column?:string, direction?:string}={})=>{
    const filters = MapToFilter(values)
    const orderBy = getOrdering(column, direction);
    return await fetch({
        tableName: 'locations',
        orderBy: orderBy,
        filter:[...filters],
        page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
    }) || {}
}

const locationTableFilterOverride = (schema: string, element: FormElementProps, form: FormInstance<any>) => {
    if (element.name === 'state'){
        return <CustomDropDown
            key={element.name}
            schema={schema}
            name={element.name}
            title={element.title}
            tableName={'state_ref'}
            filter={'country_code'}
            filterValue={form.getFieldValue('country')}
            enum_map={['code', 'name']}
        />
    }
    return undefined
}

const LocationsTableHeader: React.FunctionComponent<LocationsTableHeaderProps> = (props:LocationsTableHeaderProps)=>{
    const export_to_csv = async (customData: any) => {
        let arrayFilters: object[] = [];
        try {
            if (Object.keys(customData).length > 0) {
                for (let key in customData.values) {
                    if (customData.values[key] !== undefined && customData.values[key] !== "") {
                        arrayFilters = [...arrayFilters, {name: key, value: customData.values[key]}]
                    }
                }
            }
            const res = await getLocationCSV(arrayFilters);

            // Download CSV.
            if (res.status === 200) {
                writeToCSV(res, 'LocationExport.csv')
            }
        } catch (e) {
            console.log("Error in CSV file export:", e)
        }
    }
    return <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
        <Row>
            <Col span={12}>
                <PageHeader title={<Trans i18nKey={`locations_page.locations`} defaults={`Locations`} />} />
            </Col>
            <Col span={12}>
                <TableContext.Consumer>{table =>
                    <Fragment>
                        {
                            <Button onClick={() => {
                                export_to_csv(table?.customData)
                            }
                            }><Trans i18nKey={`export_to_csv`} defaults={`Export to CSV`} /></Button> }
                    </Fragment>}
                </TableContext.Consumer>
                <Register
                    title={<Trans i18nKey={`locations_page.register_new_location`} defaults={`Register New Location`} />}
                    schema={'locations_registration'}
                    tableName={'locations'}
                    btnText={<Trans i18nKey={`locations_page.register_new_location`} defaults={`Register New Location`} />}
                    fetchCompanies={props.fetchCompanies}
                    fetchLocations={props.fetchLocations}
                />
            </Col>
        </Row>
        <TableFilter
            schema={props.schema}
            fetch={fetchLocationData}
            visibilityValidator={(schema: string, element: any, form: any) => {
                const country = form.getFieldValue('country');
                switch (element.title){
                    case 'State':
                    case 'Estado':
                    case 'État':
                        return country === 'US'
                    case 'Province':
                    case 'Provincia':
                        return country === 'CA'
                    case 'Zip code':
                        return country === 'US'
                    case 'Postal code':
                        return country === 'CA'
                    default:
                        return true
                }
            }}
            overrideComponent={locationTableFilterOverride}
            initialValue={props.initialFilter}
        />
    </div>
}

export const Locations:React.FunctionComponent<any> = (props:any) => {
    const location = useLocation()
    const [selectedLocation, setSelectedLocation] = useState<{[key:string]: any}>(location?.state?location?.state as {[key:string]: any}:{})
    const schema = 'locations'

    useEffect(() => {
        setSelectedLocation(location?.state?location?.state as {[key:string]: any}:{})
    }, [location?.state])
    return <React.Fragment>
            <TableV2
                // key={refresh}
                schema={schema}
                maxColumnWidth={400}
                header={
                    <LocationsTableHeader
                        schema='locations_filter'
                        initialFilter= {selectedLocation}
                        fetchCompanies={props.fetchCompanies}
                        fetchLocations={props.fetchLocations}
                    />
                }
                fetchConfig={async (schema) => {
                    const response = await configs(schema)
                    response.data.rows = response.data.rows.map((row: any) => {
                        if (row.name) {
                            row.title = <Trans i18nKey={`locations.${row.name}`} defaults={row.title}/>;
                        }
                        return row;
                    });
                    return  response
                }}
                fetchData={async (request) => {
                    handleScroll()
                    const res = await fetchLocationData({...selectedLocation, ...request.options.values}, request?.page, request.options.inbuilt?.sort)
                    if (selectedLocation){
                        setSelectedLocation({});
                    }
                    return res
                }}
                scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
                customRenderer={{
                    edit: (value, row, index, column) => {
                        const {id} = row
                        return <Register
                            pageName={'locations_page'}
                            title={LOCATION_EDIT}
                            btnText={<Trans i18nKey={'locations_page.update'} defaults={`Update`}/>}
                            schema={'locations_update'}
                            tableName={'locations'}
                            orderBy={['id']}
                            id={id}
                            row={{...row, company_id: row.company_id.toString()}}
                            fetchCompanies={props.fetchCompanies}
                            fetchLocations={props.fetchLocations}
                        />
                    },
                    is_active: (value, row, index, column) => {
                        return <Tag color={value?"green":"red"}>{value? <Trans i18nKey={`active`} defaults={`Active`} /> : <Trans i18nKey={`inactive`} defaults={`Inactive`} />}</Tag>
                    },
                    created_timestamp: (value, row, index, column) => {
                        return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                    }
                }}
            />
    </React.Fragment>
}
