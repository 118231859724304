import React, {Fragment} from "react";
import {ConfigResponse, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {configs, fetch_inspection} from "../../api";
import {Badge, Button, Card, Empty, Space, Statistic, Tabs, Tag, Typography} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import moment from "moment";
import { ConfigsContext } from "../configs/ConfigsProvider";
import {AVERAGE_PER_WEEK, SETUP_FREQUENCY} from "../../utils/reportConstants";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

export interface LineSetting {
    inspection_count: number
    line_id: number
    active_line: boolean
    line_name: string
    line_hours: string
    inspections: { [key: string]: any }[]
}

export interface Shift {
    shift_id: number
    shift_name: string
    active_shift: boolean
    shift_hours: string
    lines: LineSetting[]
}

interface HeaderProps {
    shift_name: string
    active_shift: boolean
    shift_hours: string
    line_name: string
    active_line: boolean
    line_hours: string
    inspection_count: number
}



interface InspectionDataProps{
    meta: HeaderProps
    data: { [key: string]: any }[]
}

const Header: React.FunctionComponent<HeaderProps> = ({
                                                          shift_name,
                                                          active_shift,
                                                          shift_hours,
                                                          line_name,
                                                          active_line,
                                                          line_hours,
                                                          inspection_count
                                                      }) => {
    const {t} = useTranslation();
    return <Space style={{padding: 10, background: '#ececec', marginBottom: 10}}>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.inspection_count'}>{'Inspection Count'}</Trans>}
                         value={inspection_count}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.shift_name'}>{'Shift Name'}</Trans>}
                         value={shift_name}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.shift_status'}>{'Shift Status'}</Trans>}
                         value={active_shift ? `${t('qc_report.active')}` : `${t('qc_report.inactive')}`}
                         prefix={<Badge status={active_shift ? 'success' : 'error'}/>}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.shift_hours'}>{'Shift Hours'}</Trans>}
                         value={shift_hours ? shift_hours : '0.00'}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.setup_name'}>{'Setup Name'}</Trans>}
                         value={line_name}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.setup_status'}>{'Setup Status'}</Trans>}
                         value={active_line ? `${t('qc_report.active')}` : `${t('qc_report.inactive')}`}
                         prefix={<Badge status={active_line ? 'success' : 'error'}/>}/></Card>
        <Card><Statistic title={<Trans i18nKey={'qc_report.titles.setup_hours'}>{'Setup Hours'}</Trans>}
                         value={line_hours ? line_hours : '0.00'}/></Card>
    </Space>
}

const InspectionData: React.FunctionComponent<InspectionDataProps> = ({meta, data}) => {
    return <Fragment>
        <TableV2
            header={<Header
                shift_name={meta.shift_name}
                active_shift={meta.active_shift}
                shift_hours={meta.shift_hours}
                line_name={meta.line_name}
                active_line={meta.active_line}
                line_hours={meta.line_hours}
                inspection_count={meta.inspection_count}
            />}
            schema={'quality_control_log'}
            fetchConfig={async (schema: string) => {
                const response = await configs(schema) || {}
                response.data.rows = response.data.rows.map((row: any) => {
                    if (row.name) {
                        row.title = <Trans i18nKey={`qc_report.table_headers.${row.name}`} defaults={row.title}/>;
                    }
                    return row;
                });
                return response as ConfigResponse
            }}
            fetchData={async () => ({
                data: {
                    statusCode: 200,
                        rows: data
                }
            })}
            customRenderer={{
                action: (value, {inspections_id}, index, column) => {
                    return <div>
                        <Space>
                            <Button onClick={async () => {
                                await fetch_inspection(inspections_id, "html")
                            }}><Trans i18nKey={'qc_report.view'}>{'View'}</Trans></Button>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={async () => {
                                await fetch_inspection(inspections_id, "pdf")
                            }}>PDF</Button>
                        </Space>
                    </div>
                },
                created_at: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                },
                status: (value, row, index, column) => {
                    const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}>{<Trans i18nKey={name}>{name}</Trans>}</Tag>
                    return <Space>
                        {value ? nameCard('passed', 'green') : nameCard('not_conforming', 'red')}
                        {row.sign_off && nameCard('corrected', 'blue')}
                    </Space>
                },
                date: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                }
            }}
        />
    </Fragment>
}

interface QualityControlLogProps {
    data: Shift[]
}

const QualityControlLog: React.FunctionComponent<QualityControlLogProps> = ({data}) => {
    const { TabPane } = Tabs;
    return <Fragment>
        {data?.length>0?<Tabs type="card">
            {data.map(({shift_id, shift_name, active_shift, shift_hours, lines}) => <TabPane tab={shift_name} key={shift_id}>
                <Tabs tabPosition={'left'}>
                    {lines?.map(({
                                     inspection_count,
                                     line_id,
                                     active_line,
                                     line_name,
                                     line_hours,
                                     inspections
                                 }) => <TabPane tab={line_name} key={line_id}>
                        <InspectionData
                            meta={{shift_name, active_shift, shift_hours, line_name, active_line, line_hours, inspection_count}}
                            data={inspections}
                        />
                    </TabPane>)}
                </Tabs>
            </TabPane>)}
        </Tabs>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

export default QualityControlLog;