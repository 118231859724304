import React, {Fragment, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {Badge, Button, Card, Checkbox, Empty, PageHeader, Space, Table, Typography} from "antd";
import {useReactToPrint} from "react-to-print";
import {DownloadOutlined} from "@ant-design/icons";
import {Bullet, Column, DualAxes, Line, Pie} from "@ant-design/plots";
import {MetaProps, OutOfConformance} from "./Reports";
import {
    ACTUAL,
    ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES,
    AVERAGE_DISTANCE,
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER,
    AVERAGE_FEET,
    AVERAGE_INSPECTION,
    AVERAGE_PER_WEEK,
    AVERAGE_ROTATION,
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION,
    averageReports,
    AVG,
    BACK,
    certificationReports,
    COUNT, CRITICAL_INSPECTIONS, crewSummaryReport,
    CRITICAL_JOINTS,
    CRITICAL_JOINTS_PER_INSPECTION,
    dateColumn,
    DISTANCE_IN_INCHES, fillColor,
    FLOOR_CRITICAL,
    FLOOR_TRUSSES,
    formattedCriticalJointsConfigs,
    formattedPreliminaryInspectionConfigs,
    formattedTotalInspectionConfigs,
    FOUR_OR_MORE_CRITICAL_JOINTS,
    FRONT,
    graphValues,
    HOURS,
    HOURS_PER_WEEK,
    INCHES,
    INSPECTION_COUNT,
    INSPECTION_INFORMATION_COUNT,
    INSPECTIONS,
    JOINT_BREAKDOWN,
    JOINT_BREAKDOWN_AS_PERCENTAGE,
    JOINT_BREAKDOWN_COUNT,
    JOINT_OVERVIEW,
    JOINT_QC_DETAIL,
    JOINT_STRESS_INDEX,
    JOINT_STRESS_INDEX_AVERAGE,
    jointReports,
    JOINTS,
    JOINTS_IN_DETAIL, LUMBER,
    MAX, MEDIAN,
    MEMBERS,
    MEMBERS_IN_DETAIL,
    MIN, MISPLACED_PLATES,
    NO_OF_INSPECTED,
    ONE_CRITICAL_JOINTS,
    OUT_OF_CONFORMANCE,
    OVERVIEW_AS_PERCENTAGE,
    PASS_PERCENTAGE,
    PERCENT_DEFECTS_OF_INSPECTIONS,
    PERCENT_DEFECTS_OF_JOINTS,
    PERCENT_OF_PLATES_UPSIZED,
    PERCENT_TRUSSES_OF_UPSIZED_PLATES,
    PERCENTAGE, PLATE,
    PLATES_UPSIZED,
    PLATES_UPSIZED_OVERVIEW,
    PRELIMINARY,
    PRELIMINARY_BREAKDOWN,
    PRELIMINARY_IN_DETAIL,
    PRELIMINARY_INSPECTIONS,
    reportTitles,
    ROOF_CRITICAL,
    ROOF_TRUSSES, ROTATED_PLATES,
    ROTATION_IN_DEGREES, SETUP_FREQUENCY,
    SQUARE_INCHES,
    summaryReportByCrew,
    summaryReports,
    TARGET,
    THREE_CRITICAL_JOINTS,
    TOTAL_CRITICAL_JOINTS,
    TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS,
    TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS,
    TOTAL_INSPECTIONS,
    TRUSS_COUNT,
    TRUSS_OVERVIEW,
    TRUSSES,
    TRUSSES_AS_PERCENTAGE,
    TRUSSES_IN_DETAIL,
    TRUSSES_PER_SHIFT_PER_WEEK,
    TWO_CRITICAL_JOINTS, UPSIZED_PLATES, VISUAL_INSPECTIONS,
    ZERO_CRITICAL_JOINTS
} from "../../utils/reportConstants";
import {LineSetting, Shift} from "./QualityControlLog";
import {plantShift, plantLineSetting} from "./PlantReport";
import {
    FrequencySummary,
    inchToFeet, InspectionDetail, mapTitle, mapTitleJoint,
    processData,
    processDefectFoundData,
    processFrequencySummaryData,
    processInspectionDetailData,
    processInspectionPassingData,
    processTitle,
    processTrussCountData,
    processTrussPercentData,
    processUpsizedPlatesData, TrussSelection
} from "../../utils/report";
import Score from "./Score";
import CommentReport from "./CommentReport";
import {Breakdown, Overview, SummaryByCrew} from "./CrewSummaryReport";
import {LogReportTable, Sign} from "./LogReport";
import {LocationsFilterBaseProps} from "../filters/LocationsFilter";
import {AuthContext} from "../user/AuthProvider";
import {SetupFrequency} from "./AverageReport";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface LocationProps {
    reportType: string
    reportData: any
    metaData: MetaProps
}

export interface ReportPDFProps extends Partial<LocationsFilterBaseProps>{

}

export const ReportPDF: React.FunctionComponent<ReportPDFProps> = (props) => {
    const [subReports, setSubReports] = useState<string[]>()
    const {t} = useTranslation();

    const locationHook = useLocation()
    const {reportType, reportData, metaData} = locationHook.state as LocationProps
    const getInspectionTitle = (title: String) => {
        let key, defaults;
        switch (title) {
            case summaryReports.INSPECTIONS_BY_DAY_TXT:
                key = summaryReports.INSPECTIONS_BY_DAY;
                defaults = summaryReports.INSPECTIONS_BY_DAY_TXT;
                break;
            case summaryReports.INSPECTIONS_BY_WEEK_TXT:
                key = summaryReports.INSPECTIONS_BY_WEEK;
                defaults = summaryReports.INSPECTIONS_BY_WEEK_TXT;
                break;
            case summaryReports.INSPECTIONS_BY_QUARTER_TXT:
                key = summaryReports.INSPECTIONS_BY_QUARTER;
                defaults = summaryReports.INSPECTIONS_BY_QUARTER_TXT;
                break;
            default:
                key = '';
                defaults = '';
                break;
        }
        return t(key, defaults);
    }
    const getJointTitle = (title: String) => {
        let key, defaults;
        switch (title) {
            case jointReports.JOINTS_BY_DAY_TXT:
                key = jointReports.JOINTS_BY_DAY;
                defaults = 'Joints By Day';
                break;
            case jointReports.JOINTS_BY_WEEK_TXT:
                key = jointReports.JOINTS_BY_WEEK;
                defaults = 'Joints By Week';
                break;
            case jointReports.JOINTS_BY_QUARTER_TXT:
                key = jointReports.JOINTS_BY_QUARTER;
                defaults = 'Joints By Quarter';
                break;
            default:
                key = '';
                defaults = '';
                break;
        }
        return <Trans i18nKey={key} defaults={defaults} />
    }
    const titles = Object.freeze<{[key: string]: string}>({
        inspectionInformation: averageReports.INSPECTION_INFORMATION,
        jointBreakdown: averageReports.JOINT_BREAKDOWN,
        dimensionBreakdown: averageReports.DIMENSION_BREAKDOWN,
        inspectionFrequency: averageReports.INSPECTION_FREQUENCY,
        inspectionFrequencyByCrew: averageReports.INSPECTION_FREQUENCY_BY_CREW,
        overallQCInspections: t(summaryReports.OVERALL_QC_INSPECTIONS),
        outOfConformanceVisual: t(summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY),
        outOfConformanceDetailed: t(summaryReports.OUT_OF_CONFORMANCE_JOINTS),
        outOfConformanceMembers: t(summaryReports.OUT_OF_CONFORMANCE_MEMBERS),
        overallOutOfConformance: t(certificationReports.OVERALL_OUT_OF_CONFORMANCE),
        defectsFound: t(certificationReports.DEFECTS_FOUND),
        upsizedPlates: t(certificationReports.UPSIZED_PLATES),
        placementTolerance: t(certificationReports.PLACEMENT_TOLERANCE),
        frequencySummary: t(certificationReports.FREQUENCY_SUMMARY),
        trussSelection: t(certificationReports.TRUSS_SELECTION),
        inspectionDetail: t(certificationReports.INSPECTION_DETAIL),
        scoring: t(certificationReports.CERTIFICATION_SCORING),
        summary: t(jointReports.SUMMARY),
        breakdown: t(jointReports.BREAKDOWN),
        jointsByRange: t(jointReports.JOINTS_BY_RANGE),
        crewSummary: t(crewSummaryReport.CREW_SUMMARY),
        platesUpsized: t(summaryReports.PLATES_UPSIZED),
        inspectionByRange: getInspectionTitle(reportData?.inspectionByRange?.title),
        totalInspections: TOTAL_INSPECTIONS,
        preliminaryInspections: PRELIMINARY_INSPECTIONS,
        criticalJoints: CRITICAL_JOINTS,
        inspectionPassing: PASS_PERCENTAGE,
        setupFrequency: SETUP_FREQUENCY.SUB_REPORT_TITLE
    })

    const qualityLogConfig = Object.freeze({
        crew_name: `${t('qc_report.table_headers.crew_id', 'Crew')}`,
        job_name: `${t('qc_report.table_headers.job_name', 'Job Number')}`,
        id: `${t('qc_report.table_headers.truss_id', 'Truss ID')}`,
        span_actual: `${t('qc_report.table_headers.span_actual', 'Span')}`,
        crew_hours: `${t('qc_report.table_headers.crew_hours', 'Hours')}`,
        qc_inspector: `${t('qc_report.table_headers.qc_inspector', 'QC Inspector')}`,
        build_at: `${t('qc_report.table_headers.build_at', 'Date Build')}`,
        date: `${t('qc_report.table_headers.date', 'Date Inspected')}`
    })

    const componentRef  = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: titles[reportType]
    });

    const load = () => {
        setSubReports(Object.entries(reportData).map(report => report[0]))
    }

    useEffect(() => {
        load()
    }, [])

    const jointBreakdownPercentage = (count: number) => {
        return parseFloat((count / reportData?.jointBreakdown?.total_truss_count * 100).toFixed(2))
    }

    const dimensionBreakdownPercentage = (count: number) => {
        const totalCount = reportData?.dimensionBreakdown?.roof?.total_count + reportData?.dimensionBreakdown?.floor?.total_count
        if(totalCount===0) return 0
        return parseFloat((count / totalCount * 100).toFixed(2))
    }

    const positionOfGraphValues = 'bottom'
    const certificationReportLabelColor = '#566573'

    const mapDefectFoundData = (defectsFound: any, type: 'defect' | 'defect_out' | 'joint' | 'inspection') => {
        let data = processDefectFoundData(defectsFound, type);
        return data.map(item => ({...item, type: t(`certification_summary.${item.type}`, item.type)}));
    }
    const mapUpsizedPlatesData = (inputData: any) => {
        let data = processUpsizedPlatesData(inputData);
        return data.map(item => ({...item, type: t(`certification_summary.${mapToKey(item.type)}`, item.type)}));
    }
    const mapFrequencySummaryData = (inputData: FrequencySummary[], isTruss: boolean) => {
        let data = processFrequencySummaryData(inputData, isTruss);
        return data.map(item => ({...item, category: t(`certification_summary.${item.category}`, item.category)}));
    }
    const mapTrussCountData = (inputData: TrussSelection[]) => {
        let data = processTrussCountData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapTrussPercentData = (inputData: TrussSelection[]) => {
        let data = processTrussPercentData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapInspectionDetailData = (inputData: InspectionDetail[]) => {
        let data = processInspectionDetailData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapData = (inputData: any, isUnitConvert?: boolean) => {
        let data = processData(inputData, isUnitConvert);
        return data.map(item => ({...item, category: t(`certification_summary.${item.category}`, item.category)}));
    }
    const mapToKey = (phrase: string) => {
        return phrase.replace(/\s+/g, '_').toUpperCase();
    }

    const { Text, Title } = Typography
    return <Fragment>
        <PageHeader title={<Trans i18nKey={'REPORTS_COMMON.GENERATE_PDF'}>Generate PDF</Trans>}/>
        <Space style={{margin: 10}}>
            <Checkbox.Group value={subReports} onChange={(list) => setSubReports(list as string[])}>
                {
                    reportType!=='quality_control' &&
                    reportType!=='comment' &&
                    reportType!=='daily_hours' &&
                    reportType!=='log_report' &&
                    Object.entries(reportData).map(report => <Checkbox value={report[0]}><Trans i18nKey={`REPORTS_COMMON.REPORT_TYPES.${report[0]}`}>{titles[report[0]]}</Trans></Checkbox>)
                }
            </Checkbox.Group>
            <Button
                disabled={subReports?.length===0}
                type={"primary"}
                icon={<DownloadOutlined />}
                onClick={handlePrint}
            >
                <Trans i18nKey={'REPORTS_COMMON.DOWNLOAD'}> Download</Trans>
            </Button>
        </Space>
        <div style={{border:'1px solid #ABB2B9', maxWidth: '45vw', marginLeft: '10vw', marginRight: '10vw', display: 'table'}}>
            <div ref={componentRef} style={{padding: '2vw'}}>
                <Title level={2} style={{textAlign: "center", color: '#4863A0'}}><Trans i18nKey={`REPORTS_COMMON.REPORT_TITLES.${reportType}`}>{reportTitles[reportType]}</Trans></Title>
                <div style={{paddingRight: 20, textAlign: "right"}}>
                    <ul style={{listStyle: 'none'}}>
                        <li>
                            <Text strong style={{color: '#566573'}}><Trans i18nKey={'REPORTS_COMMON.COMPANY'}>Company</Trans>:</Text><Text style={{color: '#ABB2B9'}}> {props.companies?.find(({id})=>id===props.companyID)?.name}</Text>
                        </li>
                        <li>
                            <Text strong style={{color: '#566573'}}><Trans i18nKey={'REPORTS_COMMON.LOCATION'}>Location</Trans>:</Text><Text style={{color: '#ABB2B9'}}> {props.locations?.find(({id})=>id===props.locationID)?.name}</Text>
                        </li>
                        {Object.entries(reportType==='log_report'?{...metaData, start_date:reportData.startDate, end_date: reportData.endDate}:metaData)?.map(([key, value]) => <li>
                            <Text strong style={{color: '#566573'}}><Trans i18nKey={`REPORTS_COMMON.TITLES.${key}`}>{processTitle(key)}</Trans>:</Text><Text style={{color: '#ABB2B9'}}> {value}</Text>
                        </li>)}
                        <AuthContext.Consumer>{auth=>
                            <li>
                                <Text strong style={{color: '#566573'}}><Trans i18nKey={`REPORTS_COMMON.TITLES.user`}>{processTitle('user')}</Trans>:</Text><Text style={{color: '#ABB2B9'}}> {auth?.user?.username}</Text>
                            </li>
                        }</AuthContext.Consumer>
                    </ul>
                </div>

                {/*Average report*/}
                {subReports?.includes('inspectionInformation') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION'}>{averageReports.INSPECTION_INFORMATION}</Trans>}
                    style={{pageBreakAfter: "always"}}
                >
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TITLE'}>{INSPECTION_INFORMATION_COUNT}</Trans>} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.inspectionInformation?.inspection_count?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: INSPECTION_COUNT
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            meta={{
                                'Count':{
                                    alias: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.COUNT', 'Count')}`
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TOTAL_INSPECTIONS', TOTAL_INSPECTIONS)}`,
                                    'Count': reportData?.inspectionInformation?.inspection_count
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TOTAL_CRITICAL_JOINTS', TOTAL_CRITICAL_JOINTS)}`,
                                    'Count': reportData?.inspectionInformation?.critical_joint_count
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.OUT_OF_CONFORMANCE', OUT_OF_CONFORMANCE)}`,
                                    'Count': reportData?.inspectionInformation?.out_of_conformance_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.TITLE'}>{AVERAGE_PER_WEEK}</Trans>} type={"inner"} style={{minWidth: '22vw'}}>
                        {reportData?.inspectionInformation?.inspections_per_week?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Average Per Week'}
                            yAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.AVERAGE_INSPECTION', AVERAGE_INSPECTION)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            meta={{
                                'Average Per Week':{
                                    alias: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.AVERAGE_PER_WEEK', 'Average Per Week')}`
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.INSPECTIONS', INSPECTIONS)}`,
                                    'Average Per Week': reportData?.inspectionInformation?.inspections_per_week
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.CRITICAL_JOINTS', CRITICAL_JOINTS)}`,
                                    'Average Per Week': reportData?.inspectionInformation?.out_of_conformance_per_week
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.JOINT_STRESS_INDEX', JOINT_STRESS_INDEX)}`,
                                    'Average Per Week': reportData?.inspectionInformation?.inspections_joint_jsi_average_per_week
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card type={"inner"} title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.TITLE'}>{CRITICAL_JOINTS_PER_INSPECTION}</Trans>} style={{minWidth: '15vw'}}>
                        {reportData?.inspectionInformation?.critical_joints_per_inspection?<Bullet
                            measureField={`${t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.MEASURES', 'measures')}`}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            style={{ height: '30vh'}}
                            data={[
                                {
                                    title: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.TITLE', CRITICAL_JOINTS_PER_INSPECTION)}`,
                                    ranges: [reportData?.inspectionInformation?.critical_joints_per_inspection * 1.2],
                                    [t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.MEASURES', 'measures')]: [reportData?.inspectionInformation?.critical_joints_per_inspection],
                                    target: reportData?.inspectionInformation?.critical_joints_per_inspection,
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card type={"inner"} title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.TITLE'}>{JOINT_STRESS_INDEX_AVERAGE}</Trans>} style={{minWidth: '15vw'}}>
                        {reportData?.inspectionInformation?.inspections_joint_jsi_average?<Bullet
                            measureField={`${t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.MEASURES', 'measures')}`}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            style={{ height: '30vh'}}
                            data={[
                                {
                                    title: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.TITLE', JOINT_STRESS_INDEX_AVERAGE)}`,
                                    ranges: [reportData?.inspectionInformation?.inspections_joint_jsi_average * 1],
                                    [t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.MEASURES', 'measures')]: [reportData?.inspectionInformation?.inspections_joint_jsi_average],
                                    target: reportData?.inspectionInformation?.inspections_joint_jsi_average,
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Joint Breakdown*/}
                {subReports?.includes('jointBreakdown') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN'}>{averageReports.JOINT_BREAKDOWN}</Trans>}
                    style={{pageBreakAfter: "always"}}
                >
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.TITLE'}>{JOINT_BREAKDOWN_COUNT}</Trans>} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.jointBreakdown?.total_truss_count?<Column
                            color={fillColor}
                            xField={'Joint Breakdown'}
                            yField={'Percentage'}
                            yAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.INSPECTION_COUNT', INSPECTION_COUNT)}`
                                }
                            }}
                            xAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.CRITICAL_JOINTS', CRITICAL_JOINTS)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            meta={{
                                'Percentage':{
                                    alias: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.PERCENTAGE', 'Percentage')}`
                                }
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', ZERO_CRITICAL_JOINTS)}`,
                                    'Percentage': reportData?.jointBreakdown['0']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', ONE_CRITICAL_JOINTS)}`,
                                    'Percentage': reportData?.jointBreakdown['1']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', TWO_CRITICAL_JOINTS)}`,
                                    'Percentage': reportData?.jointBreakdown['2']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', THREE_CRITICAL_JOINTS)}`,
                                    'Percentage': reportData?.jointBreakdown['3']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', FOUR_OR_MORE_CRITICAL_JOINTS)}`,
                                    'Percentage': reportData?.jointBreakdown['>=4']
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.TITLE'}>{JOINT_BREAKDOWN_AS_PERCENTAGE}</Trans>} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {reportData?.jointBreakdown?.total_truss_count?<Pie
                            angleField={`${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')}`}
                            colorField={'Joint Breakdown'}
                            radius={0.8}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.ZERO_CRITICAL_JOINTS', `${ZERO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(reportData?.jointBreakdown['0'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.ONE_CRITICAL_JOINTS', `${ONE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(reportData?.jointBreakdown['1'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.TWO_CRITICAL_JOINTS', `${TWO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(reportData?.jointBreakdown['2'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.THREE_CRITICAL_JOINTS', `${THREE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(reportData?.jointBreakdown['3'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.FOUR_OR_MORE_CRITICAL_JOINTS', `${FOUR_OR_MORE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(reportData?.jointBreakdown['>=4'])
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Dimension Breakdown*/}
                {subReports?.includes('dimensionBreakdown') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.DIMENSION_BREAKDOWN'}>{averageReports.DIMENSION_BREAKDOWN}</Trans>}
                    style={{pageBreakAfter: "always"}}
                    className="report-pdf-card-body-style"
                >
                    <Card
                        title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.TITLE'}>{ROOF_TRUSSES}</Trans>}
                        type={"inner"}
                        style={{pageBreakInside: "avoid", padding: '0px'}}
                        className="report-pdf-inside-card-body-style"
                        extra={<Text type={"secondary"}><Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.EXTRA'} values={{totalRoofCount: reportData?.dimensionBreakdown?.roof?.total_count, dimensionBreakdownPercentage:dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.roof?.total_count)}} default={`count: ${reportData?.dimensionBreakdown?.roof?.total_count}  (${dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.roof?.total_count)}%)`}/></Text>}
                    >
                        {reportData?.dimensionBreakdown?.roof?.total_count?<Space>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.SPAN'}>Roof Span</Trans>} type={"inner"} style={{minWidth: '27vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Roof Span'}
                                    yField={INCHES}
                                    yAxis={{
                                        title: {
                                            text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    meta={{
                                        [INCHES]:{
                                            alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX', MAX)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.roof?.span?.max
                                        },
                                        {
                                            'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.AVG', AVG)}`,
                                            [INCHES]:  reportData?.dimensionBreakdown?.roof?.span?.avg
                                        },
                                        {
                                            'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX', MEDIAN)}`,
                                            [INCHES]:  reportData?.dimensionBreakdown?.roof?.span?.median
                                        },
                                        {
                                            'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX', MIN)}`,
                                            [INCHES]:  reportData?.dimensionBreakdown?.roof?.span?.min
                                        }
                                    ]}
                                />
                            </Card>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.HEIGHT'}>Roof Height</Trans>} type={"inner"} style={{minWidth: '27vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Roof Height'}
                                    yField={INCHES}
                                    yAxis={{
                                        title: {
                                            text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    meta={{
                                        [INCHES]:{
                                            alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX', MAX)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.roof?.height?.max
                                        },
                                        {
                                            'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.AVG', AVG)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.roof?.height?.avg
                                        },
                                        {
                                            'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MEDIAN', MEDIAN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.roof?.height?.median
                                        },
                                        {
                                            'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MIN', MIN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.roof?.height?.min
                                        }
                                    ]}
                                />
                            </Card>
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card
                        title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.TITLE'}>{FLOOR_TRUSSES}</Trans>}
                        type={"inner"}
                        style={{pageBreakInside: "avoid"}}
                        extra={<Text type={"secondary"}><Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.EXTRA'} values={{totalFloorCount: reportData?.dimensionBreakdown?.floor?.total_count, dimensionBreakdownPercentage:dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.floor?.total_count)}} default={`count: ${reportData?.dimensionBreakdown?.floor?.total_count}  (${dimensionBreakdownPercentage(reportData?.dimensionBreakdown?.floor?.total_count)}%)`}/></Text>}
                    >
                        {reportData?.dimensionBreakdown?.floor?.total_count?<Space>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.SPAN'}>Floor Span</Trans>} type={"inner"} style={{minWidth: '25vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Floor Span'}
                                    yField={INCHES}
                                    yAxis={{
                                        title: {
                                            text: reportData?.dimensionBreakdown?.floor?.total_count
                                        }
                                    }}
                                    meta={{
                                        [INCHES]:{
                                            alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MAX', MAX)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.span?.max
                                        },
                                        {
                                            'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.AVG', AVG)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.span?.avg
                                        },
                                        {
                                            'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MEDIAN', MEDIAN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.span?.median
                                        },
                                        {
                                            'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MIN', MIN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.span?.min
                                        }
                                    ]}
                                />
                            </Card>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.HEIGHT'}>Floor Height</Trans>} type={"inner"} style={{minWidth: '25vw'}}>
                                <Column
                                    color={fillColor}
                                    xField={'Floor Height'}
                                    yField={INCHES}
                                    yAxis={{
                                        title: {
                                            text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    meta={{
                                        [INCHES]:{
                                            alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                        }
                                    }}
                                    label={{
                                        content: ({Inches}) => {
                                            return inchToFeet(Inches)
                                        },
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={[
                                        {
                                            'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MAX', MAX)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.height?.max
                                        },
                                        {
                                            'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.AVG', AVG)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.height?.avg
                                        },
                                        {
                                            'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MEDIAN', MEDIAN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.height?.median
                                        },
                                        {
                                            'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MIN', MIN)}`,
                                            [INCHES]: reportData?.dimensionBreakdown?.floor?.height?.min
                                        }
                                    ]}
                                />
                            </Card>
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.TITLE'}>{TRUSS_OVERVIEW}</Trans>} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        {(reportData?.dimensionBreakdown?.roof?.total_count || reportData?.dimensionBreakdown?.floor?.total_count)?<Pie
                            angleField={'Percentage'}
                            colorField={'Dimension Breakdown'}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ Percentage }) => `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.LABEL_CONTENT', `${Percentage} Trusses`, {Percentage})}`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            radius={0.8}
                            data={[
                                {
                                    'Dimension Breakdown': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.ROOF_TRUSSES', ROOF_TRUSSES)}`,
                                    'Percentage': reportData?.dimensionBreakdown?.roof?.total_count
                                },
                                {
                                    'Dimension Breakdown': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.FLOOR_TRUSSES', FLOOR_TRUSSES)}`,
                                    'Percentage': reportData?.dimensionBreakdown?.floor?.total_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Card>}

                {/*Inspection Frequency*/}
                {subReports?.includes('inspectionFrequency') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_FREQUENCY.TITLE'}>{averageReports.INSPECTION_FREQUENCY}</Trans>}
                    style={{pageBreakAfter: "always"}}
                >
                    {reportData?.inspectionFrequency.length>0?<Fragment>
                        {reportData?.inspectionFrequency?.map((shift: any) =>
                            <Card title={shift?.shift_name} type={"inner"} style={{pageBreakInside: "avoid"}}>
                                <Column
                                    color={fillColor}
                                    xField={'Inspections'}
                                    yField={'No of Inspections'}
                                    meta={{
                                        'No of Inspections':{
                                            alias: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY.NO_OF_INSPECTIONS', 'No of Inspection')}`
                                        }
                                    }}
                                    yAxis={{
                                        title: {
                                            text: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY.INSPECTION_COUNT', INSPECTION_COUNT)}`
                                        }
                                    }}
                                    label={{
                                        position: positionOfGraphValues,
                                        style: {
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    data={shift?.lines?.map((line: any) => ({
                                        'Inspections': line?.line_name,
                                        'No of Inspections': line?.inspections
                                    }))}
                                />
                            </Card>
                        )}
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Inspection Frequency By Crew*/}
                {subReports?.includes('inspectionFrequencyByCrew') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.TITLE'}>{averageReports.INSPECTION_FREQUENCY_BY_CREW}</Trans>}
                    style={{pageBreakAfter: "always", pageBreakInside: "avoid"}}
                >
                    {reportData?.inspectionFrequencyByCrew.length?<Column
                        color={fillColor}
                        xField={'Inspections'}
                        yField={'No of Inspections'}
                        meta={{
                            'No of Inspections':{
                                alias: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.NO_OF_INSPECTIONS', 'No of Inspection')}`
                            }
                        }}
                        yAxis={{
                            title: {
                                text: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.INSPECTION_COUNT', INSPECTION_COUNT)}`
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={reportData?.inspectionFrequencyByCrew?.map((inspectionFrequencyForParticularCrew: any) => ({
                            'Inspections': inspectionFrequencyForParticularCrew?.crew_name,
                            'No of Inspections': inspectionFrequencyForParticularCrew?.inspections
                        }))}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*setup Frequency*/}
                {subReports?.includes('setupFrequency') && <Card
                    title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.TITLE'}>{averageReports.SETUP_FREQUENCY}</Trans>}
                    style={{pageBreakAfter: "always"}}
                >
                    <SetupFrequency setupFrequency={reportData?.setupFrequency} isPDFView={true}/>
                </Card>}

                {/*"Overall QC Inspections"*/}
                {subReports?.includes('overallQCInspections') && <Card
                    title={<Trans i18nKey={summaryReports.OVERALL_QC_INSPECTIONS} defaults={`Overall QC Inspections`} />}
                    style={{pageBreakAfter: "always"}}
                >
                    {reportData?.overallQCInspections?.total_number_inspected?.trusses?<Fragment>
                        <Card title={<Trans i18nKey={'summary_report.pdfTitles.overall_overview'} defaults={'Overall Inspection Overview'} />} type={"inner"}>
                            <Column
                                color={fillColor}
                                isGroup={true}
                                seriesField={'name'}
                                xField={'Trusses or Joints'}
                                yField={'Count'}
                                meta={{
                                    'Count':{
                                        alias: `${t(summaryReports.COUNT, 'Count')}`
                                    }
                                }}
                                yAxis={{
                                    title: {
                                        text: `${t(summaryReports.COUNT)}`
                                    }
                                }}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                                        'Trusses or Joints': `${t(`${t(summaryReports.TRUSSES)}`)}`,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.trusses
                                    },
                                    {
                                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                                        'Trusses or Joints': `${t(summaryReports.PRELIMINARY)}`,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.preliminary
                                    },
                                    {
                                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                                        'Trusses or Joints': `${t(summaryReports.JOINTS)}`,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.joints
                                    },
                                    {
                                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                                        'Trusses or Joints': `${t(summaryReports.MEMBERS)}`,
                                        'Count': reportData?.overallQCInspections?.total_number_inspected?.members
                                    },
                                    {
                                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                                        'Trusses or Joints': `${t(summaryReports.TRUSSES)}`,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.trusses
                                    },
                                    {
                                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                                        'Trusses or Joints': `${t(summaryReports.PRELIMINARY)}`,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.preliminary
                                    },
                                    {
                                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                                        'Trusses or Joints': `${t(summaryReports.JOINTS)}`,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.joints
                                    },
                                    {
                                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                                        'Trusses or Joints': `${t(summaryReports.MEMBERS)}`,
                                        'Count': reportData?.overallQCInspections?.numberOutOfConformance?.members
                                    }
                                ]}
                            />
                        </Card>
                        <Card
                            title={<Trans i18nKey={'summary_report.pdfTitles.overview_as_percentage'} defaults={'Overview as Percentage'} />}
                            type={"inner"}
                        >
                            <Space>
                                {[
                                    {
                                        name: `${t(TRUSSES)}`,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.trusses
                                    },
                                    {
                                        name: `${t(PRELIMINARY)}`,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.preliminary
                                    },
                                    {
                                        name: `${t(JOINTS)}`,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.joints
                                    },
                                    {
                                        name: `${t(MEMBERS)}`,
                                        value: reportData?.overallQCInspections?.percentageOfOutOfConformance?.members
                                    }
                                ].map(({name, value}) => <Pie
                                    style={{height: 200, width: 175}}
                                    angleField={'Percentage'}
                                    colorField={'Out of Conformance'}
                                    radius={0.8}
                                    label={{
                                        type: 'inner',
                                        offset: '-30%',
                                        content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                        style: {
                                            fontSize: 12,
                                            textAlign: 'center',
                                            fill: graphValues.COLOR,
                                            OPACITY: graphValues.OPACITY
                                        },
                                    }}
                                    legend={{
                                        layout: 'vertical',
                                        position: 'bottom'
                                    }}
                                    data={[
                                        {
                                            'Out of Conformance': `${t('summary_report.passed', {name})}`,
                                            'Percentage': 100 - value
                                        },
                                        {
                                            'Out of Conformance': `${t('summary_report.failed', {name})}`,
                                            'Percentage': value
                                        }
                                    ]}
                                />)}
                            </Space>
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*"Out Of Conformance Visual"*/}
                {subReports?.includes('outOfConformanceVisual') && <Card
                    title={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY} defaults={`Out Of Conformance Preliminary`} />}
                >
                    <Card title={<Trans i18nKey={'summary_report.pdfTitles.ooc_prelim_graph'} defaults={'Out Of Conformance Preliminary Graph'} />} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        <Column
                            color={fillColor}
                            xField={'Type'}
                            yField={'No of Conformance'}
                            yAxis={{
                                title: {
                                    text: `${t(summaryReports.INSPECTION_COUNT)}`
                                }
                            }}
                            meta={{
                                'No of Conformance':{
                                    alias: `${t('summary_report.no_of_conformance', 'Count')}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={Object.entries(reportData?.outOfConformanceVisual)?.map(visuals => {
                                const outOfConformanceVisuals : OutOfConformance = visuals[1] as OutOfConformance
                                return {
                                    'Type': visuals[0].substring(0, 3),
                                    ['No of Conformance']: outOfConformanceVisuals.count
                                }
                            })}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={'summary_report.pdfTitles.terminology'} defaults={'Terminology'} />} type={"inner"} style={{pageBreakInside: "avoid"}}>
                        <ul>
                            {Object.entries(reportData?.outOfConformanceVisual)?.map(visuals => <li>
                                <Text strong>{visuals[0].substring(0, 3)}</Text> - <Text>{t(mapTitle(visuals[0]))}</Text>
                            </li>)}
                        </ul>
                    </Card>
                </Card>}

                {/*"Out Of Conformance Detailed"*/}
                {subReports?.includes('outOfConformanceDetailed') && <Card
                    title={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_JOINTS} defaults={`Out Of Conformance Joints`} />}
                    style={{pageBreakInside: "avoid"}}
                >
                    <Column
                        color={fillColor}
                        xField={'Type'}
                        yField={'No of Conformance'}
                        yAxis={{
                            title: {
                                text: `${t(summaryReports.JOINT_COUNT)}`
                            }
                        }}
                        meta={{
                            'No of Conformance':{
                                alias: `${t('summary_report.no_of_conformance', 'Count')}`
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={Object.entries(reportData?.outOfConformanceDetailed)?.map(detailedJoints => {
                            const outOfConformanceDetailedJoints : OutOfConformance = detailedJoints[1] as OutOfConformance
                            return {
                                'Type': t(mapTitle(detailedJoints[0])),
                                ['No of Conformance']: outOfConformanceDetailedJoints.count
                            }
                        })}
                    />
                </Card>}

                {/*"Out Of Conformance Members"*/}
                {subReports?.includes('outOfConformanceMembers') && <Card
                    title={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_MEMBERS} defaults={`Out Of Conformance Members`} />}
                    style={{pageBreakInside: "avoid"}}
                >
                    <Column
                        color={fillColor}
                        xField={'Type'}
                        yField={'No of Conformance'}
                        yAxis={{
                            title: {
                                text: `${t(summaryReports.MEMBER_COUNT)}`
                            }
                        }}
                        meta={{
                            'No of Conformance':{
                                alias: `${t('summary_report.no_of_conformance', 'Count')}`
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={Object.entries(reportData?.outOfConformanceMembers)?.map(detailedMembers => {
                            const outOfConformanceDetailedMembers : OutOfConformance = detailedMembers[1] as OutOfConformance
                            return {
                                'Type': t(mapTitle(detailedMembers[0])),
                                ['No of Conformance']: outOfConformanceDetailedMembers.count
                            }
                        })}
                    />
                </Card>}

                {/*"Plates Upsized From Specified"*/}
                {subReports?.includes('platesUpsized') && <Card
                    title={<Trans i18nKey={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED} defaults={`Plates Upsized From Specified`} />}
                    style={{pageBreakInside: "avoid"}}
                >
                    {reportData?.platesUpsized?.totalPlates?<Bullet
                        measureField={'measures'}
                        rangeField={'ranges'}
                        targetField={'Total plates'}
                        xField={'title'}
                        data={[
                            {
                                title: `${t(summaryReports.PLATES_UPSIZED)}`,
                                ranges: [reportData?.platesUpsized?.totalPlates],
                                measures: [reportData?.platesUpsized?.upsizedPlates],
                                'Total plates': reportData?.platesUpsized?.totalPlates,
                            }
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*"Inspection By Range"*/}
                {subReports?.includes('inspectionByRange') && <Card
                    title={getInspectionTitle(reportData?.inspectionByRange?.title)}
                    style={{pageBreakInside: "avoid"}}
                >
                    <DualAxes
                        xField={'range'}
                        yField={['inspections', 'passPercentage']}
                        meta={{
                            'inspections':{
                                alias: `${t('summary_report.inspections', 'inspections')}`
                            },
                            'passPercentage':{
                                alias: `${t('summary_report.passPercentage', 'passPercentage')}`
                            }
                        }}
                        data={[reportData?.inspectionByRange?.data, reportData?.inspectionByRange?.data]}
                        geometryOptions={[
                            {
                                geometry: 'column'
                            },
                            {
                                geometry: 'line',
                                lineStyle: {
                                    lineWidth: 2
                                },
                            },
                        ]}
                    />
                </Card>}

                {/*"Quality Control Log"*/}
                {reportType==='quality_control' && reportData?.map((shift: Shift) => <Card
                    title={shift.shift_name}
                    extra={<Space><Text type={"secondary"}><Trans i18nKey={'qc_report.hours'}>{'Hours: '}</Trans></Text><Text>{shift.shift_hours?shift.shift_hours:'0.00'}h</Text><Text type={"secondary"}><Trans i18nKey={'qc_report.status'}>{'Status: '}</Trans></Text><Text>{shift.active_shift?`${t('qc_report.active')}`:`${t('qc_report.inactive')}`}</Text><Badge status={shift.active_shift?'success':'error'} /></Space>}
                >
                    {shift.lines?.map((line: LineSetting) => <Card
                        title={line.line_name}
                        type={"inner"}
                        extra={<Space><Text type={"secondary"}><Trans i18nKey={'qc_report.inspection_count'}>{'Inspection count: '}</Trans></Text><Text>{line.inspection_count}</Text><Text type={"secondary"}><Trans i18nKey={'qc_report.hours'}>{'Hours: '}</Trans></Text><Text>{line.line_hours?line.line_hours:'0.00'}h</Text><Text type={"secondary"}><Trans i18nKey={'qc_report.status'}>{'Status: '}</Trans></Text><Text>{line.active_line?`${t('qc_report.active')}`:`${t('qc_report.inactive')}`}</Text><Badge status={line.active_line?'success':'error'} /></Space>}
                    >
                        <Table
                            columns={Object.entries(qualityLogConfig).map(([key, value]) => ({
                                key,
                                dataIndex: key,
                                title: value
                            }))}
                            dataSource={line.inspections?.map((inspection: any) => ({
                                ...inspection,
                                key: inspection.id
                            }))}
                            pagination={false}
                        />
                    </Card>)}
                </Card>)}

                {/*"plant"*/}
                {reportType==='plant' && reportData.shiftData?.map((shift: plantShift) => <Card
                    title={shift.shift_name}
                    extra={<Space><Text type={"secondary"}><Trans i18nKey={'plant_report.hours'}>Hours</Trans>: </Text><Text>{shift.shift_hours?shift.shift_hours:'0.00'}h</Text><Text type={"secondary"}><Trans i18nKey={'plant_report.status'}>Status</Trans>: </Text><Text>{shift.active_shift?<Trans i18nKey={'plant_report.active'}>Active</Trans>:<Trans i18nKey={'plant_report.inactive'}>Inactive</Trans>}</Text><Badge status={shift.active_shift?'success':'error'} /></Space>}
                >
                    {shift.lines?.map((line: plantLineSetting) => <Card
                        title={line.line_name}
                        type={"inner"}
                        extra={<Space style={{ border: line.statusValue? '1px solid green' : '1px solid red', backgroundColor: line.statusValue? '#C1E1C1': '#FAA0A0', padding: '3px' }}>
                            <Text type={"secondary"}><Trans i18nKey={'plant_report.total_inspections'}> Total Inspection count: </Trans></Text><Text>{line.inspection_count}</Text><Text type={"secondary"}><Trans i18nKey={'plant_report.actual_inspections'}> Actual Inspections: </Trans></Text><Text>{line.actual_value}</Text>
                            <Text type={"secondary"}>{line.isAmount ? <Trans i18nKey={'plant_report.expected_inspections'}>Expected Inspection Amount:</Trans> : <Trans i18nKey={'plant_report.expected_inspections_percentage'}>Expected Inspection Percentage:</Trans>}</Text>
                            <Text>{line.isAmount ? `${line.expected_value}A` : `${line.expected_value}%`}</Text>
                            <Text type={"secondary"}><Trans i18nKey={'plant_report.status'}>Status</Trans>: </Text><Text>{line.statusValue?<Trans i18nKey={'plant_report.passed'}>Passed</Trans>:<Trans i18nKey={'plant_report.failed'}>Failed</Trans>}</Text><Badge status={line.statusValue?'success':'error'} /></Space>}
                    >
                        <Table
                            columns={Object.entries(qualityLogConfig).map(([key, value]) => ({
                                key,
                                dataIndex: key,
                                title: t(`plant_report.table.${key}`, value)
                            }))}
                            dataSource={line.inspections?.map((inspection: any) => ({
                                ...inspection,
                                key: inspection.id
                            }))}
                            pagination={false}
                        />
                    </Card>)}
                </Card>)}


                {/*"Total Inspections"*/}
                {subReports?.includes('totalInspections') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}><Trans i18nKey={'weekly_summary_report.total_inspections'}>Total Inspections</Trans></Title>
                    <Table
                        columns={[dateColumn, ...formattedTotalInspectionConfigs].map(col => {return {
                            ...col,
                            title: t(`weekly_summary_report.${col.key}`)
                        }})}
                        dataSource={reportData?.totalInspections}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Preliminary Inspections"*/}
                {subReports?.includes('preliminaryInspections') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}><Trans i18nKey={'weekly_summary_report.preliminary_inspections'}>Preliminary Inspections</Trans></Title>
                    <Table
                        size={"small"}
                        columns={[dateColumn, ...formattedPreliminaryInspectionConfigs].map(col => {return {
                            ...col,
                            title: t(`weekly_summary_report.${col.key}`)
                        }})}
                        dataSource={reportData?.preliminaryInspections}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Critical Joints"*/}
                {subReports?.includes('criticalJoints') && <div style={{marginBottom: 25}}>
                    <Title level={5} style={{textAlign: "center", margin: 10}}><Trans i18nKey={'weekly_summary_report.critical_joints'}>Critical Joints</Trans></Title>
                    <Table
                        size={"small"}
                        columns={[dateColumn, ...formattedCriticalJointsConfigs].map(col => {return {
                            ...col,
                            title: t(`weekly_summary_report.${col.key}`)
                        }})}
                        dataSource={reportData?.criticalJoints}
                        bordered
                        pagination={false}
                    />
                </div>}

                {/*"Pass Percentage"*/}
                {subReports?.includes('inspectionPassing') && <Card title={<Trans i18nKey={'weekly_summary_report.pass_percentage'}>PASS_PERCENTAGE</Trans>}>
                    {reportData?.inspectionPassing?<Column
                        color={fillColor}
                        style={{margin: 10}}
                        isGroup={true}
                        seriesField={'name'}
                        xField={'range'}
                        yField={'percent'}
                        yAxis={{
                            title: {
                                text: `${t('weekly_summary_report.percentage', 'Percentage %')}`
                            }
                        }}
                        meta={{
                            'name':{
                                formatter: (value: any, index?: number) => `${t(`weekly_summary_report.${value}`, value)}`
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={processInspectionPassingData(reportData?.inspectionPassing)}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}


                {/*Overall Out Of Conformance*/}
                {subReports?.includes('overallOutOfConformance') && <Card title={<Trans i18nKey={certificationReports.OVERALL_OUT_OF_CONFORMANCE}>Overall Out Of Conformance</Trans>} style={{marginBottom: 400}}>
                    <Line
                        xField={'Quarter'}
                        yField={'Average'}
                        seriesField={'category'}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: certificationReportLabelColor,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({category, Average}) => ({ name: category, value: Average + '%' })
                        }}
                        data={mapData(reportData?.overallOutOfConformance)}
                    />
                </Card>}

                {/*Defects Found*/}
                {subReports?.includes('defectsFound') && <Card title={<Trans i18nKey={certificationReports.DEFECTS_FOUND}>Defects Found</Trans>} style={{marginBottom: 100}}>
                    <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS} defaults={`Total defects found in all inspections`} />} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.COUNT)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapDefectFoundData(reportData?.defectsFound, "defect")}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS} defaults={`Total defects found in failed inspections`} />} type={"inner"} style={{marginBottom: 40}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.COUNT)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapDefectFoundData(reportData?.defectsFound, "defect_out")}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS} defaults={`Total defects found in failed inspections`} />} type={"inner"} style={{marginBottom: 40}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapDefectFoundData(reportData?.defectsFound, "joint")}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS} defaults={`Total defects found in failed inspections`} />} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapDefectFoundData(reportData?.defectsFound, "inspection")}
                        />
                    </Card>
                </Card>}

                {/*Upsized Plates*/}
                {subReports?.includes('upsizedPlates') && <Card title={<Trans i18nKey={certificationReports.UPSIZED_PLATES}>Upsized Plates</Trans>} style={{marginBottom: 600}}>
                    <Card title={<Trans i18nKey={certificationReports.PERCENT_DEFECTS_OF_JOINTS} defaults={`Percentage of defects of all critical joints`} />} type={"inner"} style={{marginBottom: 15}}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: t(`certification_summary.UPSIZED_PLATES`), value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                name: quarter.range,
                                count: quarter.upsized_trusses
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES} defaults={`Additional Square Inches of Upsized Plates`} />}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.SQUARE_INCHES)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: t('certification_summary.ADDITIONAL_SQ_INCHES'), value: count + ' sq in' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                name: quarter.range,
                                count: quarter.additional_square
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.PERCENT_DEFECTS_OF_JOINTS} defaults={`Percentage of defects of all critical joints`} />} type={"inner"} style={{marginBottom: 25}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'target'}
                            xField={'title'}
                            tooltip={{
                                formatter: ({measures}) => ({ name: t('certification_summary.ACTUAL_SPECIFIED_RATIO'), value: measures + '%' })
                            }}
                            data={reportData?.upsizedPlates?.map((quarter: any) => ({
                                title: quarter.range,
                                ranges: [1, quarter.area_ratio * 1.2],
                                measures: [quarter.area_ratio],
                                target: 1
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.PERCENT_TRUSSES_OF_UPSIZED_PLATES} defaults={`Percentage of trusses with upsized plates`} />} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'type'}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({type, count}) => ({ name: type, value: count + '%' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapUpsizedPlatesData(reportData?.upsizedPlates)}
                        />
                    </Card>
                </Card>}

                {/*Placement Tolerance*/}
                {subReports?.includes('placementTolerance') && <Card title={<Trans i18nKey={certificationReports.PLACEMENT_TOLERANCE}>Placement Tolerance</Trans>}>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_DISTANCE} defaults={`Average Distance`} />} type={"inner"} style={{marginBottom: 15}}>
                        <Card title={<Trans i18nKey={certificationReports.FRONT} defaults={`Front Side`} />} type={"inner"} style={{marginBottom: 600}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(reportData?.placementTolerance?.map((quarter: any) => quarter.distance.front))}
                            />
                        </Card>
                        <Card title={<Trans i18nKey={certificationReports.BACK} defaults={`Back Side`} />} type={"inner"}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(reportData?.placementTolerance?.map((quarter: any) => quarter.distance.back))}
                            />
                        </Card>
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_ROTATION} defaults={`Average Rotation`} />} type={"inner"}>
                        <Card title={<Trans i18nKey={certificationReports.FRONT} defaults={`Front Side`} />} type={"inner"} style={{marginBottom: 25}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(reportData?.placementTolerance?.map((quarter: any) => quarter.rotation.front))}
                            />
                        </Card>
                        <Card title={<Trans i18nKey={certificationReports.BACK} defaults={`Back Side`} />} type={"inner"}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(reportData?.placementTolerance?.map((quarter: any) => quarter.rotation.back))}
                            />
                        </Card>
                    </Card>
                </Card>}

                {/*Frequency Summary*/}
                {subReports?.includes('frequencySummary') && <Card title={<Trans i18nKey={certificationReports.FREQUENCY_SUMMARY}>Frequency Summary</Trans>}>
                    <Card title={<Trans i18nKey={certificationReports.TRUSSES_PER_SHIFT_PER_WEEK} defaults={`Trusses per Shift per Week`} />} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.TRUSSES)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={mapFrequencySummaryData(reportData?.frequencySummary, true)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.HOURS_PER_WEEK} defaults={`Hours per Week`} />} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.HOURS)}`
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}h`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={mapFrequencySummaryData(reportData?.frequencySummary, false)}
                        />
                    </Card>
                </Card>}

                {/*Truss Selection*/}
                {subReports?.includes('trussSelection') && <Card title={<Trans i18nKey={'certification_summary.TRUSS_SELECTION_SPAN'} defaults={`Truss Selection (Average Span)`} />}>
                    <Card title={<Trans i18nKey={certificationReports.TRUSS_COUNT} defaults={`Truss Count`} />} type={"inner"} style={{marginBottom: 20}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.COUNT)}`
                                }
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={mapTrussCountData(reportData?.trussSelection)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.TRUSSES_AS_PERCENTAGE} defaults={`Trusses as Percentage`} />} type={"inner"}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            label={{
                                content: ({Percent}) => `${Percent}%`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                            }}
                            data={mapTrussPercentData(reportData?.trussSelection)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.ROOF_TRUSSES} defaults={`Roof Trusses`} />} type={"inner"} style={{marginBottom: 120}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.AVERAGE_FEET)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            label={{
                                content: ({Average}) => {
                                    return inchToFeet(Average * 12)
                                },
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={mapData(reportData?.trussSelection?.map((quarter: any) => quarter.roof), true)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.FLOOR_TRUSSES} defaults={`Floor Trusses`} />} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.AVERAGE_FEET)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            label={{
                                content: ({Average}) => {
                                    return inchToFeet(Average * 12)
                                },
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={mapData(reportData?.trussSelection?.map((quarter: any) => quarter.floor), true)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.ROOF_CRITICAL} defaults={`Average Critical Joints - Roof Trusses`} />} type={"inner"} style={{marginBottom: 125}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={mapData(reportData?.trussSelection?.map((quarter: any) => quarter.roof.critical), false)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.FLOOR_CRITICAL} defaults={`Average Critical Joints - Floor Trusses`} />} type={"inner"}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={mapData(reportData?.trussSelection?.map((quarter: any) => quarter.floor.critical), false)}
                        />
                    </Card>
                </Card>}

                {/*Inspection Detail*/}
                {subReports?.includes('inspectionDetail') && <Card title={<Trans i18nKey={certificationReports.INSPECTION_DETAIL}>Inspection Detail</Trans>} style={{marginBottom: 560}}>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_DISTANCE_FROM_DESIGNED_CENTER} defaults={`Average Distance From Designed Center`} />} type={"inner"} style={{marginBottom: 25}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.DISTANCE_IN_INCHES)}`
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}in`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: t('certification_summary.avg_distance'), value: `${Count}in` })
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_center_misplaced_distance,
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION} defaults={`Average Rotation From Specified Location`} />} type={"inner"}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.ROTATION_IN_DEGREES)}`
                                }
                            }}
                            label={{
                                content: ({Count}) => `${Count}°`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: t('certification_summary.avg_rotation'), value: `${Count}°` })
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_rotation_from_specified,
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.OVERVIEW_AS_PERCENTAGE} defaults={`Overview as percentage`} />} style={{marginBottom: 125}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            label={{
                                content: ({Percent}) => `${Percent}%`,
                                position: positionOfGraphValues,
                                style: {
                                    fill: certificationReportLabelColor,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                            }}
                            data={mapInspectionDetailData(reportData?.inspectionDetail)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES} defaults={`Additional Square Inches of Upsized Plates`} />}>
                        <Column
                            color={fillColor}
                            xField={'name'}
                            yField={'count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.SQUARE_INCHES)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({count}) => ({ name: t('certification_summary.additional_sq_inches'), value: count + ' sq in' })
                            }}
                            label={{
                                position: positionOfGraphValues,
                                style: {
                                    fill: graphValues.COLOR,
                                    OPACITY: graphValues.OPACITY
                                },
                            }}
                            data={reportData?.inspectionDetail?.map((detail: any) => ({
                                name: detail?.detailed.range,
                                count: detail?.detailed.additional_square
                            }))}
                        />
                    </Card>
                </Card>}

                {/*Certification Score*/}
                {subReports?.includes('scoring') && <Score data={reportData?.scoring} column={2}/>}


                {/*"Comment Report"*/}
                {reportType==='comment' && <CommentReport data={reportData} column={2}/>}


                {/*Summary*/}
                {subReports?.includes('summary') && <Card title={<Trans i18nKey={jointReports.SUMMARY}>Summary</Trans>} style={{marginBottom: 100}}>
                    {reportData?.summary?.critical.total_joints?<Fragment>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.JOINT_OVERVIEW}>Joint Overview</Trans>} style={{marginBottom: 400}}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: `${t(jointReports.COUNT)}`
                                    }
                                }}
                                meta={{
                                    'count':{
                                        alias: `${t('joint_report.count', 'count')}`
                                    }
                                }}
                                label={{
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        name: `${t(jointReports.TOTAL)}`,
                                        count: reportData?.summary?.critical.total_joints
                                    },
                                    {
                                        name: `${t(jointReports.OUT_OF_CONFORMANCE)}`,
                                        count: reportData?.summary?.critical.failed_joints
                                    }
                                ]}
                            />
                        </Card>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.OVERVIEW_AS_PERCENTAGE}>Overview as Percentage</Trans>}>
                            <Pie
                                angleField={'count'}
                                colorField={'name'}
                                radius={0.8}
                                label={{
                                    type: 'inner',
                                    offset: '-30%',
                                    content: ({ count }) => `${count}%`,
                                    style: {
                                        fontSize: 14,
                                        textAlign: 'center',
                                    },
                                }}
                                tooltip={{
                                    formatter: ({name, count}) => ({ name, value: `${count}%` })
                                }}
                                data={[
                                    {
                                        'name': `${t(jointReports.PASSED_JOINTS)}`,
                                        'count': parseFloat((100 - reportData?.summary?.critical.percent).toFixed(2))
                                    },
                                    {
                                        'name': `${t(jointReports.OUT_OF_CONFORMANCE)}`,
                                        'count': reportData?.summary?.critical.percent
                                    }
                                ]}
                            />
                        </Card>
                        <Card type={"inner"} title={<Trans i18nKey={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED} defaults={`Plates Upsized From Specified`} />}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total plates'}
                                xField={'title'}
                                data={[
                                    {
                                        title: `${t(jointReports.PLATES_UPSIZED)}`,
                                        ranges: [reportData?.summary?.critical.total_joints],
                                        measures: [reportData?.summary?.upsized.count],
                                        'Total plates': reportData?.summary?.critical.total_joints,
                                    }
                                ]}
                            />
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Breakdown*/}
                {subReports?.includes('breakdown') && <Card title={<Trans i18nKey={jointReports.BREAKDOWN}>Breakdown</Trans>}>
                    {reportData?.summary?.critical.total_joints?<Fragment>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.OUT_OF_CONFORMANCE} defaults={`Out of Conformance`} />}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: `${t(jointReports.COUNT)}`
                                    }
                                }}
                                meta={{
                                    'count':{
                                        alias: `${t('joint_report.count', 'count')}`
                                    }
                                }}
                                label={{
                                    content: ({percent}) => `${percent || 0}%`,
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={Object.entries(reportData?.breakdown?.out_of_conformance)?.map((field: any) => ({
                                    name: field[1].abbreviation,
                                    ['count']: field[1].count,
                                    percent: field[1].percent
                                }))}
                            />
                        </Card>
                        <Card title={'Terminology'} type={"inner"} style={{marginBottom: 250}}>
                            <ul>
                                {Object.entries(reportData?.breakdown?.out_of_conformance)?.map((field: any) => <li>
                                    <Text strong>{field[1].abbreviation}</Text> - <Text>{`${t(mapTitleJoint(field[0]))}`}</Text>
                                </li>)}
                            </ul>
                        </Card>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.JOINT_QC_DETAIL} defaults={`Joint QC Detail`} />}>
                            <Column
                                color={fillColor}
                                xField={'name'}
                                yField={'count'}
                                yAxis={{
                                    title: {
                                        text: `${t(jointReports.COUNT)}`
                                    }
                                }}
                                meta={{
                                    'count':{
                                        alias: `${t('joint_report.count', 'count')}`
                                    }
                                }}
                                label={{
                                    content: ({percent}) => `${percent || 0}%`,
                                    position: positionOfGraphValues,
                                    style: {
                                        fill: certificationReportLabelColor,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={Object.entries(reportData?.breakdown?.joint_detail)
                                    ?.filter((field: any) => field[0]!=='average_distance_16ths' && field[0]!=='average_orientation')
                                    ?.map((field: any) => ({
                                        name: t(mapTitleJoint(field[1].abbreviation)),
                                        count: field[1].count,
                                        percent: field[1].percent
                                    }))}
                            />
                        </Card>
                        <Card title={'Terminology'} type={"inner"} style={{marginBottom: 450}}>
                            <ul>
                                {Object.entries(reportData?.breakdown?.joint_detail)
                                    ?.filter((field: any) => field[0]!=='average_distance_16ths' && field[0]!=='average_orientation')
                                    ?.map((field: any) => <li>
                                        <Text strong>{field[1].abbreviation}</Text> - <Text>{t(mapTitleJoint(field[0]))}</Text>
                                    </li>)}
                            </ul>
                        </Card>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.AVERAGE_DISTANCE_FROM_DESIGNED_CENTER} defaults={`Average Distance From Designed Center`} />}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total joints'}
                                xField={'title'}
                                data={[
                                    {
                                        title: `${t(jointReports.AVERAGE_DISTANCE)}`,
                                        ranges: [reportData?.breakdown?.joint_detail.average_distance_16ths * 4],
                                        measures: [reportData?.breakdown?.joint_detail.average_distance_16ths],
                                        'Total joints': reportData?.breakdown?.joint_detail.average_distance_16ths * 4,
                                    }
                                ]}
                                style={{maxHeight: 145}}
                            />
                        </Card>
                        <Card type={"inner"} title={<Trans i18nKey={jointReports.AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION} defaults={`Average Rotation From Specified Location`} />}>
                            <Bullet
                                measureField={'measures'}
                                rangeField={'ranges'}
                                targetField={'Total joints'}
                                xField={'title'}
                                data={[
                                    {
                                        title: `${t(jointReports.AVERAGE_ROTATION)}`,
                                        ranges: [reportData?.breakdown?.joint_detail.average_orientation * 4],
                                        measures: [reportData?.breakdown?.joint_detail.average_orientation],
                                        'Total joints': reportData?.breakdown?.joint_detail.average_orientation * 4,
                                    }
                                ]}
                                style={{maxHeight: 145}}
                            />
                        </Card>
                    </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}

                {/*Joints by range*/}
                {subReports?.includes('jointsByRange') && <Card title={getJointTitle(reportData?.jointsByRange.title)}>
                    {reportData?.summary?.critical.total_joints?<DualAxes
                        xField={'range'}
                        yField={['joints', 'passPercentage']}
                        meta={{
                            'joints':{
                                alias: `${t('joint_report.joints', 'joints')}`
                            },
                            'passPercentage':{
                                alias: `${t('joint_report.passPercentage', 'passPercentage')}`
                            }
                        }}
                        label={{
                            position: positionOfGraphValues,
                            style: {
                                fill: graphValues.COLOR,
                                OPACITY: graphValues.OPACITY
                            },
                        }}
                        data={[reportData?.jointsByRange.data, reportData?.jointsByRange.data]}
                        geometryOptions={[
                            {
                                geometry: 'column'
                            },
                            {
                                geometry: 'line',
                                lineStyle: {
                                    lineWidth: 2
                                },
                            },
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>}


                {/*Summary Report by Crew*/}
                {subReports?.includes('crewSummary') && reportData?.crewSummary?.map((crew: SummaryByCrew) => {
                    const {id, name, is_active, inspection, preliminary, joint, member, upsized} = crew

                    return <Card
                        key={id}
                        title={name}
                        style={{pageBreakBefore: "always"}}
                        extra={<Badge status={is_active?'success':'error'} />}
                    >
                        <Card title={<Trans i18nKey={crewSummaryReport.TRUSSES_IN_DETAIL} defaults={`Trusses In Detail`} />} style={{pageBreakAfter: "always"}}>
                            <Overview title={`${t(crewSummaryReport.TRUSSES, 'Trusses')}`} data={inspection} isPDFMode={true}/>
                        </Card>
                        <Card title={<Trans i18nKey={crewSummaryReport.PRELIMINARY_IN_DETAIL} defaults={'Preliminary In Detail'} />} style={{pageBreakAfter: "always"}}>
                            <Overview title={`${t(crewSummaryReport.PRELIMINARY)}`} data={preliminary} isPDFMode={true}/>
                            <Space>
                                <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.PRELIMINARY_BREAKDOWN} defaults={'Preliminary Breakdown'} />} style={{pageBreakInside: "avoid", width: 400}}>
                                    <Breakdown data={preliminary.breakdown} isPDFMode={true}/>
                                </Card>
                                <Card title={'Terminology'} type={"inner"} style={{pageBreakInside: "avoid", height: 481}}>
                                    <ul>
                                        {Object.entries(preliminary.breakdown)?.map(visuals => <li>
                                            <Text strong>{visuals[0].substring(0, 3)}</Text> - <Text>{t(mapTitle(visuals[0]))}</Text>
                                        </li>)}
                                    </ul>
                                </Card>
                            </Space>
                        </Card>
                        <Card title={<Trans i18nKey={crewSummaryReport.JOINTS_IN_DETAIL} defaults={'Joints In Detail'} />} style={{pageBreakAfter: "always"}}>
                            <Overview title={`${t(crewSummaryReport.JOINTS, "Joints")}`} data={joint} isPDFMode={true}/>
                            <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.JOINT_BREAKDOWN} defaults={"Joint Breakdown"} />} style={{pageBreakInside: "avoid"}}>
                                <Breakdown data={joint.breakdown}/>
                            </Card>
                        </Card>
                        <Card title={<Trans i18nKey={crewSummaryReport.MEMBERS_IN_DETAIL} defaults={'Members In Detail'} />}>
                            <Overview title={`${t(crewSummaryReport.MEMBERS)}`} data={member} isPDFMode={true}/>
                        </Card>
                        <Card title={<Trans i18nKey={crewSummaryReport.PLATES_UPSIZED} defaults={'Plates Upsized'} />}>
                            <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.PLATES_UPSIZED_OVERVIEW} defaults={'Plates Upsized Overview'} />} style={{pageBreakInside: "avoid"}}>
                                <Bullet
                                    style={{height: 100}}
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total plates'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: `${t(crewSummaryReport.PLATES_UPSIZED)}`,
                                            ranges: [upsized.total],
                                            measures: [upsized.upsized],
                                            'Total plates': upsized.total
                                        }
                                    ]}
                                />
                            </Card>
                            <Card type={"inner"} title={`${t(crewSummaryReport.OVERVIEW_AS_PERCENTAGE, 'Overview as percentage')}`+' %'} style={{pageBreakInside: "avoid"}}>
                                <Bullet
                                    style={{height: 100}}
                                    measureField={'measures'}
                                    rangeField={'ranges'}
                                    targetField={'Total plates'}
                                    xField={'title'}
                                    data={[
                                        {
                                            title: `${t(crewSummaryReport.PLATES_UPSIZED)}`,
                                            ranges: [100],
                                            measures: [upsized.percent],
                                            'Total plates': 100
                                        }
                                    ]}
                                />
                            </Card>
                        </Card>
                    </Card>
                })}

                {/*Daily Hours Report*/}
                {reportType==='daily_hours' && <Table
                    pagination={false}
                    bordered={true}
                    dataSource={reportData}
                    columns={Object.entries(reportData[0]).map(config => {
                        if (config[0] === 'job_day'){
                            return {key: config[0], dataIndex: config[0], title: <Trans i18nKey={`daily_hours_pdf.${config[0]}`}>processTitle(config[0])</Trans>, render: (text) => <Trans i18nKey={`daily_hours_report.${text}`}>text</Trans>}
                        }
                        return {key: config[0], dataIndex: config[0], title: <Trans i18nKey={`daily_hours_pdf.${config[0]}`}>processTitle(config[0])</Trans>}
                    })}
                />}

                {/*"Inspection Log Report"*/}
                {reportType==='log_report' && <div>{reportData?.timeLogs?.map((shift: any)=>{
                    return <Card
                        key={shift.shift_id}
                        title={<Trans i18nKey={'LOG_REPORT.COMMON.SHIFT_TITLE'}>Shift - {{shift: shift.name}}</Trans>}
                    >
                        {/*filter out Report Column*/}
                        <LogReportTable tableConfigs={reportData?.tableConfigs}
                                        subTableConfigs={reportData?.subTableConfigs?.filter(({name}: {name:any})=>name !== 'id')}
                                        data={shift.shift_data} isPDFView={true}/>
                    </Card>
                })}
                    <Sign isPDFView={true} locationID={props.locationID} metaData={metaData}/>
                </div>}
            </div>
        </div>
        <div style={{minHeight: 20}}/>
    </Fragment>
}
