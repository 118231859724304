import {AuthContext} from "./user/AuthProvider";
import {ITableContext, Page, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {
    configs,
    cancelJob,
    fetch,
    downloadInspectionExportZip,
    deleteZip,
    fetchUsers,
    fetch_locations,
    fetchExports, Filter
} from "../api";
import {handleScroll} from "../utils/util";
import {Button, Col, notification, PageHeader, Row, Space, Tag, Tooltip, Typography} from "antd";
import React, {useCallback} from "react";
import {DownloadOutlined, CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import {ConfigsContext} from "./configs/ConfigsProvider";
import moment from "moment/moment";
import {getOrdering} from "./filters/ordering";
import {MapToFilter} from "./filters/mapping";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import CustomDropDown from "./lib/CustomDropDown";
import {TableFilter} from "./filters/TableFilter";
import {ROLE_VALUES} from "../utils/constants";
import {Trans} from "./lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

const fetchExportJobs = async (page?: Page, filters?:Filter[]) => {
    const orderBy = getOrdering('created_time', 'desc');
    return await fetchExports({
        orderBy: orderBy,
        filter: filters,
        page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
    }) || {}
}
export const InspectionExport: React.FunctionComponent = (props) => {
    const {t} = useTranslation();
    const onClickDownload = useCallback(async (job_id: string) => {
    //     download from s3 (using aws service)
        await downloadInspectionExportZip(job_id)
    }, [])


    const onClickCancel = useCallback(async (job_id, status, table?: ITableContext) => {
        if (status === 'pending' || status === 'generating'){
            table?.setLoading(true)
            const response = await cancelJob(job_id);
            if (response.status === 200) {
                const res = await fetchExportJobs(table?.page, table?.customData?.values);
                table?.setData(res?.data?.rows)
                notification.success({message: <Trans i18nKey={`inspection_export_page.job_canceled`} values={{jobId: job_id}} defaults={`Job ${job_id} Canceled`} />});
            } else {
                notification.error({message: <Trans i18nKey={`inspection_export_page.error_in_job_cancel`} values={{jobId: job_id}} defaults={`Error in job ${job_id} cancelling`} />});
            }
            table?.setLoading(false)
        }
    }, [])

    const onClickDelete = useCallback(async (job_id, table?: ITableContext) => {
        table?.setLoading(true)
        const response = await deleteZip(job_id);
        if (response.status === 200) {
            const res = await fetchExportJobs(table?.page, table?.customData?.values);
            table?.setData(res?.data?.rows)
            notification.success({message: <Trans i18nKey={`inspection_export_page.job_deleted`} values={{jobId: job_id}} defaults={`Job ${job_id} Deleted`} />});
        } else {
            notification.error({message: <Trans i18nKey={`inspection_export_page.error_in_job_delete`} values={{jobId: job_id}} defaults={`Error in job ${job_id} deleting`} />});
        }
        table?.setLoading(false)
    }, [])

    const checkAuthorized = (createdUserRole: string, createdUserId: string, authUserRole: string, authUserId: string) => {
        if (authUserRole === 'administrator') {
            return true;
        }
        const userRoleDict: { [key: string]: number } = {
            administrator: ROLE_VALUES.ADMINISTRATOR,
            district_manager: ROLE_VALUES.DISTRICT_MANAGER,
            regional_manager: ROLE_VALUES.REGIONAL_MANAGER,
            location_qc_manager: ROLE_VALUES.LOCATION_QC_MANAGER,
            qc_manager: ROLE_VALUES.QC_MANAGER,
            inspector: ROLE_VALUES.INSPECTOR
        }
        const createdRoleLevel = userRoleDict[createdUserRole];
        const authRoleLevel = userRoleDict[authUserRole];

        return (authRoleLevel > createdRoleLevel || (authRoleLevel === createdRoleLevel && authUserId === createdUserId));
    }

    return <>
        <AuthContext.Consumer>{auth => <TableV2
            schema={'inspection_export'}
            maxColumnWidth={400}
            header={InspectionExportTableHeader({
            })}
            fetchConfig={async (schema) => {
                const res = await configs(schema);
                res.data.rows = res.data.rows.map((row: any) => {
                    if (row.name) {
                        row.title = <Trans i18nKey={`inspection_export.${row.name}`} defaults={row.title}/>;
                    }
                    return row;
                });
                return res
            }}
            fetchData={async (request) => {
                handleScroll()
                return fetchExportJobs(request.page);
            }}
            scroll={{y: 'calc(100vh - 540px)', x: '100%'}}
            customRenderer={{
                job_id: (value, row, index, column) => {
                    return row.id.toString();
                },
                location_id: (value, row) => {
                    return row.inspector_id?`${row.location_name} (${row.inspector_name})`:row.location_name;
                },
                company: (value, row) => {
                    return row.company_name;
                },
                status: (value) => {
                    const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}>{<Trans i18nKey={name}/>}</Tag>
                    let name, color;
                    switch (value) {
                        case 'ready':
                            name = 'inspection_export_page.ready'
                            color = 'success'
                            break;
                        case 'pending':
                            name = 'inspection_export_page.pending'
                            color = 'default'
                            break;
                        case 'canceled':
                            name = 'inspection_export_page.canceled'
                            color = 'warning'
                            break;
                        case 'generating':
                            name = 'inspection_export_page.generating'
                            color = 'processing'
                            break;
                        case 'failed':
                            name = 'inspection_export_page.failed'
                            color = 'error'
                            break;
                        case 'deleted':
                            name = 'inspection_export_page.deleted'
                            color = '#2db7f5'
                            return  <Space><Tag style={{margin: 2, color: '#bfbfbf', borderColor: '#d9d9d9'}}>{<Trans i18nKey={name}/>}</Tag></Space>
                            break;
                    }
                    if (name && color) {return <Space>
                        {nameCard(name, color)}
                    </Space>}
                },
                actions: (value, row, index, column) => {
                    const job_id = row.id;
                    const status = row.status;
                    const authorized = checkAuthorized(row.user_role, row.client_id, auth.user?.user_role, auth.user?.sub);
                    return <TableContext.Consumer>{
                        table => <div>
                            <Space>
                                {(status === 'ready') ? <Tooltip color={'#050505'} title={<Trans i18nKey={`inspection_export_page.download`} defaults={`Download`} />} placement={"bottom"}>
                                    <Button
                                        // displayed only in ready state
                                        onClick={() => {
                                            onClickDownload(job_id)
                                        }}
                                        type="primary"
                                        icon={<DownloadOutlined/>}/>
                                </Tooltip> : null}
                                {((status === 'pending' || status === 'generating') && authorized
                                ) ? (<Tooltip color={'red'} title={<Trans i18nKey={`inspection_export_page.cancel`} defaults={`Cancel`} />} placement={"bottom"}>
                                        <Button
                                            // displayed only in pending and generating states
                                            onClick={() => onClickCancel(job_id, status, table)}
                                            type="primary"
                                            danger icon={<CloseOutlined/>}/>
                                    </Tooltip>
                                ) : null}
                                {(status === 'ready' && authorized) ? <Tooltip color={'red'} title={<Trans i18nKey={`inspection_export_page.delete`} defaults={`Delete`} />} placement={"bottom"}>
                                    <Button
                                        // displayed only in ready state
                                        onClick={() => onClickDelete(job_id, table)}
                                        danger icon={<DeleteOutlined/>}/>
                                </Tooltip> : null}
                            </Space>
                        </div>
                    }</TableContext.Consumer>
                },
                requested_files: (value) => {
                    const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}>{name}</Tag>
                    let color = 'default';
                    let tag;
                    tag = <>{value?.map((v: string)=>nameCard(v.toUpperCase(), color))}</>
                    return tag;
                },
                start_time: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs =>
                        <Typography.Text>{value ? moment(value).format(configs.dateTimeFormat) : ""}</Typography.Text>}</ConfigsContext.Consumer>
                },
                end_time: (value, row, index, column) => {
                    if (value){
                        return <ConfigsContext.Consumer>{configs =>
                            <Typography.Text>{value ? moment(value).format(configs.dateTimeFormat) :  <Trans i18nKey={`inspection_export.pending`} defaults={"Pending"}/>}</Typography.Text>}</ConfigsContext.Consumer>
                    }
                },
                filter_start_date: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs =>
                        <Typography.Text>{value ? moment(value).format(configs.dateTimeFormat) : ''}</Typography.Text>}</ConfigsContext.Consumer>

                },
                filter_end_date: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs =>
                        <Typography.Text>{value ? moment(value).format(configs.dateTimeFormat) : ''}</Typography.Text>}</ConfigsContext.Consumer>
                },
                num_records: (value) => {
                    return value ? value : <Trans i18nKey={`inspection_export.pending`} defaults={"Pending"}/>
                }
            }}
        />}</AuthContext.Consumer>
    </>
}

const InspectionExportTableHeader:React.FunctionComponent = ({}) => {
    return <div style={{textAlign: 'right', paddingRight: 7, width: '100%', marginBottom: 7}}>
        <Row>
            <Col span={12}>
                <PageHeader title={<Trans i18nKey={`inspection_export_page.header`} defaults={`Inspection Export`}/>}/>
            </Col>
            <AuthContext.Consumer>
                {auth => <>{['administrator', 'district_manager', 'regional_manager'].includes(auth.user?.user_role) && <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                    <TableFilter schema={"inspection_export_filters"}
                                 fetch={async (values: {[key:string]: any}, page?: Page) => {
                                     const filters = MapToFilter(values)
                                     return await fetchExportJobs(page, filters) || {};
                                 }}
                                 overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                     if(element.name === 'location_id'){
                                         return <CustomDropDown
                                             key={element.name}
                                             schema={schema}
                                             name={element.name}
                                             title={element.title}
                                             filterValue={form.getFieldValue('company_id')}
                                             fetch={(request:{tableName:string, filter:{[key:string] : any}}) => {
                                                 return fetch_locations(form.getFieldValue('company_id'))
                                             }}
                                             enum_map={['id', 'name']}
                                         />
                                     }
                                     return undefined
                                 }}
                                 visibilityValidator={(schema: string, element: any, form: any) => {
                                     const company = form.getFieldValue('company_id');
                                     if (element.name === 'company_id'){
                                         return auth.user?.user_role === 'administrator';
                                     }
                                     else if (element.name === 'location_id'){
                                         return  ((company !== undefined && auth.user?.user_role === 'administrator')
                                                 || auth.user?.user_role === 'district_manager' || auth.user?.user_role === 'regional_manager');
                                     }
                                     return true
                                 }}
                                 userFlag={Boolean(true)}
                    />
                </div>}</>}
            </AuthContext.Consumer>
        </Row>
    </div>
}