import React, {Fragment, useContext, useEffect, useState} from "react";
import {
    Button,
    Col,
    Form as AntForm,
    Input,
    Modal,
    notification,
    PageHeader,
    Popconfirm,
    Row, Space,
    Spin,
    Tag,
    Tooltip,
    Typography,
    Checkbox
} from "antd";
import {QuestionCircleOutlined, DeleteOutlined, LockTwoTone, EditTwoTone} from '@ant-design/icons';
import {DataRequest, ITableContext, Page, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {
    configs,
    deleteUser,
    fetch,
    fetch_inspector_names,
    fetch_inspectors_user_registration,
    fetch_locations,
    fetch_company,
    fetch_migrated_user,
    fetchUsers,
    importUser,
    insertUser,
    updateUser,
    userInfo,
    forcePasswordReset,
    getUserCSV
} from "../api";
import {Form} from "@q4us-sw/q4us-ui";
import {TableFilter} from "./filters/TableFilter";
import {MapToFilter} from "./filters/mapping";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {handleScroll, validateEmail, validatePhone} from "../utils/util";
import CustomDropDown from "./lib/CustomDropDown";
import CustomSelect from "./lib/CustomSelect";
import {AuthContext} from "./user/AuthProvider";
import {unstable_batchedUpdates} from "react-dom";
import {getOrdering} from "./filters/ordering";
import moment from "moment";
import {ConfigsContext} from "./configs/ConfigsProvider";
import {writeToCSV} from "../utils/functions";
import {Trans} from "./lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface UsersTableHeaderProps{
    schema:string
    row?:number
    userData?: any
    tableName?: any
    permission_role?: string
    locations: any
    inspectors: any
}

interface RegistrationProps{
    title: string | React.ReactNode,
    btnText: string | React.ReactNode,
    schema: string,
    loadTable: string,
    id?: number
    row?: any,
    userData?: [string, string][]
    disabled?: boolean
    iconView?: boolean
}

interface UserImportProps{
    schema: string,
    loadTable: string,
    userData: any,
    permission_role: any,
    locations: any,
    inspectors: any
}

interface RegistrationModalProps{
    title: string | React.ReactNode,
    schema: string,
    loadTable: string,
    open: boolean,
    closeCallback: () => void,
    id?: number
    row?: any,
    userData?: [string, string][]
    migrate?:boolean
    // old_id?:number
}

// const fetchUserData = async (values: {[key:string]: any}, page?: Page)=>{
//     const filters = MapToFilter(values)
//     return await fetch({
//         tableName: "user_account",
//         orderBy: ['timestamp desc'],
//         filter:[...filters],
//         page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
//     }) || {}
// }

const getRequestPayload = (tableName:string, request:Partial<DataRequest>) => {
    const column = request?.options?.inbuilt?.sort.column || 'timestamp'
    const direction = request?.options?.inbuilt?.sort.direction || 'desc'
    const orderBy = getOrdering(column, direction);
    const filters = MapToFilter(request?.options?.values || {})
    filters.push({name: 'is_deleted', value: false})
    return  {
        tableName: tableName,
        orderBy:orderBy,
        filter:filters,
        page: {size: request?.page?.size || 100, from: ((request?.page?.current || 1) - 1) * (request?.page?.size || 100)}
    }
}

const passwordReset = async (email: string) => {
    const res = await forcePasswordReset({email});
    if (res.data?.statusCode === 200){
        notification.success({message: <Trans i18nKey={`force_password_reset_success`} defaults={`Force Password Reset Successful`} />})
    }
    else{
        notification.error({message: <Trans i18nKey={`force_password_reset_failed`} defaults={`Force Password Reset Failed`} />})
    }
}

const UsersTableHeader: React.FunctionComponent<UsersTableHeaderProps> = (props:UsersTableHeaderProps)=>{
    return <AuthContext.Consumer>
        {auth => <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
            <Row>
                <Col span={12}>
                    <PageHeader title={<Trans i18nKey={`users`} defaults={`Users`}/>}/>
                </Col>
                <Col span={12}>
                    <UserImport
                        schema={'user_form'}
                        userData={props.userData}
                        loadTable={props.tableName}
                        permission_role={props.permission_role}
                        locations={props.locations}
                        inspectors={props.inspectors}
                    />
                    <UserRegistration
                        title={<Trans i18nKey={`user_registration`} defaults={`User Registration`} />}
                        btnText={<Trans i18nKey={`register_new_user`} defaults={`Register New User`} />}
                        schema={'user_form'}
                        loadTable={props.tableName}
                        id={props.row}
                        userData={props.userData}
                    />
                </Col>
            </Row>
            <TableFilter schema={"user_filter"}
                         fetch={async (values: {[key:string]: any}, page?: Page) => {
                             const filters = MapToFilter(values)
                             return await fetchUsers({
                                 tableName: props.tableName,
                                 orderBy: ['timestamp desc'],
                                 filter:[...filters, {name: 'is_deleted', value: false}],
                                 page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
                             }) || {}
                         }
                         }
                         overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                             let userData = props.userData;
                             if (element.name === 'user_role') {
                                 const user_role = auth.user?.user_role;
                                 if (user_role !== 'administrator') {
                                     const enumProp: [string, string] = [user_role, user_role?.toUpperCase()]
                                     if (userData && userData[0][0] !== user_role) {
                                         userData?.unshift(enumProp)
                                     }
                                 }
                                 // @ts-ignore
                                 return  <CustomFormInput
                                     type={'STRING'}
                                     name={element.name}
                                     schema={props.schema}
                                     enums={userData}
                                 />
                             }
                             else if(element.name === 'location_id'){
                                 return <CustomDropDown
                                     key={element.name}
                                     schema={schema}
                                     name={element.name}
                                     filterValue={form.getFieldValue('company_id')}
                                     fetch={(request:{tableName:string, filter:{[key:string] : any}}) => {
                                         return fetch_locations(form.getFieldValue('company_id'))
                                     }}
                                     enum_map={['id', 'name']}
                                 />
                             }
                             return undefined
                         }}
                         visibilityValidator={(schema: string, element: any, form: any) => {
                             const company = form.getFieldValue('company_id');
                             const user_role = form.getFieldValue('user_role');
                             if (element.name === 'company_id'){
                                 return props.permission_role === 'administrator' && user_role !== 'administrator'
                             }
                             else if (element.name === 'location_id'){
                                 return  ((company !== undefined && props.permission_role === 'administrator')
                                         || props.permission_role === 'district_manager' || props.permission_role === 'regional_manager')
                                     && user_role !== 'administrator' && user_role !== 'district_manager'
                             }
                             return true
                         }}
                         userFlag={Boolean(true)}
            />
        </div>}
    </AuthContext.Consumer>
}

const UserRegistration: React.FunctionComponent<RegistrationProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false)

    // const { userData } = props
    return <Fragment>
        {props.iconView?<Tooltip color={'#1890ff'} title={<Trans i18nKey={`update`} defaults={`Update`} />} placement={"bottom"}>
                <Button
                    style={{background: '#1890ff'}}
                    icon={<EditTwoTone twoToneColor="white"/>}
                    onClick={() => setOpen(true)}
                    disabled={props.disabled}
                /></Tooltip>:
            <Button
                style={{margin:20}}
                onClick={() => setOpen(true)}
                disabled={props.disabled}>
                {props.btnText}
            </Button>}
        <RegistrationModal
            title={props.title}
            schema={props.schema}
            loadTable={props.loadTable}
            open={open}
            closeCallback={()=>setOpen(false)}
            id={props.id}
            row={props.row}
            userData={props.userData}
        />
    </Fragment>
}

const UserImport: React.FunctionComponent<UserImportProps> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [register, setRegister] = useState<boolean>(false);
    const [row, setRow] = useState<any>(null)
    // const [oldId, setOldId] = useState<number|undefined>(undefined)

    const [form] = AntForm.useForm();
    const {t} = useTranslation();

    const handleImport = async () => {
        form.validateFields()
            .then(async ({email}) => {
                const {data} = await fetch_migrated_user(email);
                if (data){
                    if (Object.keys(data).length !== 0){
                        const user = {
                            ...data,
                            email: email
                        }
                        unstable_batchedUpdates(()=>{
                            setRow(user);
                            // setOldId(data.old_id);
                            setOpen(false);
                            setRegister(true);
                        })
                    }
                }
                else{
                    notification.error({message: <Trans i18nKey={`user_import_failed`} defaults={`User import failed. No user exists with the given name`} />});
                }
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const export_to_csv = async (customData: any, locations: object, inspectors: object) => {
        let arrayFilters: object[] = [];
        try {
            if (Object.keys(customData).length > 0) {
                for (let key in customData.values) {
                    if (customData.values[key] !== undefined && customData.values[key] !== "") {
                        arrayFilters = [...arrayFilters, {name: key, value: customData.values[key]}]
                    }
                }
            }

            const res = await getUserCSV(arrayFilters);
            // Download CSV.
            if (res.status === 200) {
                writeToCSV(res, 'UserExport.csv')
            }
        } catch (e) {
            console.log("Error in CSV file export:", e)
        }

    }

    return <TableContext.Consumer>{table =>
        <Fragment>
            { <Button onClick={() => {
                export_to_csv(table?.customData, props.locations, props.inspectors)
            }
            }><Trans i18nKey={`export_to_csv`} defaults={`Export To CSV`} /></Button> }
            <Button style={{margin:5}} onClick={() => {setOpen(true)}}>
                <Trans i18nKey={`import_user`} defaults={`Import User`} />
            </Button>
            <Modal
                title={<Trans i18nKey={`import_user`} defaults={`Import User`} />}
                visible={open}
                onCancel={() => setOpen(false)}
                onOk={handleImport}
                okText={<Trans i18nKey={`import`} defaults={`Import`} />}
                cancelText={<Trans i18nKey={`cancel`} defaults={`Cancel`}/>}
                destroyOnClose
            >
                <AntForm
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 20 }}
                >
                    <AntForm.Item
                        label={<Trans i18nKey={`email`} defaults={`Email`} />}
                        name='email'
                        rules={[
                            {
                                message: `${t('please_enter_valid_email')}`,
                                validator: async (_, value) => {
                                    if (validateEmail(value)){
                                        return Promise.resolve()
                                    }
                                    else{
                                        return Promise.reject(t('invalid_email_given'))
                                    }
                                }
                            }
                        ]}
                    >
                        <Input/>
                    </AntForm.Item>
                </AntForm>
            </Modal>
            <RegistrationModal
                title={<Trans i18nKey={`import_user`} defaults={`Import User`} />}
                schema={props.schema}
                loadTable={props.loadTable}
                open={register}
                closeCallback={()=>setRegister(false)}
                userData={props.userData}
                row={row}
                migrate={true}
                // old_id={oldId}
            />
        </Fragment>}
    </TableContext.Consumer>
}

interface LocationInputProps {
    type?: string
    value?: any
    onChange?: (data: any) => void
    schema: string
    name: string
    title?: string
    company_id: number
}

const LocationInput: React.FunctionComponent<LocationInputProps> = (props) => {
    const [enums, setEnums] = useState([])

    const loadEnums = async () => {
        let locationArray;
        if(props.value !== undefined && props.value !== null) {
            locationArray = [props.value].flat();
        }
        const {data: {rows} ={}} = await fetch_locations(props.company_id, locationArray)
        setEnums(rows?.map((location: any) => [location.id.toString(), location.name]))
    }

    useEffect(() => {
        loadEnums()
    }, [props.company_id])

    // @ts-ignore
    return  <CustomFormInput
        type={props.type || 'STRING'}
        name={props.name}
        schema={props.schema}
        enums={enums}
        value={props.value}
        onChange={(e)=>{props.onChange?.(e)}}
    />
}

interface CompanyInputProps {
    type?: string
    value?: any
    onChange?: (data: any) => void
    schema: string
    enums: [string, string][] | undefined
    name: string
    title?: string
}

const CompanyInput: React.FunctionComponent<CompanyInputProps> = (props) => {
    const [enums, setEnums] = useState([])

    const loadEnums = async () => {
        let companyArray;
        if(props.value !== undefined && props.value !== null) {
            companyArray = [props.value].flat();
        }
        const {data: {rows} ={}} = await fetch_company(companyArray)
        setEnums(rows?.map((location: any) => [location.id.toString(), location.name]))
    }

    useEffect(() => {
        loadEnums()
    }, [])

    // @ts-ignore
    return <CustomFormInput
        type={'STRING'}
        name={props.name}
        schema={props.schema}
        enums={enums}
        value={props.value}
        onChange={(e) => props.onChange?.(e)}
    />
}

const RegistrationModal: React.FunctionComponent<RegistrationModalProps> = (props)=>{
    const [loading, setLoading] = useState<boolean>(false)
    const [fieldData, setFieldData] = useState<any>(false)
    const { changeLanguageCode, user } = useContext(AuthContext);
    let userData  = props.userData
    const {t} = useTranslation();

    const roleBasedVisibility = (role:string, element:string) => {
        switch (element) {
            case 'company_id':
                return role === 'administrator'
            case 'location_id':
                return role === 'administrator' || role === 'district_manager' || role === 'regional_manager'
            default:
                return true
        }
    }

    const roleBasedSkipValidate = (role:string, field: string) => {
        switch (field) {
            case 'company_id':
                return role !== 'administrator'
            case 'location_id':
                return !(role === 'administrator' || role === 'district_manager')
            default:
                return false
        }
    }
    return <Fragment>
        <AuthContext.Consumer>
            { auth => <TableContext.Consumer>
                {table => <Modal
                    title={props.title}
                    visible={props.open}
                    onCancel={() => props.closeCallback()}
                    footer={null}
                    destroyOnClose
                >
                    <Spin spinning={loading}>
                        <Form
                            schema={props.schema}
                            initialValue={fieldData ||
                                {...props.row,
                                    company_id: props.row===undefined? null: props.row?.company_id?.toString(),
                                    location_id: props.row?.user_role==='regional_manager'?props.row?.location_id?.map((location: number) => location.toString()):props.row?.location_id?.toString(),
                                    inspector_list: props.row===undefined? undefined: (props.row?.inspector_list===null? []: props.row?.inspector_list),
                                    //inspector_list: props?.row?.inspector_list,
                                    is_active: props.row?.is_active===undefined? true: props.row?.is_active,
                                    range:[props.row?.daily&&'daily', props.row?.weekly&&'weekly', props.row?.monthly&&'monthly']}}
                            config={async (schema: string) => {
                                const res = await configs(props.schema)
                                return res.data.rows;
                            }}
                            visibilityValidator={(schema: string, element: any, form: any) => {
                                const user_role = form.getFieldValue('user_role');
                                const is_subscribe = form.getFieldValue('is_subscribe');
                                const permission_role = auth.user?.user_role;
                                switch (element.name){
                                    case 'company_id':
                                        return roleBasedVisibility(permission_role, element.name) && user_role !== 'administrator';
                                    case 'location_id':
                                        return roleBasedVisibility(permission_role, element.name) && user_role !== 'district_manager' && user_role !== 'administrator';
                                    case 'inspector_list':
                                        return roleBasedVisibility(permission_role, element.name) && user_role === 'qc_manager';
                                    case 'is_subscribe':
                                        return roleBasedVisibility(permission_role, element.name) && user_role !== 'inspector';
                                    case 'range':
                                        return is_subscribe === true && user_role != 'inspector';
                                }
                                return true;
                            }}
                            disabledValidator={(schema: string, element: FormElementProps, value: FormInstance<any>)=>{
                                if (element.name === 'email'){
                                    return props.migrate
                                }
                            }}
                            validateField={async  (rule:any, value:any, schema:string, column:FormElementProps, form:FormInstance<any>) =>{
                                const permission_role = auth.user?.user_role;
                                if (value!=null && !(typeof value==='object' && value.length===0)){
                                    switch (column.name){
                                        case "email":{
                                            if(!validateEmail(form.getFieldValue('email'))){
                                                return Promise.reject(t('invalid_email_address'))
                                            }
                                            break
                                        }
                                        case "contact_no":{
                                            if(!validatePhone(form.getFieldValue('contact_no'))){
                                                return Promise.reject(t('invalid_contact-number'))
                                            }
                                            break
                                        }
                                    }
                                } else {
                                    if (form.getFieldValue('user_role')) {
                                        const user_role = form.getFieldValue('user_role');
                                        switch (column.name){
                                            case "company_id":{
                                                if(!roleBasedSkipValidate(permission_role, column.name) && user_role !== 'administrator'){
                                                    return Promise.reject(t('company_is_required'))
                                                }
                                                break
                                            }
                                            case "location_id":{
                                                if(!roleBasedSkipValidate(permission_role, column.name) && user_role !== 'administrator' && user_role !== 'district_manager'){
                                                    return Promise.reject(t('Location_is_required'))
                                                }
                                                break
                                            }
                                        }
                                    }
                                }
                                return Promise.resolve()
                            }}
                            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                const auth_user_role = auth.user?.user_role;
                                const current_user_role = form.getFieldValue('user_role')
                                switch (element.name) {
                                    case 'user_role':
                                        if (auth_user_role !== 'administrator') {
                                            if (props.row && props.row.id === auth.user?.user_profile.id){
                                                const enumProp: [string, string] = [auth_user_role, auth_user_role.toUpperCase()]
                                                if (userData && userData[0][0] !== auth_user_role) {
                                                    userData?.unshift(enumProp)
                                                }
                                            } else {
                                                if (userData && userData[0][0] === auth_user_role) {
                                                    userData = userData?.slice(1)
                                                }
                                            }
                                        }
                                        // @ts-ignore
                                        return  <CustomFormInput
                                            type={'STRING'}
                                            name={element.name}
                                            schema={props.schema}
                                            enums={userData}
                                        />
                                    case 'location_id':
                                        const locationIdValue = form.getFieldValue('location_id');
                                        if (!locationIdValue && current_user_role === 'qc_manager') {
                                            form.setFieldsValue({
                                                location_id: locationIdValue
                                            });
                                        }
                                        return <LocationInput
                                            schema={schema}
                                            name={element.name}
                                            type={current_user_role==='regional_manager'?'LIST_MULTIPLE':'STRING'}
                                            company_id={form.getFieldValue('company_id')}
                                        />
                                    case 'company_id':
                                        // @ts-ignore
                                        return <CompanyInput
                                            schema={schema}
                                            name={element.name}
                                            type={'STRING'}
                                        />
                                    case 'inspector_list':
                                        if (current_user_role==='qc_manager' && auth_user_role==='regional_manager') {
                                            const locationIdValue = form.getFieldValue('location_id');
                                            if(locationIdValue) {
                                                return <CustomSelect
                                                    key={element.name}
                                                    schema={schema}
                                                    name={element.name}
                                                    title={element.title}
                                                    fetch={(filterValues:string[]) => {
                                                        return fetch_inspectors_user_registration(locationIdValue)
                                                    }}
                                                    filterValues={[form.getFieldValue('location_id')]}  // used for the dependency list
                                                    enum_map={['id', 'name']}
                                                />
                                            }
                                        } else if(current_user_role === 'qc_manager') {
                                            return <CustomSelect
                                                key={element.name}
                                                schema={schema}
                                                name={element.name}
                                                title={element.title}
                                                fetch={(filterValues:string[]) => {
                                                    return fetch_inspectors_user_registration(form.getFieldValue('location_id'))
                                                }}
                                                filterValues={[form.getFieldValue('location_id')]}  // used for the dependency list
                                                enum_map={['id', 'name']}
                                            />
                                        }
                                }
                            }}
                            submit={async (values:any) => {
                                let response
                                if (values?.language_code && (props.row?.client_id === user.sub)) {
                                    changeLanguageCode?.(values?.language_code)
                                }
                                unstable_batchedUpdates(() => {
                                    setFieldData(values)
                                    setLoading(true)
                                })
                                const rangeValues = values?.range;
                                if(rangeValues){
                                    values.daily = rangeValues.includes('daily') ? true : false;
                                    values.weekly = rangeValues.includes('weekly') ? true : false;
                                    values.monthly = rangeValues.includes('monthly') ? true : false;
                                    delete values.range;
                                }
                                if (!props.migrate && props.row) {  // Update User
                                    response = await updateUser({
                                        tableName: "user_account",
                                        data: {...values, id: props.id},
                                        previous_data: {
                                            previous_user_role: props.row.user_role,
                                            previous_company_id: props.row.company_id?.toString(),
                                            previous_email: props.row.email
                                        }
                                    })
                                } else {
                                    if (props.migrate){
                                        response = await importUser(values)
                                    }
                                    else{
                                        response = await insertUser(values)
                                    }
                                }
                                unstable_batchedUpdates(() => {
                                    setFieldData(undefined)
                                    setLoading(false)
                                })
                                const {data = {}} = response;
                                if(data?.statusCode===200){
                                    table?.setLoading(true);
                                    props.closeCallback();
                                    notification.success({message: <Trans i18nKey={`user_created_successfully`} defaults={`User created/updated successfully`} />})
                                    const request = getRequestPayload(auth?.user?.role_attributes?.table_name, {options:{values:table?.customData?.values, inbuilt:table?.customData?.options?.inbuilt}, page:table?.page});
                                    const res = await fetchUsers(request)
                                    table?.setData(res.data?.rows || [])
                                    table?.setPage({
                                        current: res.data?.page?.current ?? table?.page?.current ?? 0,
                                        total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                        size: res.data?.page?.size ?? table?.page?.size ?? 100
                                    })
                                    table?.setLoading(false);
                                    return true
                                }else{
                                    if(data?.message === 'Activate company and location before activating the user') {
                                        notification.warning({message: <Trans i18nKey={`activate_company_and_location`} defaults={`activate_company_and_location`} />})
                                    } else {
                                        notification.error({message: <Trans i18nKey={`user_creation_failed`} defaults={`User creation failed`} />})
                                        props.closeCallback()
                                    }
                                }
                            }}
                            maxHeight={'60vh'}
                        />
                    </Spin>
                </Modal>}
            </TableContext.Consumer>}
        </AuthContext.Consumer>
    </Fragment>
}

export const Users: React.FunctionComponent<any> = (props) => {
    const [inspectors, setInspectors] = useState<{[key: string]: {name: string, active: boolean}}>({'0': {name: 'undefined', active: false}})
    const [locations, setLocations] = useState<{ [k: number]: any; }>()
    const [rmLocations, setRMLocations] = useState<number[]>([])
    const {t} = useTranslation();

    const load = async () => {
        const res = await fetch_inspector_names()
        const inspector_list = res?.data?.rows?.map((user: any) => [user.id.toString(), {name: `${user.first_name} ${user.last_name}`, active: user.is_active}]) || []
        setInspectors(Object.fromEntries(inspector_list))
    }

    const loadLocations = async () => {
        const {data: {rows: [row] = []} ={}} = await fetch({tableName: 'enums', filter: [{name: 'type', value: 'location'}]})
        const {values} = row
        const {data: {user_profile: {id=0} ={}, user_role} ={}} = await userInfo()
        if(user_role==='regional_manager') {
            const {data: {rows = []} ={}} = await fetch({tableName: 'user_location_mapping', filter: [{name: 'user_id', value: id}]})
            unstable_batchedUpdates(() => {
                setRMLocations(rows?.map((userLocation: any) => userLocation.location_id.toString()))
                setLocations(Object.fromEntries(values?.map((value: string[]) => ([parseInt(value[0]), value[1]])) || []))
            })
        } else {
            setLocations(Object.fromEntries(values?.map((value: string[]) => ([parseInt(value[0]), value[1]])) || []))
        }
    }

    useEffect(()=>{
        load()
    }, [])

    useEffect(() => {
        loadLocations()
    }, [])

    const schema = 'user_view'

    const delete_user = async (table: ITableContext|undefined, email: string, id: number, tableName: string) =>{
        const res = await deleteUser(email, id)
        table?.setLoading(true)
        if (res.data?.statusCode === 200) {
            notification.success({message: <Trans i18nKey={`user_deleted_success`} defaults={`User deleted successfully`} />});
            table?.setLoading(true);
            const request = getRequestPayload(tableName, {options:{values:table?.customData?.values, inbuilt:table?.customData?.options?.inbuilt}, page:table?.page});
            const res = await fetchUsers(request)
            table?.setData(res.data?.rows || [])
            table?.setPage({
                current: res.data?.page?.current ?? table?.page?.current ?? 0,
                total: res.data?.page?.total ?? table?.page?.total ?? 0,
                size: res.data?.page?.size ?? table?.page?.size ?? 100
            })
            table?.setLoading(false);
            return true
        }else{
            notification.error({message: <Trans i18nKey={`user_deletion_failed`} defaults={`User deletion failed`} />})
        }
        return res
    }

    return <React.Fragment>
        <AuthContext.Consumer>
            {auth =>
                <div>
                    <TableV2
                        key={auth?.user}
                        schema={schema}
                        rowKey={"email"}
                        maxColumnWidth={400}
                        header={UsersTableHeader({
                            schema: schema,
                            userData: auth?.user?.role_attributes?.user_setup,
                            tableName: auth?.user?.role_attributes?.table_name,
                            permission_role: auth?.user?.user_role,
                            locations: locations,
                            inspectors: inspectors
                        })}
                        fetchConfig={async (schema) => {
                            const {data = {}} = await configs(schema)
                            const {statusCode, rows = [], fields = []} = data;
                            data.rows = data.rows.map((row: any) => {
                                if (row.name) {
                                    row.title = <Trans i18nKey={row.name} defaults={row.title}/>;
                                }
                                return row;
                            });
                            return {data: {statusCode, rows, fields}};
                        }}
                        fetchData={async (request) => {
                            handleScroll();
                            const req = getRequestPayload(auth?.user?.role_attributes?.table_name, request);
                            if (auth.user){
                                return  await fetchUsers(req)
                            }
                            else{
                                return {}
                            }
                        }}
                        scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
                        customRenderer={{
                            edit: (value, row, index, column) => {
                                const {id, location_id, first_name, user_role, email} = row
                                return <TableContext.Consumer>
                                    {table =>
                                        <Space>
                                            <UserRegistration
                                                title={<Trans i18nKey={`user_edit`} defaults={`User - Edit`} />}
                                                btnText={<Trans i18nKey={`update`} defaults={`Update`} />}
                                                schema={'user_form'}
                                                loadTable={auth?.user.role_attributes.table_name}
                                                id={id}
                                                row={row}
                                                userData={auth?.user.role_attributes.user_setup}
                                                disabled={first_name === 'Migrated Inspections' ||
                                                    (user_role !== 'administrator' && user_role === auth.user.user_role && id !== auth.user.user_profile.id) ||
                                                    (auth.user.user_role==='regional_manager' && user_role!=='regional_manager' && !rmLocations?.includes(location_id?.toString()))}
                                                iconView={auth.user?.user_role === 'administrator'}
                                            />
                                            {(auth.user?.user_role === 'administrator' && first_name !== 'Migrated Inspections')  &&
                                                <>
                                                    <Tooltip color={'green'} title={<Trans i18nKey={`force_password_reset`} defaults={`Force Reset Password`} />} placement={"bottom"}>
                                                        <Button
                                                            style={{background: '#87d068'}}
                                                            icon={<LockTwoTone twoToneColor="white"/>}
                                                            onClick={() => passwordReset(email)}
                                                        />
                                                    </Tooltip>
                                                    <Popconfirm
                                                        placement="topRight"
                                                        icon = {<QuestionCircleOutlined style={{ color: 'red' }}/>}
                                                        title={<Trans i18nKey={`delete_conformation`} defaults={`Are you sure you want to delete this user?`} />}
                                                        onConfirm={() => delete_user(table, email, id, auth?.user.role_attributes.table_name)}
                                                        okText={<Trans i18nKey={`yes`} defaults={`Yes`} />}
                                                        cancelText={<Trans i18nKey={`no`} defaults={`No`} />}
                                                        okType={'danger'}
                                                    >
                                                        <Tooltip color={'red'} title={<Trans i18nKey={`delete`} defaults={`Delete`}/>} placement={"bottom"}>
                                                            <Button type="primary" danger icon={<DeleteOutlined/>}/>
                                                        </Tooltip>
                                                    </Popconfirm>
                                                </>}
                                        </Space>}
                                </TableContext.Consumer>
                            },
                            is_active: (value, row, index, column) => {
                                return <Tag color={value ? "green" : "red"}>{value ? `${t('active')}` : `${t('inactive')}`}</Tag>
                            },
                            email: (value, row, index, column) => {
                                const {first_name} = row
                                if (first_name === 'Migrated Inspections') {
                                    return <Typography.Text/>
                                } else {
                                    return <Typography.Text>{value}</Typography.Text>
                                }
                            },
                            location_id: (value, row, index, column) => {
                                if(value && locations) {
                                    return value?.map((locationId: number) => {
                                        const location = locations[locationId]
                                        const NameCard = (name: string) => <Tag
                                            style={{margin: 2}}>{name}</Tag>
                                        return <Fragment>
                                            {location?.length > 25 ?
                                                <Tooltip title={location}
                                                         color={'blue'}>{NameCard(`${location?.substring(0, 21)}...`)}</Tooltip> :
                                                NameCard(location)}
                                        </Fragment>
                                    })
                                }
                            },
                            inspector_list: (value, row, index, column) => {
                                return value?.map((inspectorId: any) => {
                                    const inspector = inspectors[inspectorId]
                                    if (inspector) {
                                        const NameCard = (name: string) => <Tag
                                            color={inspector?.active ? 'processing' : 'error'} style={{margin: 2}}>{name}</Tag>
                                        return <Fragment>
                                            {inspector?.name?.length > 25 ?
                                                <Tooltip title={inspector?.name}
                                                         color={inspector?.active ? 'blue' : 'red'}>{NameCard(`${inspector?.name?.substring(0, 21)}...`)}</Tooltip> :
                                                NameCard(inspector?.name)}
                                        </Fragment>
                                    }
                                })
                            },
                            timestamp: (value, row, index, column) => {
                                return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                            }
                        }}
                    />
                </div>

            }</AuthContext.Consumer> </React.Fragment>
}