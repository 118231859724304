import {ColumnsType} from "antd/es/table";
import React from "react";

export const graphValues = {
    COLOR: '#000000',   // #ABB2B9, #FFFFFF #566573
    POSITION: 'bottom', // 'top', 'bottom', 'middle'
    OPACITY: 0.8    // 0.6
}

export const reportTitles: {[key: string]: string} = {
    average: 'Average Report',
    summary: 'Summary Report',
    quality_control: 'Quality Control Log',
    weekly_summary: 'Weekly Summary Report',
    certification_summary: 'Certification Summary Report',
    comment: 'Comment Report',
    joint: 'Joint Report',
    crew_summary: 'Summary Report by Crew',
    daily_hours: 'Daily Hours Report',
    log_report: 'Inspection Log Report',
    plant: 'In-Plant Report'
}

export const reportSubTitles: {[key: string]: string} = {
    joint: '(Based on Critical Joints)'
}

export const averageReports = {
    INSPECTION_INFORMATION: 'Inspection Information',
    JOINT_BREAKDOWN: 'Joint Breakdown',
    DIMENSION_BREAKDOWN: 'Dimension Breakdown',
    INSPECTION_FREQUENCY: 'Inspection Frequency',
    INSPECTION_FREQUENCY_BY_CREW: 'Inspection Frequency By Crew',
    SETUP_FREQUENCY: 'Setup Frequency'
}

export const summaryReports = {
    OVERALL_QC_INSPECTIONS: 'summary_report.OVERALL_QC_INSPECTIONS',
    OUT_OF_CONFORMANCE_PRELIMINARY: 'summary_report.OUT_OF_CONFORMANCE_PRELIMINARY',
    OUT_OF_CONFORMANCE_JOINTS: 'summary_report.OUT_OF_CONFORMANCE_JOINTS',
    OUT_OF_CONFORMANCE_MEMBERS: 'summary_report.OUT_OF_CONFORMANCE_MEMBERS',
    PLATES_UPSIZED_FROM_SPECIFIED: 'summary_report.PLATES_UPSIZED_FROM_SPECIFIED',
    INSPECTIONS_BY_DAY: 'summary_report.INSPECTIONS_BY_DAY',
    INSPECTIONS_BY_WEEK: 'summary_report.INSPECTIONS_BY_WEEK',
    INSPECTIONS_BY_QUARTER: 'summary_report.INSPECTIONS_BY_QUARTER',
    INSPECTIONS_BY_DAY_TXT: "Inspections By Day",
    INSPECTIONS_BY_WEEK_TXT: "Inspections By Week",
    INSPECTIONS_BY_QUARTER_TXT: "Inspections By Quarter",
    COUNT: "summary_report.COUNT",
    INSPECTION_COUNT: "summary_report.INSPECTION_COUNT",
    JOINT_COUNT: "summary_report.JOINT_COUNT",
    JOINTS: "summary_report.JOINTS",
    MEMBER_COUNT: "summary_report.MEMBER_COUNT",
    MEMBERS: "summary_report.MEMBERS",
    NO_OF_INSPECTED: "summary_report.NO_OF_INSPECTED",
    OUT_OF_CONFORMANCE: "summary_report.OUT_OF_CONFORMANCE",
    PLATES_UPSIZED: "summary_report.PLATES_UPSIZED",
    PRELIMINARY: "summary_report.PRELIMINARY",
    TRUSSES: "summary_report.TRUSSES"
}



export const crewSummaryReport = {
    OVERALL_QC_INSPECTIONS: "crew_summary.OVERALL_QC_INSPECTIONS",
    COUNT: 'crew_summary.COUNT',
    JOINT_BREAKDOWN: 'crew_summary.JOINT_BREAKDOWN',
    JOINTS: 'crew_summary.JOINTS',
    JOINTS_IN_DETAIL: 'crew_summary.JOINTS_IN_DETAIL',
    MEMBERS_IN_DETAIL: 'crew_summary.MEMBERS_IN_DETAIL',
    OUT_OF_CONFORMANCE: 'crew_summary.OUT_OF_CONFORMANCE',
    OVERVIEW_AS_PERCENTAGE: 'crew_summary.OVERVIEW_AS_PERCENTAGE',
    PLATES_UPSIZED: 'crew_summary.PLATES_UPSIZED',
    PLATES_UPSIZED_OVERVIEW: 'crew_summary.PLATES_UPSIZED_OVERVIEW',
    PRELIMINARY: "crew_summary.PRELIMINARY",
    PRELIMINARY_BREAKDOWN: 'crew_summary.PRELIMINARY_BREAKDOWN',
    PRELIMINARY_IN_DETAIL: 'crew_summary.PRELIMINARY_IN_DETAIL',
    TRUSSES: 'crew_summary.TRUSSES',
    TRUSSES_IN_DETAIL: 'crew_summary.TRUSSES_IN_DETAIL',
    MEMBERS: 'crew_summary.MEMBERS',
    NO_OF_INSPECTED: 'crew_summary.NO_OF_INSPECTED',
    PASS: 'crew_summary.PASS',
    CREW_SUMMARY: 'crew_summary.CREW_SUMMARY'
}

export const certificationReports = {
    OVERALL_OUT_OF_CONFORMANCE: 'certification_summary.OVERALL_OUT_OF_CONFORMANCE',
    DEFECTS_FOUND: 'certification_summary.DEFECTS_FOUND',
    UPSIZED_PLATES: 'certification_summary.UPSIZED_PLATES',
    PLACEMENT_TOLERANCE: 'certification_summary.PLACEMENT_TOLERANCE',
    FREQUENCY_SUMMARY: 'certification_summary.FREQUENCY_SUMMARY',
    TRUSS_SELECTION: 'certification_summary.TRUSS_SELECTION',
    INSPECTION_DETAIL: 'certification_summary.INSPECTION_DETAIL',
    CERTIFICATION_SCORING: 'certification_summary.CERTIFICATION_SCORING',
    ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES: 'certification_summary.ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES',
    AVERAGE_DISTANCE: 'certification_summary.AVERAGE_DISTANCE',
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER: 'certification_summary.AVERAGE_DISTANCE_FROM_DESIGNED_CENTER',
    AVERAGE_FEET: 'certification_summary.AVERAGE_FEET',
    AVERAGE_ROTATION: 'certification_summary.AVERAGE_ROTATION',
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION: 'certification_summary.AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION',
    BACK: 'certification_summary.BACK',
    COUNT: 'certification_summary.COUNT',
    DISTANCE_IN_INCHES: 'certification_summary.DISTANCE_IN_INCHES',
    FLOOR_CRITICAL: 'certification_summary.FLOOR_CRITICAL',
    FLOOR_SPAN: 'certification_summary.FLOOR_SPAN',
    FRONT: 'certification_summary.FRONT',
    HOURS: 'certification_summary.HOURS',
    HOURS_PER_WEEK: 'certification_summary.HOURS_PER_WEEK',
    OVERVIEW_AS_PERCENTAGE: 'certification_summary.OVERVIEW_AS_PERCENTAGE',
    PERCENT_DEFECTS_OF_INSPECTIONS: 'certification_summary.PERCENT_DEFECTS_OF_INSPECTIONS',
    PERCENT_DEFECTS_OF_JOINTS: 'certification_summary.PERCENT_DEFECTS_OF_JOINTS',
    PERCENT_OF_PLATES_UPSIZED: 'certification_summary.PERCENT_OF_PLATES_UPSIZED',
    PERCENT_TRUSSES_OF_UPSIZED_PLATES: 'certification_summary.PERCENT_TRUSSES_OF_UPSIZED_PLATES',
    PERCENTAGE: 'certification_summary.PERCENTAGE',
    RATIO_OF_SQUARE_INCHES: 'certification_summary.RATIO_OF_SQUARE_INCHES',
    ROOF_CRITICAL: 'certification_summary.ROOF_CRITICAL',
    ROOF_SPAN: 'certification_summary.ROOF_SPAN',
    ROTATION_IN_DEGREES: 'certification_summary.ROTATION_IN_DEGREES',
    SQUARE_INCHES: 'certification_summary.SQUARE_INCHES',
    TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS: 'certification_summary.TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS',
    TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS: 'certification_summary.TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS',
    TRUSS_COUNT: 'certification_summary.TRUSS_COUNT',
    TRUSSES: 'certification_summary.TRUSSES',
    TRUSSES_AS_PERCENTAGE: 'certification_summary.TRUSSES_AS_PERCENTAGE',
    TRUSSES_PER_SHIFT_PER_WEEK: 'certification_summary.TRUSSES_PER_SHIFT_PER_WEEK',
    ROOF_TRUSSES: "certification_summary.ROOF_TRUSSES",
    FLOOR_TRUSSES: "certification_summary.FLOOR_TRUSSES"
}

export const jointReports = {
    SUMMARY_TXT: 'Summary',
    SUMMARY: 'joint_report.SUMMARY',
    BREAKDOWN_TXT: 'Breakdown',
    BREAKDOWN: 'joint_report.BREAKDOWN',
    JOINTS_BY_RANGE: 'joint_report.JOINTS_BY_RANGE',
    PLATES_UPSIZED_FROM_SPECIFIED: 'joint_report.PLATES_UPSIZED_FROM_SPECIFIED',
    JOINTS_BY_DAY: 'joint_report.JOINTS_BY_DAY',
    JOINTS_BY_WEEK: 'joint_report.JOINTS_BY_WEEK',
    JOINTS_BY_QUARTER: 'joint_report.JOINTS_BY_QUARTER',
    JOINTS_BY_DAY_TXT: "Joints By Day",
    JOINTS_BY_WEEK_TXT: "Joints By Week",
    JOINTS_BY_QUARTER_TXT: "Joints By Quarter",
    COUNT: 'joint_report.COUNT',
    TOTAL: 'joint_report.TOTAL',
    OUT_OF_CONFORMANCE: 'joint_report.OUT_OF_CONFORMANCE',
    PLATES_UPSIZED: 'joint_report.PLATES_UPSIZED',
    PASSED_JOINTS: 'joint_report.PASSED_JOINTS',
    JOINT_OVERVIEW: 'joint_report.JOINT_OVERVIEW',
    AVERAGE_DISTANCE: 'joint_report.AVERAGE_DISTANCE',
    AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION: 'joint_report.AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION',
    AVERAGE_DISTANCE_FROM_DESIGNED_CENTER: 'joint_report.AVERAGE_DISTANCE_FROM_DESIGNED_CENTER',
    AVERAGE_ROTATION: 'joint_report.AVERAGE_ROTATION',
    JOINT_QC_DETAIL: 'joint_report.JOINT_QC_DETAIL',
    OVERVIEW_AS_PERCENTAGE: 'joint_report.OVERVIEW_AS_PERCENTAGE'
}

export const summaryReportByCrew = {
    OVERALL_QC_INSPECTIONS: 'Overall QC Inspections',
    CREW_SUMMARY: 'Crew Summary'
}

export const fillColor = '#acccfc';
export const POSITION_OF_GRAPH_VALUES = 'bottom'
export const TOTAL_INSPECTIONS = 'Total Inspections'
export const PRELIMINARY_INSPECTIONS = 'Preliminary Inspections'
export const TOTAL_CRITICAL_JOINTS = 'Total Critical Joints'
export const OUT_OF_CONFORMANCE = 'Out of Conformance'
export const AVERAGE_PER_WEEK = 'Average Per Week'
export const INSPECTIONS = 'Inspections'
export const CRITICAL_JOINTS = 'Critical Joints'
export const JOINT_STRESS_INDEX = 'Critical Joints JSI'
export const JOINT_STRESS_INDEX_AVERAGE = 'Critical Joints JSI Average'
export const CRITICAL_JOINTS_PER_INSPECTION = 'Critical Joints Per Inspection'
export const ZERO_CRITICAL_JOINTS = '0'
export const ONE_CRITICAL_JOINTS = '1'
export const TWO_CRITICAL_JOINTS = '2'
export const THREE_CRITICAL_JOINTS = '3'
export const FOUR_OR_MORE_CRITICAL_JOINTS = '4 or more'
export const MAX = 'Max'
export const AVG = 'Avg'
export const MEDIAN = 'Med'
export const MIN = 'Min'
export const MAX_SPAN = 'Max Span'
export const AVG_SPAN = 'Avg Span'
export const MEDIAN_SPAN = 'Median Span'
export const MIN_SPAN = 'Min Span'
export const MAX_HEIGHT = 'Max Height'
export const AVG_HEIGHT = 'Avg Height'
export const MEDIAN_HEIGHT = 'Median Height'
export const MIN_HEIGHT = 'Min Height'
export const ROOF_TRUSSES = 'Roof Trusses'
export const FLOOR_TRUSSES = 'Floor Trusses'
export const NO_OF_INSPECTED = 'Total'
export const TRUSSES = 'Trusses'
export const PRELIMINARY = 'Preliminary'
export const JOINTS = 'Joints'
export const MEMBERS = 'Members'
export const INSPECTION_INFORMATION_COUNT = 'Inspection Information(Count)'
export const JOINT_BREAKDOWN_COUNT = 'Joint Breakdown(Count)'
export const JOINT_BREAKDOWN_AS_PERCENTAGE = 'Joint Breakdown as Percentage'
export const TRUSS_OVERVIEW = 'Truss Overview'
export const JOINT_OVERVIEW = 'Joint Overview'
export const INSPECTION_COUNT = 'Inspection Count'
export const AVERAGE_INSPECTION = 'Average Inspection'
export const INCHES = 'Inches'
export const COUNT = 'Count'
export const JOINT_COUNT = 'Joint Count'
export const MEMBER_COUNT = 'Member Count'
export const PLATES_UPSIZED = 'Plates Upsized'
export const PASS_PERCENTAGE = 'Pass Percentage'
export const AVERAGE_DISTANCE = 'Average Distance'
export const AVERAGE_ROTATION = 'Average Rotation'
export const FRONT = 'Front Side'
export const BACK = 'Back Side'
export const PLATE = 'Plate'
export const LUMBER = 'Lumber'
export const PERCENTAGE = 'Percentage %'
export const VISUAL_INSPECTIONS = 'Visual Inspections'
export const CRITICAL_INSPECTIONS = 'Critical Inspections'
export const TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS = 'Total defects found in all inspections'
export const TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS = 'Total defects found in failed inspections'
export const PERCENT_DEFECTS_OF_JOINTS = 'Percentage of defects of all critical joints'
export const PERCENT_DEFECTS_OF_INSPECTIONS = 'Percentage of defects of total inspections'
export const PERCENT_OF_PLATES_UPSIZED = 'Percentage of plates upsized'
export const PERCENT_TRUSSES_OF_UPSIZED_PLATES = 'Percentage of trusses with upsized plates'
export const RATIO_OF_SQUARE_INCHES = 'Ratio of square inches'
export const AVERAGE_FEET = 'Average (Feet)'
export const ROOF_SPAN = 'Average Span - Roof Trusses'
export const FLOOR_SPAN = 'Average Span - Floor Trusses'
export const ROOF_CRITICAL = 'Average Critical Joints - Roof Trusses'
export const FLOOR_CRITICAL = 'Average Critical Joints - Floor Trusses'
export const TRUSS_COUNT = 'Truss Count'
export const TRUSSES_AS_PERCENTAGE = 'Trusses as Percentage'
export const AVERAGE_DISTANCE_FROM_DESIGNED_CENTER = 'Average Distance From Designed Center'
export const AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION = 'Average Rotation From Specified Location'
export const DISTANCE_IN_INCHES = 'Distance in inches'
export const ROTATION_IN_DEGREES = 'Rotation in degrees'
export const OVERVIEW_AS_PERCENTAGE = 'Overview as percentage'
export const MISPLACED_PLATES = 'Misplaced Plates'
export const ROTATED_PLATES = 'Rotated Plates'
export const UPSIZED_PLATES = 'Upsized Plates'
export const TRUSSES_PER_SHIFT_PER_WEEK = 'Trusses per Shift per Week'
export const HOURS_PER_WEEK = 'Hours per Week'
export const TARGET = 'Target'
export const ACTUAL = 'Actual'
export const HOURS = 'Hours'
export const ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES = 'Additional Square Inches of Upsized Plates'
export const SQUARE_INCHES = 'Square Inches'
export const OUT_OF_CONFORMANCE_JOINTS = 'Out of Conformance Joints'
export const JOINT_QC_DETAIL = 'Joint QC Detail'
export const TRUSSES_IN_DETAIL = 'Trusses In Detail'
export const PRELIMINARY_IN_DETAIL = 'Preliminary In Detail'
export const JOINTS_IN_DETAIL = 'Joints In Detail'
export const MEMBERS_IN_DETAIL = 'Members In Detail'
export const PASS = 'Pass'
export const PLATES_UPSIZED_OVERVIEW = 'Plates Upsized Overview'
export const PRELIMINARY_BREAKDOWN = 'Preliminary Breakdown'
export const JOINT_BREAKDOWN = 'Joint Breakdown'

export const SETUP_FREQUENCY = {
    SUB_REPORT_TITLE: 'Setup Frequency',
    RATIO_TITLE: 'Ratio',
    RATIO_ANGLE_FIELD: 'Percentage',
    RATIO_COLOR_FIELD: 'Setup Type',
    AVERAGE_SETUP_PER_SETUP_TITLE: 'Average Setup per Setup',
    AVERAGE_SETUP_PER_SETUP_X_AXIS_TITLE: 'Setup',
    AVERAGE_SETUP_PER_SETUP_Y_AXIS_TITLE: 'Avg Setups',
    AVERAGE_SETUP_PER_SETUP_X_FIELD: 'Shift',
    AVERAGE_SETUP_PER_SETUP_X_NAME_FIELD: 'Name',
    AVERAGE_SETUP_PER_SETUP_Y_FIELD: 'Avg Setups',
    AVERAGE_SETUP_PER_SETUP_SERIES_FIELD: 'Setup',
    AVERAGE_SETUP_PER_SETUP_SERIES_FIELD_NAME: 'Setup Name',
    AVERAGE_SETUPS_TOTAL_TITLE: 'Average Setups (Total)',
    AVERAGE_SETUPS_TOTAL_DATA_TITLE: 'Average Setups',
    AVERAGE_SETUPS_TOTAL_MEASURE_FIELD: 'Total'
}

export interface DataType {
    key: React.Key
    dataIndex?: string
    title: string
    width?: number
    children?: {key?:string|number}[]
}

export const totalInspectionConfigs = {
    trusses: 'Trusses',
    total_out: 'Out of Conformance',
    total_out_percent: 'Out of Conformance Percentage'
}

export const preliminaryInspectionConfigs = {
    preliminary_out: 'Out of Conformance',
    preliminary_out_percent: 'Out of Conformance Percentage',
    dimensions: 'Dimensions',
    lumber: 'Lumber',
    plate: 'Plate',
    teeth_gap: 'Teeth Gap',
    preliminary_rotation: 'Rotation',
    mbr_gap: 'Member to Member Gap'
}

export const criticalJointsConfigs = {
    joints: 'Joints',
    detailed_out: 'Out of Conformance',
    detailed_out_percent: 'Out of Conformance Percentage',
    size: 'Plate Size',
    gauge: 'Plate Gauge',
    zero_tol_poly_available: 'Zero Tolerance Polygon Available',
    zero_tol_polygon: 'Zero Tolerance Polygon',
    detailed_rotation: 'Rotation',
}

export const formattedTotalInspectionConfigs: ColumnsType<DataType> = Object.entries(totalInspectionConfigs).map(([key, value]) => ({
    title: value,
    dataIndex: key,
    key
}))
export const formattedPreliminaryInspectionConfigs: ColumnsType<DataType> = Object.entries(preliminaryInspectionConfigs).map(([key, value]) => ({
    title: value,
    dataIndex: key,
    key
}))
export const formattedCriticalJointsConfigs: ColumnsType<DataType> = Object.entries(criticalJointsConfigs).map(([key, value]) => ({
    title: value,
    dataIndex: key,
    key
}))

export const dateColumn: DataType = {
    title: 'Date',
    dataIndex: 'range',
    key: 'range',
    width: 200
}