import enUS from "antd/lib/locale/en_US";
import frFR from "antd/lib/locale/fr_FR";
import esES from "antd/lib/locale/es_ES";
import {DailyHourItem, DailyHoursData} from "../components/AddWeeklyHours";
export const writeToCSV = (res: any, fileName: string) => {
    const dataCSV = res.data;
    const filename = fileName;
    const blob = new Blob([dataCSV], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

export const languageMap = (language: any) => {
    switch (language) {
        case 'en':
            return enUS;
        case 'fr':
            return frFR;
        case 'es':
            return esES;
        default:
            return enUS;
    }
}
export const createEmptyDailyHoursData = (key: string): DailyHoursData => ({
    key,
    setup: '',
    shift: '',
    crew: '',
    week: null,
    sun: '',
    mon: '',
    tue: '',
    wed: '',
    thu: '',
    fri: '',
    sat: '',
})

export const processDailyHours = (hourData: DailyHoursData[], companyID: number | undefined, locationID: number | undefined) => {
    let dailyHours = {
        company_id: companyID,
        location_id: locationID,
        data: [] as DailyHourItem[]
    };
    const validatedRows = validateHours(hourData)
    if (!validatedRows.error) {
        for (let row of validatedRows.data) {
            dailyHours.data.push({
                shift_id: row.shift || '',
                line_id: row.setup || '',
                crew_id: row.crew || '',
                week: row?.week?.format('YYYY-MM-DD') || null,
                hours: [
                    parseFloat(row.sun),
                    parseFloat(row.mon),
                    parseFloat(row.tue),
                    parseFloat(row.wed),
                    parseFloat(row.thu),
                    parseFloat(row.fri),
                    parseFloat(row.sat)
                ]
            })
        }
    }
    return {
        dailyHours: dailyHours,
        error: validatedRows.error
    };
}

const validateHours = (hourData: DailyHoursData[]) => {
    let nonEmptyRows = []
    let errorMessage
    for (let datum of hourData) {
        const isEmptyRow = !(datum.shift || datum.setup || datum.crew || datum.week ||
            datum.sun || datum.mon || datum.tue || datum.wed ||
            datum.thu || datum.fri || datum.sat);
        if (!isEmptyRow) {
            nonEmptyRows.push(datum)
        }
    }
    for (let datum of nonEmptyRows) {
        if (!(datum.shift && datum.setup && datum.crew && datum.week)) {
            errorMessage = 'add_daily_hours.add_all_data'
            break
        }
        const allHoursEmpty = [datum.sun, datum.mon, datum.tue, datum.wed, datum.thu, datum.fri, datum.sat]
            .every(hour => hour === null || hour === "");
        if (allHoursEmpty) {
            errorMessage = 'add_daily_hours.warning_add_hours'
            break
        }
    }
    return {
        data: nonEmptyRows,
        error: errorMessage
    }
}