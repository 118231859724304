import {
    ACTUAL, certificationReports,
    CRITICAL_INSPECTIONS,
    FLOOR_TRUSSES,
    LUMBER, MISPLACED_PLATES,
    PLATE,
    ROOF_TRUSSES, ROTATED_PLATES, summaryReports, TARGET, TRUSSES,
    UPSIZED_PLATES,
    VISUAL_INSPECTIONS
} from "./reportConstants"

export const inchToFeet = (inches: number) => {
    const feet = Math.floor(inches / 12)
    const inch = Math.floor(inches % 12)
    const sixteenth = Math.round((inches % 1) * 16)
    return `${feet}-${inch}-${sixteenth}`
}

export const inchInFeet = (inches: number) => inches / 12

export const feetToInch = (feet: number, inches: number, sixteenth: number) => {
    if (inches < 12 && sixteenth < 16) {
        return (feet * 12) + inches + parseFloat((sixteenth / 16).toFixed(2))
    } else {
        return Promise.reject('Invalid inches or sixteenth')
    }
}

export const feetToInchByString = (value: string) => {
    const regex = /^\d+-\d{1,2}-\d{1,2}$/
    if (value.match(regex)) {
        const [feetAsStr, inchesAsStr, sixteenthAsStr] = value.split('-')
        const feet: number = parseInt(feetAsStr)
        const inches: number = parseInt(inchesAsStr)
        const sixteenth: number = parseInt(sixteenthAsStr)
        feetToInch(feet, inches, sixteenth)
    } else {
        return Promise.reject('Invalid argument')
    }
}

export const processTitle = (str: string) => {
    switch (str) {
        case 'size':
            return 'Plate Size'
        case 'gauge':
            return 'Manufacturer/Gauge'
        case 'embedment':
            return 'Plate Embedment'
        case 'placement':
            return 'Plate Rotation/Placement'
        case 'mbr_gap':
            return 'Member To Member Gap'
        case 'user':
            return 'Generated by'
        default:
            if (str.includes('_')) {
                let fullName = ''
                const names = str.split('_')
                names.forEach(name => fullName += name.substring(0, 1).toUpperCase() + name.substring(1) + ' ')
                return fullName
            }
            return str.substring(0, 1).toUpperCase() + str.substring(1)
    }
}

interface InspectionPassingBody {
    range: string
    inspections: number
    preliminary: number
    joints: number
}

export const processInspectionPassingData = (inspectionPassing: InspectionPassingBody[]) => {
    const inspectionPass = inspectionPassing.map(({range, inspections}) => ({
        name: 'Inspections',
        range: range,
        percent: inspections
    }))
    const preliminaryPass = inspectionPassing.map(({range, preliminary}) => ({
        name: 'Preliminary',
        range: range,
        percent: preliminary
    }))
    const jointPass = inspectionPassing.map(({range, joints}) => ({
        name: 'Joints',
        range: range,
        percent: joints
    }))
    return [...inspectionPass, ...preliminaryPass, ...jointPass]
}

export const processData = (inputData: any, isUnitConvert?: boolean) => {
    let data: any[] = []
    inputData?.forEach((quarter: any) => {
        Object.entries(quarter).filter(([key, value]) => key!=='range').forEach(([key, value]) => {
            data.push({
                Quarter: quarter.range,
                Average: isUnitConvert?inchInFeet(value as number):value,
                category: key
            })
        })
    })
    return data
}

export const processDefectFoundData = (inputData: any, type: 'defect' | 'defect_out' | 'joint' | 'inspection') => {
    let data: any[] = []
    inputData?.map((quarter: any) => {
        let plateCount
        let lumberCount
        switch (type) {
            case "defect":
                plateCount = quarter.plate.defects
                lumberCount = quarter.lumber.defects
                break
            case "defect_out":
                plateCount = quarter.plate.defects_out_of_conformance
                lumberCount = quarter.lumber.defects_out_of_conformance
                break
            case "joint":
                plateCount = quarter.plate.percent_joint
                lumberCount = quarter.lumber.percent_joint
                break
            case "inspection":
                plateCount = quarter.plate.percent_inspection
                lumberCount = quarter.lumber.percent_inspection
                break
            default:
                return Promise.reject('Invalid type')
        }
        const plate = {
            type: PLATE,
            name: quarter.range,
            count: plateCount
        }
        const lumber = {
            type: LUMBER,
            name: quarter.range,
            count: lumberCount
        }
        data.push(plate)
        data.push(lumber)
    })
    return data
}

export const processUpsizedPlatesData = (inputData: any) => {
    let data: any[] = []
    inputData?.map((quarter: any) => {
        const visual = {
            type: VISUAL_INSPECTIONS,
            name: quarter.range,
            count: quarter.visual_plates
        }
        const critical = {
            type: CRITICAL_INSPECTIONS,
            name: quarter.range,
            count: quarter.critical_plates
        }
        data.push(visual)
        data.push(critical)
    })
    return data
}

interface Truss {
    count: number
    percent: number
}

export interface TrussSelection {
    range: string
    roof: Truss
    floor: Truss
}

export const processTrussCountData = (inputData: TrussSelection[]) => {
    let data: any[] = []
    inputData?.forEach(({roof: {count: roofCount}, floor: {count: floorCount}, range}) => {
        data.push({
            Quarter: range,
            Count: roofCount,
            category: ROOF_TRUSSES
        })
        data.push({
            Quarter: range,
            Count: floorCount,
            category: FLOOR_TRUSSES
        })
    })
    return data
}

export const processTrussPercentData = (inputData: TrussSelection[]) => {
    let data: any[] = []
    inputData?.forEach(({roof: {percent: roofPercent}, floor: {percent: floorPercent}, range}) => {
        data.push({
            Quarter: range,
            Percent: roofPercent,
            category: ROOF_TRUSSES
        })
        data.push({
            Quarter: range,
            Percent: floorPercent,
            category: FLOOR_TRUSSES
        })
    })
    return data
}

interface InspectionDetailCritical {
    range: string
    center_misplaced_percent: number
    avg_center_misplaced_distance: number
    rotation_from_specified_percent: number
    avg_rotation_from_specified: number
    upsized_percent: number
}

export interface InspectionDetail {
    detailed: InspectionDetailCritical
}

export const processInspectionDetailData = (inputData: InspectionDetail[]) => {
    let data: any[] = []
    inputData?.forEach(({detailed: {range, center_misplaced_percent, rotation_from_specified_percent, upsized_percent}}) => {
        data.push({
            Quarter: range,
            Percent: center_misplaced_percent,
            category: MISPLACED_PLATES
        })
        data.push({
            Quarter: range,
            Percent: rotation_from_specified_percent,
            category: ROTATED_PLATES
        })
        data.push({
            Quarter: range,
            Percent: upsized_percent,
            category: UPSIZED_PLATES
        })
    })
    return data
}

interface FrequencySummaryData {
    target: number
    actual: number
}

export interface FrequencySummary {
    range: string
    trusses_per_shift_per_week: FrequencySummaryData
    hours_per_week: FrequencySummaryData
}

export const processFrequencySummaryData = (inputData: FrequencySummary[], isTruss: boolean) => {
    let data: any[] = []
    inputData?.forEach(({range, trusses_per_shift_per_week: {target: trussTarget, actual: trussActual}, hours_per_week: {target: hoursTarget, actual: hoursActual}}) => {
        data.push({
            Quarter: range,
            Count: isTruss?trussTarget:hoursTarget,
            category: TARGET
        })
        data.push({
            Quarter: range,
            Count: isTruss?trussActual:hoursActual,
            category: ACTUAL
        })
    })
    return data
}

export const mapTitle = (title: string) => {
    switch (title) {
        case 'size':
            return 'summary_report.title_mappings.plate_size'
        case 'gauge':
            return 'summary_report.title_mappings.manf_guage'
        case 'embedment':
            return 'summary_report.title_mappings.embedment'
        case 'placement':
            return 'summary_report.title_mappings.plate_rotation'
        case 'mbr_gap':
            return 'summary_report.title_mappings.gap'
        case 'user':
            return 'summary_report.title_mappings.generated_by'
        case 'dimensions':
            return 'summary_report.title_mappings.dimensions'
        case 'lumber':
            return 'summary_report.title_mappings.lumber'
        case 'polygon':
            return 'summary_report.title_mappings.polygon'
        case 'rotation':
            return 'summary_report.title_mappings.rotation'
        case 'teeth_found':
            return 'summary_report.title_mappings.teeth_found'
        default:
            return processTitle(title)
    }
}

export const mapTitleJoint = (title: string) => {
    switch (title) {
        case 'size':
            return 'joint_report.title_mappings.size'
        case 'type':
            return 'joint_report.title_mappings.type'
        case 'distance':
            return 'joint_report.title_mappings.distance'
        case 'rotation':
            return 'joint_report.title_mappings.rotation'
        case 'member_gap':
            return 'joint_report.title_mappings.gap'
        case 'distance_front':
            return 'joint_report.title_mappings.distance_front'
        case 'distance_back':
            return 'joint_report.title_mappings.distance_back'
        case 'orientation_front':
            return 'joint_report.title_mappings.orientation_front'
        case 'orientation_back':
            return 'joint_report.title_mappings.orientation_back'
        case 'zero_distance_misplaced':
            return 'joint_report.title_mappings.zero_distance_misplaced'
        case 'zero_rotation':
            return 'joint_report.title_mappings.zero_rotation'
        case 'zero_rotation_and_misplacement':
            return 'joint_report.title_mappings.zero_rotation_and_misplacement'
        case 'more_than_half_inch_distance_misplaced':
            return 'joint_report.title_mappings.more_than_half_inch_distance_misplaced'
        default:
            return processTitle(title)
    }
}

export const mapTitleCrew = (title: string) => {
    switch (title) {
        case 'dimensions':
            return 'crew_summary.title_mappings.dimensions'
        case 'lumber':
            return 'crew_summary.title_mappings.lumber'
        case 'size':
            return 'crew_summary.title_mappings.size'
        case 'gauge':
            return 'crew_summary.title_mappings.gauge'
        case 'embedment':
            return 'crew_summary.title_mappings.embedment'
        case 'placement':
            return 'crew_summary.title_mappings.placement'
        case 'mbr_gap':
            return 'crew_summary.title_mappings.mbr_gap'
        case 'rotation':
            return 'crew_summary.title_mappings.rotation'
        case 'polygon':
            return 'crew_summary.title_mappings.polygon'
        default:
            return processTitle(title)
    }
}