import React, {Fragment, FunctionComponent, useEffect, useRef, useState} from "react";
import {
    fetchUsersForReviewEmail,
    fetchWithoutPageData,
    sendDashboardReviewMail,
    UploadProps,
    uploadQCManual
} from "../../api";
import {Button, Divider, Form, Input, InputRef, message, notification, Select, Space, Upload, UploadFile} from "antd";
import {useLocation} from "react-router-dom";
import CustomPageHeader from "./CustomPageHeader";
import {PaperClipOutlined, PlusOutlined, SendOutlined} from "@ant-design/icons";
import {unstable_batchedUpdates} from "react-dom";
import {processName} from "../../utils/util";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface Item {
    label: string,
    value: string
}

interface ItemProps {
    value?: any
    onChange?: (value: any) => void
}

interface CustomSelectorProps extends ItemProps {
    company_id: number | undefined
    location_id: number | undefined
}

const CustomSelector: FunctionComponent<CustomSelectorProps> = ({value, onChange, company_id, location_id}) => {
    const [items, setItems] = useState<Item[]>([]);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    const {t} = useTranslation();
    const loadUsers = async () => {
        if(company_id && location_id) {
            const {data} = await fetchUsersForReviewEmail({company_id, location_id})
            setItems(data?.map((user: any) => ({label: `${user.name} [${processName(user.user_role)}]`, value: user.email})))
        }
    }

    useEffect(() => {
        loadUsers()
    }, [])

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setItems([...items, {label: name, value: name}]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <Select
            mode={"multiple"}
            placeholder={`${t('enter_reciever_email')}`}
            value={value}
            onChange={selected => onChange && onChange(selected)}
            dropdownRender={menu => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                            placeholder={`${t('please_enter_item')}`}
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            <Trans i18nKey={`add_item`} defaults={`Add Item`}/>
                        </Button>
                    </Space>
                </>
            )}
            options={items}
        />
    );
}

const FileUpload: FunctionComponent<ItemProps> = ({value, onChange}) => {
    const [file, setFile] = useState<UploadFile[]>([])
    const [upload, setUpload] = useState<UploadProps[]>([])

    return <Upload
        defaultFileList={file}
        customRequest={async opt => {
            const formData = new FormData()
            // @ts-ignore
            formData.append(opt.file.name, opt.file)
            const {data, status} = await uploadQCManual(formData)
            if(status===200) {
                // @ts-ignore
                const filename = opt.file.name
                const curUpload = {oid: data, filename: filename}
                message.success(<Trans i18nKey={`file_uploaded`} defaults={`File Uploaded`}/>)
                onChange && onChange([...upload, curUpload])
                unstable_batchedUpdates(() => {
                    setFile([...file, {uid: data.toString(), name: filename, status: "done"}])
                    setUpload([...upload, curUpload])
                })
                // @ts-ignore
                opt.onSuccess(data, opt.file)
            }
        }}
        onRemove={async removingFile => {
            const newUploadList = upload?.filter(u => u.oid!==removingFile?.response)
            onChange && onChange(newUploadList)
            message.warning(<Trans i18nKey={`file_removed`} defaults={`File Removed`}/>)
            unstable_batchedUpdates(() => {
                setFile(file?.filter(f => f.uid!==removingFile?.response?.toString()))
                setUpload(newUploadList)
            })
        }}
    >
        <Button className={'margin-five'} icon={<PaperClipOutlined />}>
            <Trans i18nKey={`attach_file`} defaults={`Attach File`}/>
        </Button>
    </Upload>
}

interface SendMailProps {
    locationID: number | undefined
    companyID: number | undefined
}

const SendMail: FunctionComponent<SendMailProps> = ({companyID: company_id, locationID: location_id}) => {
    const location: {pathname: string, state: {[key: string]: string}} = useLocation()
    const start_date = location?.state?.start_date
    const end_date = location?.state?.end_date
    const quarter_date = location?.state?.quarter_date
    const quarter = location?.state?.quarter
    const weeks = location?.state?.weeks

    type Contact = {
        to: string[],
        cc: string[]
    }

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false)
    const [rendered, setRendered] = useState<boolean>(false)
    const [contact, setContact] = useState<Contact>()
    const {t} = useTranslation();

    const loadContacts = async () => {
        const payload = {
            tableName: 'review_email_contact',
            filter: [{name: 'location_id', value: location_id}]
        }
        const {data: {rows: [row] =[]} ={}} = await fetchWithoutPageData(payload)
        unstable_batchedUpdates(() => {
            setContact({to: row?.send_to || [], cc: row?.cc || []})
            setRendered(true)
        })
    }

    useEffect(() => {
        loadContacts()
    },[])

    const onFinish = async (values: any) => {
        setLoading(true)
        const {status} = await sendDashboardReviewMail({
            ...values,
            company_id,
            location_id,
            quarter,
            start_date,
            end_date,
            weeks: parseFloat(weeks)
        })
        setLoading(false)
        if(status===200) notification.success({message: <Trans i18nKey={`mail_sent_successfully`} defaults={`Mail Sent Successfully`}/>})
    }

    const { Item } = Form

    return <Fragment>
        <CustomPageHeader
            title={'Send Mail'}
            start_date={start_date}
            end_date={end_date}
            quarter_date={quarter_date}
            quarter_name={quarter}
            weeks={weeks}
        />
        {rendered && <div style={{marginLeft: '20%', marginRight: '20%', marginTop: 20}}>
            <Form
                name={'direct_message'}
                form={form}
                initialValues={contact}
                onFinish={onFinish}
            >
                <Item
                    label={'To'}
                    name={'to'}
                    rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (value === null || (typeof getFieldValue('to') === 'object' && getFieldValue('to').length > 0)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(`${t('send_mail_error')}`));
                            },
                        })
                    ]}
                >
                    <CustomSelector company_id={company_id} location_id={location_id}/>
                </Item>
                <Item
                    label={'Cc'}
                    name={'cc'}
                >
                    <CustomSelector company_id={company_id} location_id={location_id}/>
                </Item>
                <Item name={'subject'} rules={[{required: true, message: <Trans i18nKey={`please_enter_object`} defaults={`Please enter subject`}/>}]}>
                    <Input
                        placeholder={`${t('subject')}`}/>
                </Item>
                <Item name={'upload'}>
                    <FileUpload/>
                </Item>
                <Item>
                    <Button
                        type={"primary"}
                        loading={loading}
                        htmlType={"submit"}
                        style={{float: "right"}}
                        icon={<SendOutlined/>}
                    ><Trans i18nKey={`send`} defaults={`Send`}/></Button>
                </Item>
            </Form>
        </div>}
    </Fragment>
}

export default SendMail
