import React, {ReactNode, useEffect, useState} from 'react'
import {Page, TableContext} from "@q4us-sw/q4us-ui/lib/TableV2";
import {Form} from '@q4us-sw/q4us-ui'
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import moment from "moment"
import { ViewPortContext } from '../../view/MainView';
import {Button, Drawer, notification} from "antd";
import {AxiosResponse} from "axios";
import {unstable_batchedUpdates} from "react-dom";
import {configs} from "../../api";
import '../../styles/TableFilter.css'
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";
import i18next from "i18next";

export interface TableFilterProps {
    schema: string,
    fetch: (values: { [key: string]: any }, page?: Page, sort?:{column:string,direction:string}) => Promise<AxiosResponse<any>>
    visibilityValidator?: (schema: string, element: FormElementProps, value: FormInstance<any>) => boolean
    overrideComponent?:(schema: string, element: FormElementProps, form: FormInstance<any>) => ReactNode | undefined
    initialValue?: {[key:string]: any}
    notifyLocation?: boolean
    onFilter?: (values: any) => void
    userFlag?: boolean
}

export const FilterWrapper: React.FunctionComponent = (props) => {
    const [collapsed, setCollapsed] = React.useState(false)

    return <ViewPortContext.Consumer>
        {
            view => <React.Fragment>
                <div className={'table-filter'}
                     style={{height: view.isMobileView ? 45 : 95, display: view.isMobileView ? 'flex' : undefined}}>
                    {(!view.isMobileView) && props.children}
                    {view.isMobileView && <React.Fragment>
                        <Button onClick={() => setCollapsed(!collapsed)}/>
                    </React.Fragment>}
                </div>
                {view.isMobileView &&
                    <Drawer className={`form-drawer`} bodyStyle={{padding: 0}} visible={!collapsed}
                            onClose={() => setCollapsed(true)} title={"Filter"} placement={"right"}>
                        {props.children}
                    </Drawer>}
            </React.Fragment>
        }
    </ViewPortContext.Consumer>
}


export const TableFilter: React.FunctionComponent<TableFilterProps> = (props) => {
    const [filterConfigs, setFilterConfigs] = useState([])
    const {t} = useTranslation();

    useEffect(() =>{
        const fetchConfigs = async () =>{
            const res = await configs(props.schema);
            setFilterConfigs(res.data?.rows||[])
        }
        fetchConfigs()
    }, []);

    return <div key={i18next.language}>
        <ViewPortContext.Consumer>
            {view => <TableContext.Consumer>
                {table => <FilterWrapper>
                    <Form
                        layout={'inline'}
                        acceptText={<Trans i18nKey={`filter`} defaults={`Filter`} />}
                        rejectText={<Trans i18nKey={`clear`} defaults={`Clear`} />}
                        initialValue={props.initialValue}
                        schema={props.schema}
                        config={async (schema: string) => {
                            // const res = await configs(schema);
                            const updatedFilterConfigs = filterConfigs.map((row: any) => {
                                if (row.name) {
                                    row.title =  t(`${props.schema}.${row.name}`, row.title);
                                }
                                if(row.title === 'State'){
                                    row.title = t(`${props.schema}.state_name`, row.title)
                                } else if(row.title === 'Province') {
                                    row.title = t(`${props.schema}.province_name`, row.title)
                                }
                                return row;
                            });
                            return updatedFilterConfigs;
                        }}
                        visibilityValidator={props.visibilityValidator}
                        overrideComponent={props.overrideComponent}
                        submit={async (values: any) => {
                            if(props.notifyLocation) {
                                notification.info({message: <Trans i18nKey={`please_enter_company_and_location`} defaults={`Please select company and location first`} />})
                                return false
                            }
                            let modified = { ...table?.customData?.values, ...values };
                            if (props.userFlag) {
                                modified = { ...values };
                            }
                            if(values.job_date_range) {
                                const {job_date_range} = values
                                const [job_start_date, job_end_date] = job_date_range
                                delete values.job_date_range
                                modified = {...values, job_start_date, job_end_date}
                            }
                            if(values.audit_date_range) {
                                const {audit_date_range} = values
                                const [started, completed] = audit_date_range
                                delete values.audit_date_range
                                modified = {...values, started, completed}
                            }
                            props.onFilter && props.onFilter(modified)
                            table?.setLoading(true)
                            const res = await props.fetch(modified,
                                {current: 0, total: 0, size: table?.page?.size || 100},
                                table?.customData?.inbuilt?.sort);

                            // ?? - Nullish coalescing operator - Not supported by IE
                            unstable_batchedUpdates(() => {
                                table?.setData(res.data?.rows || [])
                                table?.setCustomData( {...table?.customData, values:modified})
                                table?.setPage({
                                    current: 1,
                                    total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                    size: table?.page?.size ?? table?.props.defaultPageSize ?? 100
                                })
                                table?.setLoading(false)
                            })
                            return false;
                        }}
                        rejected={async () => {
                            table?.setLoading(true)
                            const res = await props.fetch({},
                                {current: 0, total: 0, size: table?.page?.size || 100},
                                table?.customData?.inbuilt?.sort);

                            // ?? - Nullish coalescing operator - Not supported by IE
                            unstable_batchedUpdates(() => {
                                table?.setData(res.data?.rows || [])
                                table?.setCustomData({...table?.customData, values:{}})
                                table?.setPage({
                                    current: 1,
                                    total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                    size: table?.page?.size ?? table?.props.defaultPageSize ?? 100
                                })
                                table?.setLoading(false)
                            })
                            return false;
                        }}
                    />
                </FilterWrapper>}
            </TableContext.Consumer>}
        </ViewPortContext.Consumer>
    </div>
}