import React, {FocusEventHandler, useCallback, useState} from "react";
import {LocationsFilterBaseProps} from "./filters/LocationsFilter";
import {
    Button,
    Col,
    DatePicker, InputNumber, Modal, notification,
    PageHeader, Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Typography
} from "antd";
import {Trans} from "./lib/CustomTransComponent";
import {PlusCircleOutlined} from "@ant-design/icons";
import {useHistory, useLocation} from "react-router-dom";
import {fetchDailyHoursWeekly, setDailyHoursWeekly} from "../api";
import {Moment} from "moment";
import dayjs, { Dayjs } from 'dayjs';
import {useTranslation} from "react-i18next";
import DatePickerCustomWeekStart from "./lib/DatePickerCustomWeekStart";
import {createEmptyDailyHoursData, processDailyHours} from "../utils/functions";
export interface AddWeeklyHoursProps extends Partial<LocationsFilterBaseProps>{

}
interface SelectColumnProps {
    value: string;
    options: string[];
    onChange: (value: string) => void;
    style?: React.CSSProperties;
}
const {Option} = Select
const SelectColumn: React.FC<SelectColumnProps> = ({ value, options, onChange, style }) => (
    <Select
        value={value}
        style={{ width: 10 }}
        onChange={onChange}
    >
        {options && options.length > 0 ? (
            options.map((option: string, index: number) => (
                <Option key={option[0]}>{option[1]}</Option>
            ))
        ) : (
            <Option value="" disabled> </Option>
        )}
    </Select>
);
interface InputNumberColumnProps {
    columnKey: string;
    value: number;
    onBlur: FocusEventHandler<HTMLInputElement>
}

const InputNumberColumn: React.FC<InputNumberColumnProps> = ({ columnKey, value,  onBlur }) => (
    <InputNumber
        key={columnKey}
        min={0}
        max={15}
        step={0.25}
        value={value}
        onBlur={onBlur}
        style={{ width: 60 }}
    />
);
export interface DailyHourItem {
    shift_id: string,
    line_id: string,
    crew_id: string,
    week: string | null,
    hours: (number | null) []
}
interface WeekHour {
    sun: string;
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    sat: string;
}
export interface DailyHoursData {
    key: string;
    setup: string;
    shift: string;
    crew: string;
    week: Dayjs | null;
    sun: string;
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    sat: string;
}

export const AddWeeklyHours: React.FunctionComponent<AddWeeklyHoursProps> = (props) => {
    const initialData: DailyHoursData[] = Array.from({ length: 10 }, (_, index) =>
        createEmptyDailyHoursData(index.toString())
    );

    const {Text} = Typography
    const {Option} = Select
    const [allData, setAllData] = useState<DailyHoursData[]>(   initialData);
    const history = useHistory()
    const location = useLocation<any>()
    const {enumList} = location.state
    const companyName = props.companies?.find(({id})=>id===props.companyID)?.name;
    const locationName = props.locations?.find(({id})=>id===props.locationID)?.name;
    const onSubmitHours = async () => {
        const hasNonEmptyRow = allData.some(datum =>
            datum.shift || datum.setup || datum.crew || datum.week ||
            datum.sun || datum.mon || datum.tue || datum.wed ||
            datum.thu || datum.fri || datum.sat
        );
        if (allData.length===0 || !hasNonEmptyRow) {
            notification.warning({message: <Trans i18nKey={'add_daily_hours.no_data_entered'}>No data entered!</Trans>})
            return false
        }

        const {dailyHours, error} = processDailyHours(allData, props.companyID, props.locationID);
        if (error) {
            notification.warning({message: <Trans i18nKey={error}/>})
            return false
        }
        let {data = {}} = await setDailyHoursWeekly({tableName: 'daily_hours', data: dailyHours})
        if (data?.statusCode === 200) {
            notification.success({message: <Trans i18nKey={'add_daily_hours.created_updated_successfully'}>Daily hours created/updated successfully.</Trans>})
            history.push({
                pathname: '/view/daily-hours-reports'
            })
            return true
        } else {
            notification.error({message: <Trans i18nKey={'add_daily_hours.creation_failed'}>Daily hours creation failed. Please try again.</Trans>})
        }
    }
    const handleAdd = useCallback(() => {
        const count = allData.length;
        const newData =createEmptyDailyHoursData(count.toString())
        setAllData(prevData => [...prevData, newData]);
    }, [])
    const handleFieldChange = async (index: number, field: keyof DailyHoursData, value: any) => {
        const rowData = {...allData.find(item => {
            return parseInt(item.key) === index
        }), [field]:value};

        if (rowData && rowData.setup && rowData.shift && rowData.crew && rowData.week) {
            const isDuplicate = checkForDuplicates(rowData.setup, rowData.shift, rowData.crew, rowData.week, index)
            if (!isDuplicate) {
                const weekHours = await fetchWeekData(rowData.setup, rowData.shift, rowData.crew, rowData.week, index);
                const isRowPopulated =
                    [rowData.sun, rowData.mon, rowData.tue, rowData.wed, rowData.thu, rowData.fri, rowData.sat]
                        .some(day => day !== null && day !== "");
                if (weekHours){
                    if (isRowPopulated) {
                        const isWeekEmpty = Object.values(weekHours).every(day => day === '');
                        if (isWeekEmpty) {
                            handlePopulateDays(index, field, value, weekHours)
                        } else {
                            Modal.confirm({
                                title: <Trans i18nKey={'add_daily_hours.warning'}>Warning</Trans>,
                                content: <Trans i18nKey={'add_daily_hours.warning_content'}>
                                    This row already has data. Do you want to overwrite the existing data?
                                </Trans>,
                                okText: <Trans i18nKey={'add_daily_hours.yes'}>Yes</Trans>,
                                cancelText: <Trans i18nKey={'add_daily_hours.no'}>No</Trans>,
                                onOk: () => {
                                    handlePopulateDays(index, field, value, weekHours)
                                }
                            });
                        }
                    } else {
                        handlePopulateDays(index, field, value, weekHours)
                    }
                }
            } else {
                notification.warning({message: <Trans i18nKey={`add_daily_hours.duplicate_content`} />})
            }
        } else {
            setAllData(prevData => prevData.map(item => {
                return parseInt(item.key) === index ? {...item, [field]: value} : item
            }));
        }
    }
    const handlePopulateDays = (index: number, field: keyof DailyHoursData, value: any, weekHours: WeekHour) => {
        setAllData(prevData=>prevData.map(item => {
            if (parseInt(item.key) === index) {
                return { ...item, ...weekHours, [field]: value };
            }
            return item;
        }));
    }

    const handleDateChange = async(index: number, date: Moment|null) => {
        const dayjsDate = date ? dayjs(date.toDate()) : null;
        const sunday = dayjsDate?.startOf('week');
        await handleFieldChange(index, 'week', sunday);
    }
    const checkForDuplicates = (line_id: string, shift_id: string, crew_id: string, week: Dayjs|null, recordKey: number) => {
        const isDuplicate = allData.some((item, index) => {
                return item.setup === line_id &&
                item.shift === shift_id &&
                item.crew === crew_id &&
                item.week?.format('YYYY-MM-DD') === week?.format('YYYY-MM-DD') &&
                parseInt(item.key) !== recordKey
            }
        );
        return isDuplicate
    }
    const fetchWeekData = async (line_id: string, shift_id: string, crew_id: string, week: Dayjs | null, key: number) => {
        const response = await fetchDailyHoursWeekly({
            week: week?.format('YYYY-MM-DD'),
            line_id: line_id,
            shift_id: shift_id,
            crew_id: crew_id,
            company_id: props.companyID,
            location_id: props.locationID
        })
        if (response.status === 200) {
            const hoursArray = response.data.rows.map((item: { total_hours: number; }) => item.total_hours);
            const weekHours = {
                sun: hoursArray[0] || '',
                mon: hoursArray[1] || '',
                tue: hoursArray[2] || '',
                wed: hoursArray[3] || '',
                thu: hoursArray[4] || '',
                fri: hoursArray[5] || '',
                sat: hoursArray[6] || '',
            };
            return weekHours;
        } else {
            notification.error({message: <Trans i18nKey={`add_daily_hours.error_data_fetch`} />});
        }

    };
    const handleDelete = useCallback((key: React.Key) => {
        setAllData(prevData => {
            const updatedData = prevData.filter((item) => item.key !== key);

            return updatedData.map((item, index) => ({
                ...item,
                key: index.toString(),
            }));
        });
    }, []);
    const handleBlur = (index: number, field: string) => (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value ? parseFloat(event.target.value) : null;
        setAllData(prevData =>
            prevData.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item
            )
        );
    };
    const {t} = useTranslation()

    const columns = [
        {
            title: `${t('add_daily_hours.columns.shift', 'Shift')}`,
            dataIndex: 'shift',
            key: 'shift',
            width: 40,
            render: (text: string, e: any, index: number) => (
                <SelectColumn
                    value={text}
                    options={enumList.shifts}
                    onChange={value => handleFieldChange(index, 'shift', value)}
                    style={{ width: 50 }}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.setup', 'Setup')}`,
            dataIndex: 'setup',
            key: 'setup',
            width: 40,
            render: (text: string, e: any, index: number) => (
                <SelectColumn
                    value={text}
                    options={enumList.lines}
                    onChange={value => handleFieldChange(index, 'setup', value)}
                    style={{ width: 50 }}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.crew', 'Crew')}`,
            dataIndex: 'crew',
            key: 'crew',
            width: 40,
            render: (text: string, e: any, index: number) => (
                <SelectColumn
                    value={text}
                    options={enumList.crews}
                    onChange={value => handleFieldChange(index, 'crew', value)}
                    style={{ width: 50 }}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.week', 'Week')}`,
            dataIndex: 'week',
            key: 'week',
            width: 50,
            render: (text: string, e: any, index: number) => (
                <DatePicker
                    onChange={date => handleDateChange(index, date)}
                    picker="week"
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.sun', 'Sun')}`,
            dataIndex: 'sun',
            key: 'sun',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`sun`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'sun')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.mon', 'Mon')}`,
            dataIndex: 'mon',
            key: 'mon',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`mon`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'mon')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.tue', 'Tue')}`,
            dataIndex: 'tue',
            key: 'tue',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`tue`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'tue')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.wed', 'Wed')}`,
            dataIndex: 'wed',
            key: 'wed',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`wed`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'wed')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.thu', 'Thu')}`,
            dataIndex: 'thu',
            key: 'thu',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`thu`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'thu')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.fri', 'Fri')}`,
            dataIndex: 'fri',
            key: 'fri',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`fri`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'fri')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.sat', 'Sat')}`,
            dataIndex: 'sat',
            key: 'sat',
            width: 40,
            render: (enteredValue: number, value: any, index: number) => (
                <InputNumberColumn
                    columnKey={`sat`}
                    value={enteredValue}
                    onBlur={handleBlur(index,'sat')}
                />
            ),
        },
        {
            title: `${t('add_daily_hours.columns.operation', 'Action')}`,
            dataIndex: 'operation',
            width: 40,
            render: (_: any, record: any) =>
                allData.length >= 1 ? (
                    <Popconfirm
                        title={`${t('add_daily_hours.sure_delete', 'Sure to Delete?')}`}
                        onConfirm={() => {
                        handleDelete(record.key)
                    }}
                    >
                        <a><Trans i18nKey={'add_daily_hours.delete'}>Delete</Trans></a>
                    </Popconfirm>
                ) : null,
        }
    ];
    return <React.Fragment>
            <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                <Row>
                    <Col span={12}>
                        <PageHeader title={<Trans i18nKey={'add_daily_hours.add_hours_weekly'}>Add Hours Weekly</Trans>}/>
                    </Col>
                </Row>
            </div>
        <Space style={{margin: 10, marginLeft: '25vw', marginBottom: 5}}>
            <Text strong><Trans i18nKey={'add_daily_hours.company'}>Company</Trans></Text>
            <Select
                showSearch
                disabled
                value={companyName}
                style={{width: 400}}
                placeholder={`$t('add_daily_hours.select_company_placeholder', 'Please select company...')`}
                optionFilterProp="children"
            >
                <Option key={props.companyID} value={props.companyID} children={companyName}/>
            </Select>
        </Space>
        <Space style={{margin: 10, marginLeft: '25vw', marginTop: 5}}>
            <Text strong style={{marginRight: 6}}><Trans i18nKey={'add_daily_hours.location'}>Location</Trans></Text>
            <Select
                showSearch
                disabled
                value={locationName}
                style={{width: 400}}
                placeholder={`$t('add_daily_hours.select_location_placeholder', 'Please select location...')`}
                optionFilterProp="children"
            >
                <Option key={props.locationID} value={props.locationID} children={locationName}/>
            </Select>
        </Space>
        <div>
            <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                <Trans i18nKey={'add_daily_hours.add_row'}>Add a row</Trans>
            </Button>
            <Space style={{margin: 10, marginLeft: '80vw', marginBottom: 5}}>
                <Button
                    type="primary"
                    icon={<PlusCircleOutlined/>}
                    disabled={allData.length === 0}
                    onClick={onSubmitHours}> <Trans i18nKey={'add_daily_hours.submit'}>Submit</Trans>
                </Button>
            </Space>
            <Table
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={allData}
                columns={columns}
                rowKey="key"
                pagination={false}
            />
        </div>
        </React.Fragment>;
}