import React, {useEffect} from "react";
import { AuthContext } from "../user/AuthProvider";
import {Col, Row, Select, Space, Typography} from "antd";
import {unstable_batchedUpdates} from "react-dom";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";


interface DropDownComponentProps{
    title: React.ReactNode
    items:any[]
    filterID?: number
    placeHolder?: string
    value?:number
    onChange?:(value:number|undefined, items:any[])=>void
    disabled?:boolean
}

export interface LocationsFilterBaseProps {
    companyID?: number
    locationID?: number
    companies:any[]
    locations:any[]
    setCompanyID: (id?:number)=>void
    setLocationID: (id?:number)=>void
}

export interface LocationsFilterProps extends LocationsFilterBaseProps{
    initialFilters?: { [key: string]: any }
    onChange?:(value:number|undefined, items:any[])=>void
    onChangeCompany?:(value:number|undefined, items:any[])=>void
    disabledValidatorLocation?:(user:any)=>boolean
    disabledValidatorCompany?:(user:any)=>boolean
}

export const DropDownComponent: React.FunctionComponent<DropDownComponentProps> = (props) => {
    const {Text} = Typography;
    const {Option} = Select;

    useEffect( ()=>{
        if(props.items.find(({id})=>id===props.value)){
            if(props.value){
                if(props.title==='Location'){
                    props.onChange?.(props.value, props.items)
                }
            }
        }
        else{
            props.onChange?.(undefined, props.items)
        }
    },[props.value, props.items])

    if (props.disabled !== false){
        return <Space style={{width: '25vw', margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={7}>
                    <Text strong style={{marginRight: 6}}>{props.title}</Text>
                </Col>
                <Col span={7}>
                    <Select
                        key={props.filterID}
                        showSearch
                        disabled
                        value={props.items?.[0]?.name}
                        style={{width: 400}}
                        placeholder=''
                        optionFilterProp="children"
                    />
                </Col>
            </Row>
        </Space>
    }
    else{
        return <Space style={{width: '25vw', margin: 10, marginLeft:'25vw', marginTop: 5}}>
            <Row>
                <Col span={7}>
                    <Text style={{marginRight: 6}} strong>{props.title}</Text>
                </Col>
                <Col span={7} >
                    <Select
                        key={props.filterID}
                        showSearch
                        value={props.value}
                        style={{width: 400}}
                        placeholder={props.placeHolder}
                        optionFilterProp="children"
                        onChange={
                            (value: number) => {
                                props.onChange?.(value, props.items)
                            }
                        }
                    >
                        {props.items?.map(({id, name}) => <Option key={id} value={id} children={name}/>)}
                    </Select>
                </Col>
            </Row>
        </Space>
    }
}

export const LocationsFilter:React.FunctionComponent<LocationsFilterProps> = (props) =>{
    const {t} = useTranslation();

    return <AuthContext.Consumer>{auth=><div>
        <DropDownComponent
            title={<Trans i18nKey={`company.company_name`} defaults={`Company`}/>}
            items={props.companies}
            placeHolder={`${t('please_enter_company')}`}
            value={props.companyID}
            onChange={(value, items)=>{
                unstable_batchedUpdates(()=>{
                    props.setCompanyID(value);
                    props.setLocationID(undefined);
                    props.onChangeCompany?.(value, items);
                })
            }}
            disabled={props.disabledValidatorCompany?props.disabledValidatorCompany(auth.user):false}
        />
        <DropDownComponent
            title={<Trans i18nKey={`location`} defaults={`Location`}/>}
            items={props.locations}
            filterID={props.companyID}
            placeHolder={`${t('please_enter_location')}`}
            value={props.locationID}
            onChange={async (value, items) => {
                unstable_batchedUpdates(() => {
                    props.setLocationID(value);
                    props.onChange?.(value, items);
                })
            }}
            disabled={props.companyID===undefined || props.disabledValidatorLocation?props.disabledValidatorLocation?.(auth.user):false}
        />
    </div>}</AuthContext.Consumer>
}