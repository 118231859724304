import React, {Fragment, useMemo} from "react";
import {Card, Col, Collapse, Empty, Row, Space, Typography} from "antd";
import {
    AVERAGE_INSPECTION,
    AVERAGE_PER_WEEK,
    averageReports,
    AVG_HEIGHT,
    AVG_SPAN,
    CRITICAL_JOINTS,
    JOINT_STRESS_INDEX,
    CRITICAL_JOINTS_PER_INSPECTION,
    FLOOR_TRUSSES,
    FOUR_OR_MORE_CRITICAL_JOINTS,
    graphValues,
    INCHES,
    INSPECTION_COUNT,
    INSPECTION_INFORMATION_COUNT,
    INSPECTIONS,
    JOINT_BREAKDOWN_AS_PERCENTAGE,
    JOINT_BREAKDOWN_COUNT,
    MAX_HEIGHT,
    MAX_SPAN,
    MIN_HEIGHT,
    MIN_SPAN,
    ONE_CRITICAL_JOINTS,
    OUT_OF_CONFORMANCE,
    POSITION_OF_GRAPH_VALUES,
    ROOF_TRUSSES,
    SETUP_FREQUENCY,
    THREE_CRITICAL_JOINTS,
    TOTAL_CRITICAL_JOINTS,
    TOTAL_INSPECTIONS,
    TRUSS_OVERVIEW,
    TWO_CRITICAL_JOINTS,
    ZERO_CRITICAL_JOINTS,
    JOINT_STRESS_INDEX_AVERAGE,
    MEDIAN_SPAN,
    MEDIAN_HEIGHT,
    fillColor
} from "../../utils/reportConstants";
import {Bullet, Column, Pie} from "@ant-design/plots";
import {inchToFeet} from "../../utils/report";
import {Trans} from "./../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface Line {
    line_name: string
    inspections: number
    active_line: boolean
}

interface Shift {
    shift_name: string
    lines: Line[]
}

interface Crew {
    crew_name: string
    inspections: number
    active_crew: boolean
}

interface ISetupFrequency{
    ratio: {count:number, truss_type:string}[],
    avg_setup_per_setup: {shift_id: number, shift: string, line_id:number,setup:string, avg_setups:number}[],
    avg_setup_total: any
    meta: {setups: number, shifts: number}
}

interface AverageReportProps {
    inspectionInformation: any
    jointBreakdown: any
    dimensionBreakdown: any
    inspectionFrequency: Shift[]
    inspectionFrequencyByCrew: Crew[]
    setupFrequency: ISetupFrequency
}

interface SetupFrequencyProps {
    setupFrequency: ISetupFrequency
    isPDFView?:boolean
}


export const SetupFrequency: React.FunctionComponent<SetupFrequencyProps> = ({setupFrequency:{
    ratio, avg_setup_per_setup,avg_setup_total, meta}, isPDFView
                                                                             }) => {

    const {t} = useTranslation();

    const ComponentRatio = useMemo(()=><>
        {ratio?<Pie
            angleField={SETUP_FREQUENCY.RATIO_ANGLE_FIELD}
            colorField={SETUP_FREQUENCY.RATIO_COLOR_FIELD}
            label={{
                type: 'inner',
                offset: '-30%',
                content: ({percent}) => `${Math.round(percent * 100)}%`,
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            }}
            radius={0.8}
            data={ratio.map(({count, truss_type})=>({
                [SETUP_FREQUENCY.RATIO_COLOR_FIELD]: `${t('AVERAGE_REPORT.SETUP_FREQUENCY.SETUP_TYPE', `${truss_type} Setups`, {truss_type})}`,
                [SETUP_FREQUENCY.RATIO_ANGLE_FIELD]: count
            }))}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[ratio, t]);

    const ComponentAvgSetupPerSetup = useMemo(()=><>
        {avg_setup_per_setup?<Column
            xField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_FIELD}
            yField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_FIELD}
            isGroup={true}
            seriesField={SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD}
            yAxis={{
                title: {
                    text: `${t('AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_AXIS_TITLE', SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_AXIS_TITLE)}`
                }
            }}
            xAxis={{
                title: {
                        text:`${t('AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_AXIS_TITLE', SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_AXIS_TITLE)}`
                },
                label:{
                    // id is used as x-axis field name to handle duplicates in setup names
                    // override id with setup name for displaying
                    formatter: (data, item,index)=>{
                        const setup = avg_setup_per_setup.find(({shift_id})=>shift_id.toString() === data);
                        return setup?.shift || data;
                    },
                    autoRotate: true
                }
            }}
            legend={
                {
                    itemName:{
                        formatter: (text: string, item: any, index: number) => {
                            const setup = avg_setup_per_setup.find(({line_id})=>line_id.toString() === text);
                            return setup?.setup || text;
                        }
                    }
                }
            }
            tooltip={{
                title: (title: string, datum)=> {
                    return datum[SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_NAME_FIELD];
                },
                customItems: (originalItems: any[]) => {
                    return originalItems.map(((item: any)=> {return {...item, name: item.data[SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD_NAME]}}));
                }
            }}
            appendPadding={20}
            label={avg_setup_per_setup.length<=12?{
                position: 'top'
            }:false}
            data={avg_setup_per_setup.map(({shift_id, shift, line_id, setup, avg_setups})=>({
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_FIELD]: shift_id.toString(),
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_Y_FIELD]: avg_setups,
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_NAME_FIELD]: shift,
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD]: line_id.toString(),
                [SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_SERIES_FIELD_NAME]: setup
            }))}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[avg_setup_per_setup, t]);

    const ComponentAvgSetupTotal = useMemo(()=><>
        {avg_setup_total?<Bullet
            measureField={`${t('AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD', SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD)}`}
            rangeField={'ranges'}
            targetField={'target'}
            xField={'title'}
            data={[
                {
                    title: `${t('AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_X_AXIS_TITLE', SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_DATA_TITLE)}`,
                    ranges: [avg_setup_total.range],
                    [t('AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD', SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_MEASURE_FIELD)]: [avg_setup_total.total_setups],
                    target: avg_setup_total.target,
                }
            ]}
        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </>,[avg_setup_total, t]);

    if (isPDFView){
        const displayAvgSetup = (meta.setups <= 4) &&  meta.setups * meta.shifts <= 15;
        return <>
            {displayAvgSetup&&<Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE'}>{SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE}</Trans>} type={"inner"} style={{pageBreakInside: "avoid"}}>
                {ComponentAvgSetupPerSetup}
            </Card>}
            <Space style={{pageBreakInside: "avoid"}}>
                <Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE'}>{SETUP_FREQUENCY.RATIO_TITLE}</Trans>} type={"inner"} style={{minWidth: '19vw'}}>
                    {ComponentRatio}
                </Card>
                <Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE'}>{SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE}</Trans>} type={"inner"} style={{minWidth: '19vw'}}>
                    {ComponentAvgSetupTotal}
                </Card>
            </Space>
        </>
    }else{
        const displayAvgSetup = meta.setups * meta.shifts <= 15;
        return <Space>
            <Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.RATIO_TITLE'}>{SETUP_FREQUENCY.RATIO_TITLE}</Trans>} style={{width: '30vw', margin: 15}}>
                {ComponentRatio}
            </Card>
            {displayAvgSetup&&<Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE'}>{SETUP_FREQUENCY.AVERAGE_SETUP_PER_SETUP_TITLE}</Trans>} style={{width: '25vw'}}>
                {ComponentAvgSetupPerSetup}
            </Card>}
            <Card title={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE'}>{SETUP_FREQUENCY.AVERAGE_SETUPS_TOTAL_TITLE}</Trans>} style={{width: '25vw'}}>
                {ComponentAvgSetupTotal}
            </Card>
        </Space>
    }
}

const AverageReport: React.FunctionComponent<AverageReportProps> = ({
                                                                        inspectionInformation,
                                                                        jointBreakdown,
                                                                        dimensionBreakdown,
                                                                        inspectionFrequency,
                                                                        inspectionFrequencyByCrew,
                                                                        setupFrequency
                                                                    }) => {
    const { Panel } = Collapse
    const { Text } = Typography
    const {
        inspection_count,
        inspections_per_week,
        critical_joint_count,
        critical_joints_per_inspection,
        out_of_conformance_count,
        out_of_conformance_per_week,
        inspections_joint_jsi_average,
        inspections_joint_jsi_average_per_week
    } = inspectionInformation
    const {total_truss_count} = jointBreakdown
    const {roof: {total_count: totalRoofCount}} = dimensionBreakdown
    const {floor: {total_count: totalFloorCount}} = dimensionBreakdown
    const {roof: {span: {min: minRoofSpan, max: maxRoofSpan, avg: avgRoofSpan, median: medianRoofSpan}}} = dimensionBreakdown
    const {roof: {height: {min: minRoofHeight, max: maxRoofHeight, avg: avgRoofHeight, median: medianRoofHeight}}} = dimensionBreakdown
    const {floor: {span: {min: minFloorSpan, max: maxFloorSpan, avg: avgFloorSpan, median: medianFloorSpan}}} = dimensionBreakdown
    const {floor: {height: {min: minFloorHeight, max: maxFloorHeight, avg: avgFloorHeight, median: medianFloorHeight}}} = dimensionBreakdown

    const jointBreakdownPercentage = (count: number) => {
        return parseFloat((count / total_truss_count * 100).toFixed(2))
    }

    const dimensionBreakdownPercentage = (count: number) => {
        const totalCount = totalRoofCount + totalFloorCount
        if(totalCount===0) return 0
        return parseFloat((count / totalCount * 100).toFixed(2))
    }

    const {t} = useTranslation();

    return <Fragment>
        <Collapse defaultActiveKey={['inspectionInformation']}>
            {inspectionInformation && <Panel key={'inspectionInformation'} header={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION'}>{averageReports.INSPECTION_INFORMATION}</Trans>}>
                <Space align={"center"}>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TITLE'}>{INSPECTION_INFORMATION_COUNT}</Trans>} style={{width: '27vw'}}>
                        {inspection_count?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.INSPECTION_COUNT', INSPECTION_COUNT)}`
                                }
                            }}
                            meta={{
                                'Count':{
                                    alias: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.COUNT', 'Count')}`
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TOTAL_INSPECTIONS', TOTAL_INSPECTIONS)}`,
                                    ['Count']: inspection_count
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.TOTAL_CRITICAL_JOINTS', TOTAL_CRITICAL_JOINTS)}`,
                                    ['Count']: critical_joint_count
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.INSPECTION_INFORMATION_COUNT.OUT_OF_CONFORMANCE', OUT_OF_CONFORMANCE)}`,
                                    ['Count']: out_of_conformance_count
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.TITLE'}>{AVERAGE_PER_WEEK}</Trans>} style={{width: '27vw'}}>
                        {inspections_per_week?<Column
                            color={fillColor}
                            xField={'Inspection Information'}
                            yField={'Average Per Week'}
                            yAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.AVERAGE_INSPECTION', AVERAGE_INSPECTION)}`
                                }
                            }}
                            meta={{
                                'Average Per Week':{
                                    alias: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.AVERAGE_PER_WEEK', 'Average Per Week')}`
                                }
                            }}
                            data={[
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.INSPECTIONS', INSPECTIONS)}`,
                                    'Average Per Week': inspections_per_week
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.CRITICAL_JOINTS', CRITICAL_JOINTS)}`,
                                    'Average Per Week': out_of_conformance_per_week
                                },
                                {
                                    'Inspection Information': `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.AVERAGE_PER_WEEK.JOINT_STRESS_INDEX', JOINT_STRESS_INDEX)}`,
                                    'Average Per Week': inspections_joint_jsi_average_per_week
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Col>
                        <Row>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.TITLE'}>{CRITICAL_JOINTS_PER_INSPECTION}</Trans>} style={{width: '26vw'}}>
                                {critical_joints_per_inspection?<Bullet
                                    measureField={`${t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.MEASURES', 'measures')}`}
                                    rangeField={'ranges'}
                                    targetField={'target'}
                                    xField={'title'}
                                    style={{ height: '20vh'}}
                                    data={[
                                        {
                                            title: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.TITLE', CRITICAL_JOINTS_PER_INSPECTION)}`,
                                            ranges: [critical_joints_per_inspection * 1.2],
                                            [t('AVERAGE_REPORT.INSPECTION_INFORMATION.CRITICAL_JOINTS_PER_INSPECTION.MEASURES', 'measures')]: [critical_joints_per_inspection],
                                            target: critical_joints_per_inspection,
                                        }
                                    ]}
                                />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                        </Row>
                        <Row>
                            <Card title={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.TITLE'}>{JOINT_STRESS_INDEX_AVERAGE}</Trans>} style={{width: '26vw'}}>
                                {inspections_joint_jsi_average?<Bullet
                                    measureField={`${t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.MEASURES', 'measures')}`}
                                    rangeField={'ranges'}
                                    targetField={'target'}
                                    xField={'title'}
                                    style={{ height: '20vh'}}
                                    data={[
                                        {
                                            title: `${t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.TITLE', JOINT_STRESS_INDEX_AVERAGE)}`,
                                            ranges: [inspections_joint_jsi_average * 1.25],
                                            [t('AVERAGE_REPORT.INSPECTION_INFORMATION.JOINT_STRESS_INDEX_AVERAGE.MEASURES', 'measures')]: [inspections_joint_jsi_average],
                                            target: inspections_joint_jsi_average,
                                        }
                                    ]}
                                />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                            </Card>
                        </Row>
                    </Col>
                </Space>
            </Panel>}
            {jointBreakdown && <Panel key={'jointBreakdown'} header={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN'}>{averageReports.JOINT_BREAKDOWN}</Trans>}>
                <Space>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.TITLE'}>{JOINT_BREAKDOWN_COUNT}</Trans>} style={{width: '40vw'}}>
                        {total_truss_count?<Column
                            color={fillColor}
                            xField={'Joint Breakdown'}
                            yField={'Percentage'}
                            yAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.INSPECTION_COUNT', INSPECTION_COUNT)}`
                                }
                            }}
                            xAxis={{
                                title: {
                                    text: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.CRITICAL_JOINTS', CRITICAL_JOINTS)}`
                                }
                            }}
                            meta={{
                                'Percentage':{
                                    alias: `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.PERCENTAGE', 'Percentage')}`
                                }
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ZERO_CRITICAL_JOINTS', ZERO_CRITICAL_JOINTS)}`,
                                    'Percentage': jointBreakdown['0']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.ONE_CRITICAL_JOINTS', ONE_CRITICAL_JOINTS)}`,
                                    'Percentage': jointBreakdown['1']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.TWO_CRITICAL_JOINTS', TWO_CRITICAL_JOINTS)}`,
                                    'Percentage': jointBreakdown['2']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.THREE_CRITICAL_JOINTS', THREE_CRITICAL_JOINTS)}`,
                                    'Percentage': jointBreakdown['3']
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_COUNT.FOUR_OR_MORE_CRITICAL_JOINTS', FOUR_OR_MORE_CRITICAL_JOINTS)}`,
                                    'Percentage': jointBreakdown['>=4']
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.TITLE'}>{JOINT_BREAKDOWN_AS_PERCENTAGE}</Trans>} style={{width: '40vw'}}>
                        {total_truss_count?<Pie
                            angleField={`${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')}`}
                            colorField={'Joint Breakdown'}
                            radius={0.8}
                            label={{
                                type: 'inner',
                                offset: '-30%',
                                content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                                style: {
                                    fontSize: 14,
                                    textAlign: 'center',
                                },
                            }}
                            data={[
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.ZERO_CRITICAL_JOINTS', `${ZERO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(jointBreakdown['0'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.ONE_CRITICAL_JOINTS', `${ONE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(jointBreakdown['1'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.TWO_CRITICAL_JOINTS', `${TWO_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(jointBreakdown['2'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.THREE_CRITICAL_JOINTS', `${THREE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(jointBreakdown['3'])
                                },
                                {
                                    'Joint Breakdown': `${t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.FOUR_OR_MORE_CRITICAL_JOINTS', `${FOUR_OR_MORE_CRITICAL_JOINTS} ${CRITICAL_JOINTS}`)}`,
                                    [t('AVERAGE_REPORT.JOINT_BREAKDOWN.JOINT_BREAKDOWN_AS_PERCENTAGE.PERCENTAGE', 'Percentage')]: jointBreakdownPercentage(jointBreakdown['>=4'])
                                }
                            ]}
                        />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
            </Panel>}
            {dimensionBreakdown && <Panel key={'dimensionBreakdown'} header={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.DIMENSION_BREAKDOWN'}>{averageReports.DIMENSION_BREAKDOWN}</Trans>}>
                <Space align="center">
                    <Card
                        title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.TITLE'}>{ROOF_TRUSSES}</Trans>}
                        style={{maxWidth: '70vw', minHeight: '67vh', alignItems: 'center', width: '60vw', marginLeft: '20%', alignContent: 'center'}}
                        extra={<Text type={"secondary"}><Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.EXTRA'} values={{totalRoofCount, dimensionBreakdownPercentage:dimensionBreakdownPercentage(totalRoofCount)}} default={`count: ${totalRoofCount}  (${dimensionBreakdownPercentage(totalRoofCount)}%)`}/></Text>}>
                        {totalRoofCount?<Space>
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Roof Span'}
                                yField={INCHES}
                                yAxis={{
                                    title: {
                                        text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                meta={{
                                    [INCHES]:{
                                        alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX_SPAN', MAX_SPAN)}`,
                                        [INCHES]: maxRoofSpan
                                    },
                                    {
                                        'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.AVG_SPAN', AVG_SPAN)}`,
                                        [INCHES]: avgRoofSpan
                                    },
                                    {
                                        'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MEDIAN_SPAN', MEDIAN_SPAN)}`,
                                        [INCHES]: medianRoofSpan
                                    },
                                    {
                                        'Roof Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MIN_SPAN', MIN_SPAN)}`,
                                        [INCHES]: minRoofSpan
                                    }
                                ]}
                            />
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Roof Height'}
                                yField={INCHES}
                                yAxis={{
                                    title: {
                                        text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                meta={{
                                    [INCHES]:{
                                        alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MAX_HEIGHT', MAX_HEIGHT)}`,
                                        [INCHES]: maxRoofHeight
                                    },
                                    {
                                        'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.AVG_HEIGHT', AVG_HEIGHT)}`,
                                        [INCHES]: avgRoofHeight
                                    },
                                    {    'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MEDIAN_HEIGHT', MEDIAN_HEIGHT)}`,
                                        [INCHES]: medianRoofHeight
                                    },
                                    {
                                        'Roof Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.ROOF_TRUSSES.MIN_HEIGHT', MIN_HEIGHT)}`,
                                        [INCHES]: minRoofHeight
                                    }
                                ]}
                            />
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
                <Space align="center">
                    <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.TITLE'}>{FLOOR_TRUSSES}</Trans>} style={{maxWidth: '70vw', minHeight: '67vh', alignItems: 'center', width: '60vw', marginLeft: '20%', alignContent: 'center'}}
                          extra={<Text type={"secondary"}><Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.EXTRA'} values={{totalFloorCount, dimensionBreakdownPercentage:dimensionBreakdownPercentage(totalFloorCount)}} default={`count: ${totalFloorCount}  (${dimensionBreakdownPercentage(totalFloorCount)}%)`}/></Text>}>
                        {totalFloorCount?<Space>
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Floor Span'}
                                yField={INCHES}
                                yAxis={{
                                    title: {
                                        text: `${[t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)]}`
                                    }
                                }}
                                meta={{
                                    [INCHES]:{
                                        alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MAX_SPAN', MAX_SPAN)}`,
                                        [INCHES]: maxFloorSpan
                                    },
                                    {
                                        'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.AVG_SPAN', AVG_SPAN)}`,
                                        [INCHES]: avgFloorSpan
                                    },
                                    {
                                        'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MEDIAN_SPAN', MEDIAN_SPAN)}`,
                                        [INCHES]: medianFloorSpan
                                    },
                                    {
                                        'Floor Span': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MIN_SPAN', MIN_SPAN)}`,
                                        [INCHES]: minFloorSpan
                                    }
                                ]}
                            />
                            <Column
                                color={fillColor}
                                style={{maxWidth: '30vw'}}
                                xField={'Floor Height'}
                                yField={INCHES}
                                yAxis={{
                                    title: {
                                        text: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                meta={{
                                    [INCHES]:{
                                        alias: `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.INCHES', INCHES)}`
                                    }
                                }}
                                label={{
                                    content: ({Inches}) => {
                                        return inchToFeet(Inches)
                                    },
                                    position: POSITION_OF_GRAPH_VALUES,
                                    style: {
                                        fill: graphValues.COLOR,
                                        OPACITY: graphValues.OPACITY
                                    },
                                }}
                                data={[
                                    {
                                        'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MAX_HEIGHT', MAX_HEIGHT)}`,
                                        [INCHES]: maxFloorHeight
                                    },
                                    {
                                        'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.AVG_HEIGHT', AVG_HEIGHT)}`,
                                        [INCHES]: avgFloorHeight
                                    },
                                    {
                                        'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MEDIAN_HEIGHT', MEDIAN_HEIGHT)}`,
                                        [INCHES]: medianFloorHeight
                                    },
                                    {
                                        'Floor Height': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.FLOOR_TRUSSES.MIN_HEIGHT', MIN_HEIGHT)}`,
                                        [INCHES]: minFloorHeight
                                    }
                                ]}
                            />
                        </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                    </Card>
                </Space>
                <Card title={<Trans i18nKey={'AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.TITLE'}>{TRUSS_OVERVIEW}</Trans>} style={{width: '60vw', margin: 10, marginLeft: '14%'}}>
                    {(totalRoofCount || totalFloorCount)?<Pie
                        angleField={'Percentage'}
                        colorField={'Dimension Breakdown'}
                        label={{
                            type: 'inner',
                            offset: '-30%',
                            content: ({ Percentage }) => `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.LABEL_CONTENT', `${Percentage} Trusses`, {Percentage})}`,
                            style: {
                                fontSize: 14,
                                textAlign: 'center',
                            },
                        }}
                        radius={0.8}
                        data={[
                            {
                                'Dimension Breakdown': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.ROOF_TRUSSES', ROOF_TRUSSES)}`,
                                'Percentage': totalRoofCount
                            },
                            {
                                'Dimension Breakdown': `${t('AVERAGE_REPORT.DIMENSION_BREAKDOWN.TRUSS_OVERVIEW.FLOOR_TRUSSES', FLOOR_TRUSSES)}`,
                                'Percentage': totalFloorCount
                            }
                        ]}
                    />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
                </Card>
            </Panel>}
            {inspectionFrequency && <Panel key={'inspectionFrequency'} header={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_FREQUENCY.TITLE'}>{averageReports.INSPECTION_FREQUENCY}</Trans>}>
                {inspectionFrequency.length>0?<Space style={{maxWidth: '100%', overflowX: 'auto'}}>
                    {inspectionFrequency?.map(({shift_name, lines}) =>
                        <Card title={shift_name} style={{width: '25vw'}}>
                            <Column
                                color={fillColor}
                                xField={'Inspections'}
                                yField={'No of Inspections'}
                                meta={{
                                    'No of Inspections':{
                                        alias: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY.NO_OF_INSPECTIONS', 'No of Inspection')}`
                                    }
                                }}
                                yAxis={{
                                    title: {
                                        text: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY.INSPECTION_COUNT', INSPECTION_COUNT)}`
                                    }
                                }}
                                data={lines?.map(({line_name, inspections}) => ({
                                    'Inspections': line_name,
                                    'No of Inspections': inspections
                                }))}
                            />
                        </Card>
                    )}
                </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {inspectionFrequencyByCrew && <Panel key={'inspectionFrequencyByCrew'} header={<Trans i18nKey={'AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.TITLE'}>{averageReports.INSPECTION_FREQUENCY_BY_CREW}</Trans>}>
                {inspectionFrequencyByCrew.length?<Space>
                    <Column
                        color={fillColor}
                        xField={'Inspections'}
                        yField={'No of Inspections'}
                        meta={{
                            'No of Inspections':{
                                alias: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.NO_OF_INSPECTIONS', 'No of Inspection')}`
                            }
                        }}
                        yAxis={{
                            title: {
                                text: `${t('AVERAGE_REPORT.INSPECTION_FREQUENCY_BY_CREW.INSPECTION_COUNT', INSPECTION_COUNT)}`
                            }
                        }}
                        data={inspectionFrequencyByCrew?.map(({crew_name, inspections, active_crew}) => ({
                            'Inspections': crew_name,
                            'No of Inspections': inspections
                        }))}
                    />
                </Space>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
            </Panel>}
            {setupFrequency&&<Panel key={'setupFrequency'} header={<Trans i18nKey={'AVERAGE_REPORT.SETUP_FREQUENCY.TITLE'}>{averageReports.SETUP_FREQUENCY}</Trans>}>
                <SetupFrequency setupFrequency={setupFrequency}/>
            </Panel>}
        </Collapse>
    </Fragment>
}

export default AverageReport;
