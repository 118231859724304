import React, {useState} from "react";
import {
    Button,
    Col,
    DatePicker,
    Form as AntdForm,
    notification,
    PageHeader,
    Row,
    Space, Tag,
    Typography
} from "antd";
import {Trans, useTranslation} from "react-i18next";
import {LocationsFilter, LocationsFilterBaseProps} from "../filters/LocationsFilter";
import dayjs from 'dayjs';
import {configs, fetch_locations, fetch_review_request, request_review} from "../../api";
import {ITableContext, Page, Response, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {handleScroll} from "../../utils/util";
import {ConfigsContext} from "../configs/ConfigsProvider";
import {MapToFilter} from "../filters/mapping";
import {getOrdering} from "../filters/ordering";
import {TableFilter} from "../filters/TableFilter";
import CustomDropDown from "../lib/CustomDropDown";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import {unstable_batchedUpdates} from "react-dom";
import {Switch} from "react-router-dom";

dayjs.extend(quarterOfYear);

const {Text} = Typography
export interface ReviewRequestProps extends LocationsFilterBaseProps{

}
export interface ReviewRequestPayload {
    location_id: number | undefined;
    company_id: number | undefined;
    quarter: string;
    start_date: string;
    end_date: string;
}

export interface AddRequestProps {
    companyID: number | undefined;
    locationID: number | undefined;
    companies:any[]
    locations:any[]
    setCompanyID: (id?:number)=>void
    setLocationID: (id?:number)=>void
}

const AddRequest: React.FunctionComponent<AddRequestProps> = (props) => {
    const {t} = useTranslation();
    const [form] = AntdForm.useForm()
    const [quarterDate, setQuarterDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const isFormValid = () => {
        return quarterDate && props.locationID && props.companyID;
    };

    const handleFinish = async (table: ITableContext | undefined) => {
        const payload: ReviewRequestPayload = {
            quarter: quarterDate,
            start_date: startDate,
            end_date: endDate,
            location_id: props.locationID,
            company_id: props.companyID,
        };

        try {
            table?.setLoading(true)
            const res = await request_review(payload);
            if(res.data?.statusCode == 404) {
                let message = res.data?.message
                switch (message) {
                    case 'The location has no any QC data for the selected quarter':
                        message = t('review_requests_page.no_qc_data');
                        break;
                    case 'You have already reviewed this location':
                        message = t('review_requests_page.already_reviewed');
                        break;
                    case 'You have pending request for this location':
                        message = t('review_requests_page.pending_review');
                        break;
                    default:
                        message = t('review_requests_page.error');
                        break;
                }
                notification.error({message:message})
            } else {
                const payload = {
                    tableName: 'review_request_multi_location',
                    orderBy: ['id desc'],
                    filter:[],
                    page: {size: 100, from: 0}
                }
                const response = await fetch_review_request(payload) || {}
                table?.setData(response.data?.rows)
                notification.success({message:t('review_requests_page.request_success')})
                table?.setLoading(false)
            }
            table?.setLoading(false)
        } catch (error:any) {
            console.error('Error requesting review:', error.message);
            table?.setLoading(false)
        }
    };

    return (
        <TableContext.Consumer>
            {table => <>
                <AntdForm form={form}>
                    <LocationsFilter
                        locationID={props?.locationID}
                        companyID={props?.companyID}
                        setLocationID={props?.setLocationID}
                        setCompanyID={props?.setCompanyID}
                        companies={props?.companies}
                        locations={props?.locations}
                        disabledValidatorCompany={(user) => user.user_role !== 'administrator'}
                        disabledValidatorLocation={(user) => !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')}
                    />
                    <Row>
                        <Col span={20}>
                            <Space style={{width: '25vw', margin: 10, marginLeft: '25vw', marginTop: 5}}>
                                <Row>
                                    <Col span={7}>
                                        <Text strong><Trans i18nKey={`quarter`} defaults={`Quarter`}/></Text>
                                    </Col>
                                    <Col span={7}>
                                        <DatePicker
                                            style={{width: 400}}
                                            picker="quarter"
                                            defaultValue={undefined}
                                            onChange={(date, dateString) => {
                                                unstable_batchedUpdates(()=>{
                                                    // @ts-ignore
                                                    setStartDate(date?.clone().startOf('quarter').format('YYYY-MM-DD'))
                                                    // @ts-ignore
                                                    setEndDate(date?.clone().endOf('quarter').format('YYYY-MM-DD'))
                                                    setQuarterDate(dateString);
                                                })
                                            }}
                                            placeholder={`${t('select_quarter')}`}
                                            disabledDate={(current) => current && current >= dayjs().startOf('quarter')}
                                        />
                                    </Col>
                                </Row>
                            </Space>
                        </Col>
                        <Col span={4}>
                            <Button type="primary"
                                    disabled={!isFormValid()} onClick={() => handleFinish(table)}>
                                <Trans i18nKey={'review_requests_page.request_review'} default={'Request Review'} />
                            </Button>
                        </Col>
                    </Row>
                </AntdForm>
            </>}
        </TableContext.Consumer>

    )
}

export const ReviewRequests:React.FunctionComponent<ReviewRequestProps> = (props) => {
    const {t} = useTranslation();

    const fetchReviewRequests = async (values: {[key:string]: any}, page?: Page) => {
        const filters = MapToFilter(values)
        const payload = {
            tableName: 'review_request_multi_location',
            orderBy: ['id desc'],
            filter:[...filters],
            page: {size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
        }
        return await fetch_review_request(payload) || {}
    }

    return (
            <>
                <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                    <PageHeader title={<Trans i18nKey={`review_requests_page.review_request_title`}
                                              defaults={`Review Requests`}/>}/>
                </div>
                <TableV2
                    // key={locationID}
                    schema={'review_requests'}
                    header={
                        <>
                            <AddRequest
                                companyID={props?.companyID}
                                locationID={props?.locationID}
                                setLocationID={props?.setLocationID}
                                setCompanyID={props?.setCompanyID}
                                companies={props?.companies}
                                locations={props?.locations}
                            />
                            <div style={{ paddingRight: 7, width: '100%'}}>
                                <TableFilter
                                    schema={'review_requests_filter'}
                                    fetch={fetchReviewRequests}
                                    overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                        if(element.name === 'location_id') {
                                            return <CustomDropDown
                                                key={element.name}
                                                schema={schema}
                                                name={element.name}
                                                filterValue={JSON.stringify(props.companyID)}
                                                fetch={(request: { tableName: string, filter: { [key: string]: any } }) => {
                                                    return fetch_locations(props.companyID)
                                                }}
                                                enum_map={['id', 'name']}
                                            />
                                        }
                                        return undefined
                                    }}
                                />
                            </div>
                        </>
                    }
                    maxColumnWidth={400}
                    fetchConfig={async (schema) => {
                        const res = await configs(schema);
                        res.data.rows = res.data.rows.map((row: any) => {
                            if (row.name) {
                                row.title = <Trans i18nKey={`review_requests.${row.name}`} defaults={row.title}/>;
                            }
                            return row;
                        });
                        return res
                    }}
                    fetchData={async (request) => {
                        handleScroll()
                        const filters = MapToFilter(request.options?.values || {})
                        const page = {
                            size: request?.page?.size || 100,
                            current: request?.page?.current || 1,
                            total: request?.page?.total || 0
                        };
                        const response = await fetchReviewRequests(filters, page)
                        return response as Response
                    }}
                    customRenderer={{
                        status: (value, row, index, column) => {
                            const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}>{name}</Tag>
                            return <Space>
                                {(value == 'PENDING') ? nameCard(t('review_requests_page.pending'), 'blue') : nameCard(t('review_requests_page.ready'), 'green')}
                            </Space>
                        },
                        created_at: (value, row, index, column) => {
                            return <ConfigsContext.Consumer>{configs =>
                                <Typography.Text>{dayjs(value).format(configs.dateTimeFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                        },
                        reviewed_at: (value, row, index, column) => {
                            return (
                                <ConfigsContext.Consumer>
                                    {configs => (
                                        value ? (
                                            <Typography.Text>{dayjs(value).format(configs.dateTimeFormat)}</Typography.Text>
                                        ) : (
                                            <Typography.Text><Trans i18nKey={`review_requests_page.pending_review_status`}
                                                                    defaults={`Pending Review`}/></Typography.Text>
                                        )
                                    )}
                                </ConfigsContext.Consumer>
                            );
                        }
                    }}
                    scroll={{y: 'calc(100vh - 513px)', x: 'max-content'}}
                />
            </>
    );
}