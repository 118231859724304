import React, {Fragment, FunctionComponent, useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Collapse,
    DatePicker,
    Descriptions,
    Divider,
    Form,
    InputNumber,
    notification,
    PageHeader,
    Row,
    Space,
    Spin,
    Statistic,
    Table,
    Typography
} from "antd";
import {LocationsFilter, LocationsFilterBaseProps} from "../filters/LocationsFilter";
import {
    Comment,
    createDashboardReviewRecord,
    fetchReviewDashboardData,
    Flag,
    updateDashboardReviewRecord
} from "../../api";
import moment from "moment";
import {EditOutlined, FormOutlined, MailOutlined, SaveOutlined} from '@ant-design/icons';
import {unstable_batchedUpdates} from "react-dom";
import {Link, useHistory, useLocation} from "react-router-dom";
import {Column, Line} from "@ant-design/plots";
import Section from "./Section";
import {CustomInputNumber, CustomTextArea} from "./CustomComponents";
import Extra from "./Extra";
import {
    addTextButtonStyle,
    AVERAGE,
    CERTIFIED_AVERAGE,
    DASHBOARDSTATUS,
    END_DATE,
    INSPECTION_PER_SHIFT_PER_WEEK,
    IS_OOC,
    ISEDIT,
    LOCATION_AVERAGE,
    LOCATION_ID,
    MISPLACEMENT,
    QUARTER,
    ROTATION,
    SCHEMA,
    START_DATE, TITLE
} from "../../utils/constants";
import {processName} from "../../utils/util";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface DashboardProps extends LocationsFilterBaseProps{}

interface MetaProps {
    id?: number
    reviewQuarter?: moment.Moment | null
    reviewDate?: string
}

const Dashboard: FunctionComponent<DashboardProps> = (props) => {
    const location: {pathname: string, state: {[key: string]: string}} = useLocation()
    const startDate = location?.state?.start_date
    const endDate = location?.state?.end_date
    const quarterDate = location?.state?.quarter_date
    const quarterName = location?.state?.quarter_name
    const locationStateWeeks = location?.state?.weeks

    const [form] = Form.useForm()
    const history = useHistory()

    const [quarter, setQuarter] = useState<string>(quarterName)
    const [meta, setMeta] = useState<MetaProps>()
    const [isNew, setIsNew] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [comment, setComment] = useState<Comment>()
    const [flag, setFlag] = useState<Flag>()
    const [point, setPoint] = useState<{[key: string]: number | undefined}>()
    const [loading, setLoading] = useState<boolean>(false)
    const [allocatedPoints, setAllocatedPoints] = useState<{[key: string]: number | undefined}>()
    const {t} = useTranslation();

    const weeks = locationStateWeeks || moment.duration(moment(meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD")).diff(moment(meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD")))).asWeeks().toFixed(2)

    const valueWithDecimalPlaces = (number: number, decimalPlaces = 2) => parseFloat(number.toFixed(decimalPlaces)) || 0

    const countPerWeek = (value: number) => valueWithDecimalPlaces(value/parseFloat(weeks))

    const calculateInspectionExpectedValues = () => {
        const inspectionExpected = valueWithDecimalPlaces(form.getFieldValue('avg_setup_reported') * INSPECTION_PER_SHIFT_PER_WEEK * parseFloat(weeks))
        const floorInspectionExpected = valueWithDecimalPlaces(form.getFieldValue('avg_setup_reported') * INSPECTION_PER_SHIFT_PER_WEEK * parseFloat(weeks) * form.getFieldValue('percent_floor_reported') / 100)
        const roofInspectionExpected = valueWithDecimalPlaces(inspectionExpected - floorInspectionExpected)
        unstable_batchedUpdates(() => {
            form.setFieldsValue({
                inspection_expected: inspectionExpected,
                floor_inspection_expected: floorInspectionExpected,
                roof_inspection_expected: roofInspectionExpected,
                inspection_expected_per_week: countPerWeek(inspectionExpected),
                floor_inspection_expected_per_week: countPerWeek(floorInspectionExpected),
                roof_inspection_expected_per_week: countPerWeek(roofInspectionExpected),
            })
        })
    }

    const initialFieldValues = {
        avg_setup_reported: undefined,
        inspection_expected: undefined,
        inspection_expected_per_week: undefined,
        floor_inspection_expected: undefined,
        floor_inspection_expected_per_week: undefined,
        roof_inspection_expected: undefined,
        roof_inspection_expected_per_week: undefined,
        percent_floor_reported: undefined,
        areas_for_improvement: undefined,
        other_comments: undefined
    }

    let dashboard_status = DASHBOARDSTATUS.NotCompleted;
    const handleStartReview = async () => {
        const {data: {rows: [row] =[]} ={}, status} = await createDashboardReviewRecord({
            dashboard_status,
            quarter,
            company_id: props.companyID,
            location_id: props.locationID
        })
        if(status===200) {
            unstable_batchedUpdates(() => {
                setMeta({...meta, id: row?.id})
                setIsEdit(true)
            })
        }
    }

    const updateReview = async () => {
        setIsEdit(true)
    }

    const handleSave = async () => {
        form.submit()
        const data = form.getFieldsValue([
            'avg_setup_reported',
            'inspection_expected',
            'floor_inspection_expected',
            'roof_inspection_expected',
            'percent_floor_reported',
            'areas_for_improvement',
            'areas_for_improvement',
            'other_comments'
        ])
        let status;
        dashboard_status = DASHBOARDSTATUS.Completed;
        if(flag?.documentation_status && flag?.frequency_status && flag?.inspection_details_status && flag?.overall_status && flag?.selection_status) {
            const response = await updateDashboardReviewRecord({
                review_id: meta?.id,
                dashboard_status,
                comment,
                flag,
                data,
                point
            })
            status = response?.status
        } else {
            notification.error({message: <Trans i18nKey={`should_select_message`} defaults={`Should select the status for required sections ( Frequency, Selection, Inspection Details, Documentation and Overall )`}/>})
        }
        if(status===200) {
            notification.success({message: isNew?<Trans i18nKey={`record_created`} defaults={`Record Created`}/>:<Trans i18nKey={`changes_saved`} defaults={`Changes are Saved`}/>})
            unstable_batchedUpdates(() => {
                setIsEdit(false)
                setIsNew(false)
            })
        }
    }

    const load = async () => {
        if(props.locationID && quarter && weeks) {
            localStorage.setItem(LOCATION_ID, props.locationID.toString())
            localStorage.setItem(QUARTER, quarter)
            setLoading(true)
            const {data: {data, isNew: isNewReview, allocatedPoints: allocatedPointList} ={}, status} = await fetchReviewDashboardData({
                location_id: props.locationID,
                quarter,
                start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                weeks: parseFloat(weeks)
            })
            if(status!==200) return
            if(isNewReview) {
                const {fieldValues,
                    point: {...pointData}} = data
                unstable_batchedUpdates(() => {
                    setIsNew(true)
                    setIsEdit(false)
                    setMeta({...meta, id: undefined, reviewDate: moment().format("YYYY-MM-DD")})
                    setComment({})
                    setFlag({})
                    setPoint(pointData)
                    setLoading(false)
                    form.setFieldsValue({...fieldValues, ...initialFieldValues})
                    setAllocatedPoints(allocatedPointList)
                })
            } else {
                const {
                    dashboard,
                    comment: {id: commentId, review_id: commentReviewId, ...commentData},
                    flag: {id: flagId, review_id: flagReviewId, ...flagData},
                    point: {id: pointId, review_id: pointReviewId, ...pointData},
                    data: {id: dataId, review_id: dataReviewId, ...fieldData},
                    fieldValues,
                } = data
                unstable_batchedUpdates(() => {
                    setIsNew(false)
                    setIsEdit(false)
                    setMeta({...meta, id: dashboard.id, reviewDate: moment(dashboard.created_at).format("YYYY-MM-DD")})
                    setComment(commentData)
                    setFlag(flagData)
                    setPoint(pointData)
                    setLoading(false)
                    form.setFieldsValue({
                        ...fieldValues,
                        ...fieldData,
                        inspection_expected_per_week: countPerWeek(fieldData?.inspection_expected),
                        floor_inspection_expected_per_week: countPerWeek(fieldData?.floor_inspection_expected),
                        roof_inspection_expected_per_week: countPerWeek(fieldData?.roof_inspection_expected),
                    })
                    setAllocatedPoints(allocatedPointList)
                })
            }
        }
    }

    useEffect(() => {
        load()
    }, [props.locationID, quarter])

    const oocGraphData = () => {
        const processedOOCHistory: { Quarter: any; Percentage: any; category: string; }[] = []
        const sortedOOCHistory = []
        const oocHistory = form.getFieldValue('out_of_conformance_percent_history') || []
        if (oocHistory.length !== 4) return []
        for (let i = 3; i >= 0; i--) {
            sortedOOCHistory.push(oocHistory[i])
        }
        sortedOOCHistory?.forEach(({quarter_name, out_of_conformance_percent, out_of_conformance_cert_percent}: {[key: string]: any}) => {
            processedOOCHistory.push({
                Quarter: quarter_name,
                Percentage: out_of_conformance_percent,
                category: t('plant')
            })
            processedOOCHistory.push({
                Quarter: quarter_name,
                Percentage: out_of_conformance_cert_percent,
                category: t('certified')
            })
        })
        return processedOOCHistory
    }

    const calculateTotalPoints = (pointObj: {[key: string]: number | undefined} | undefined) => Object.values(pointObj || {})
        ?.map(value => parseInt((value || 0).toString()))
        ?.filter(value => value!==undefined)
        ?.reduce((acc, cur) => (acc || 0) + (cur || 0))

    const plateGraph = (title: string | React.ReactNode, data: any) => <Card title={title}>
        <Column
            isGroup={true}
            style={{width: '25vw'}}
            seriesField={'name'}
            xField={'Offset'}
            yField={'Average'}
            yAxis={{
                title: {
                    text: `${t('average')}`
                }
            }}
            data={data}
        />
    </Card>

    const reportButtonStyle = {
        color: '#A569BD ',
        borderColor: '#A569BD '
    }

    const isLargeScreen = window.innerWidth>1900

    const {Text} = Typography
    const FREQUENCY_LABEL_WIDTH = 250
    const SELECTION_LABEL_WIDTH = 275
    const INSPECTION_DETAIL_LABEL_WIDTH = 330
    const OOC_LABEL_WIDTH = 200
    const PLATE_BOXES_LABEL_WIDTH = 290
    const OVERALL_LABEL_WIDTH = 150
    const PLATE_BOXES_OUTER_WIDTH = isLargeScreen?'32.6vw':'33.3vw'
    const labelText = (text: React.ReactNode, width: number) => <Text style={{width, textAlign: "left"}}>{text}</Text>

    const {Item} = Form
    const { Panel } = Collapse

    return <Fragment>
        <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
            <Row>
                <Col span={12}>
                    <PageHeader title={<Trans i18nKey={`review_dashboard`} defaults={`Review Dashboard`}/>} />
                </Col>
                <Col span={12}>
                    {props.locationID && quarter && isNew && (isEdit?<Button
                        type={"primary"}
                        style={{margin: 10}}
                        icon={<SaveOutlined />}
                        onClick={handleSave}
                    ><Trans i18nKey={`save_button`} defaults={`Save`}/></Button>:<Button
                        shape={"round"}
                        style={{backgroundColor: meta?.id?undefined:"#2ECC71", color: meta?.id?undefined:"white", margin: 10}}
                        icon={<FormOutlined/>}
                        onClick={handleStartReview}
                        disabled={meta?.id!==undefined}
                    ><Trans i18nKey={`start_review`} defaults={`Start Review`}/></Button>)}
                    {!isNew && meta?.id && (isEdit?<Button
                        type={"primary"}
                        style={{margin: 10}}
                        icon={<SaveOutlined />}
                        onClick={handleSave}
                    ><Trans i18nKey={`save_button`} defaults={`Save`}/></Button>:<Button
                        shape={"round"}
                        style={{margin: 10}}
                        icon={<EditOutlined />}
                        onClick={updateReview}
                    ><Trans i18nKey={`update`} defaults={`Update`}/></Button>)}
                    {props.locationID && meta?.id && <Button
                        icon={<MailOutlined />}
                        onClick={() => history.push({
                            pathname: '/view/send-mail',
                            state: {
                                start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                                end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                                quarter_date: meta?.reviewQuarter?moment(meta?.reviewQuarter).format('YYYY-MM-DD'):quarterDate,
                                quarter,
                                weeks
                            }
                        })}
                        disabled={isEdit}
                    ><Trans i18nKey={`send_mail`} defaults={`Send Mail`}/></Button>}
                </Col>
            </Row>
        </div>
        <LocationsFilter
            locationID={props.locationID}
            companyID={props.companyID}
            setLocationID={props.setLocationID}
            setCompanyID={props.setCompanyID}
            companies={props.companies}
            locations={props.locations}
            disabledValidatorCompany={(user)=>{
                return user.user_role !== 'administrator'
            }}
            disabledValidatorLocation={(user)=>{
                return !(user.user_role === 'administrator' || user.user_role === 'district_manager' || user.user_role === 'regional_manager')
            }}
        />
        <div>
            <Space style={{width: '25vw', margin: 10, marginLeft:'25vw', marginTop: 5}}>
                <Row>
                    <Col span={7}>
                        <Text strong><Trans i18nKey={`quarter`} defaults={`Quarter`}/></Text>
                    </Col>
                    <Col span={7}>
                        <DatePicker
                            style={{width: 400}}
                            picker="quarter"
                            defaultValue={quarterDate?moment(quarterDate):undefined}
                            onChange={(date, dateString) => {
                                localStorage.setItem(START_DATE, date?.startOf("quarter").format("YYYY-MM-DD") || '')
                                localStorage.setItem(END_DATE, date?.endOf("quarter").format("YYYY-MM-DD") || '')
                                unstable_batchedUpdates(() => {
                                    setQuarter(dateString)
                                    setMeta({reviewQuarter: date})
                                    setIsNew(false)
                                })
                            }}
                            placeholder={`${t('select_quarter')}`}
                            disabledDate={current => current >= moment().startOf("quarter")}
                        />
                    </Col>
                </Row>
            </Space>
        </div>
        <div style={{
            width: isLargeScreen?'60vw':'70vw',
            marginLeft: isLargeScreen?'15%':'10%'
        }}>
            {meta?.id && <Space style={{margin: 10, backgroundColor: '#f5f5f5'}}>
                <Card style={{margin: '10px 1px 10px 10px'}}><Statistic title={<Trans i18nKey={`review_id`} defaults={`Review ID`}/>}
                     value={meta.id}/></Card>
                {(meta?.reviewQuarter || startDate) && <Card style={{margin: '10px 1px 10px 1px'}}><Statistic
                    title={<Trans i18nKey={`inspection_start_date`} defaults={`Inspection Start Date`}/>} value={meta.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate}/></Card>}
                {(meta?.reviewQuarter || endDate) && <Card style={{margin: '10px 1px 10px 1px'}}><Statistic
                    title={<Trans i18nKey={`inspection_end_date`} defaults={`Inspection End Date`}/>} value={meta.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate}/></Card>}
                {meta?.reviewDate && <Card style={{margin: '10px 1px 10px 1px'}}><Statistic
                    title={<Trans i18nKey={`review_date`} defaults={`Review Date`}/>} value={meta.reviewDate}/></Card>}
                {(locationStateWeeks || meta?.reviewQuarter) && <Card style={{margin: (point && Object.keys(point).length!==0)?'10px 1px 10px 1px':'10px 10px 10px 1px'}}>
                    <Statistic title={<Trans i18nKey={`weeks_in_range`} defaults={`Weeks in Range`}/>} value={locationStateWeeks || weeks}/></Card>}
                {point && Object.keys(point).length!==0 && <Card style={{margin: '10px 10px 10px 1px'}}><Statistic
                    title={<Trans i18nKey={`total_points`} defaults={`Total Points`}/>} value={calculateTotalPoints(point)}/></Card>}
            </Space>}
            {isEdit?<Link to={'/view/review-comments'} target={"_blank"} rel={"noopener noreferrer"}>
                    <Button style={{...reportButtonStyle, margin: 10}} disabled={!props.locationID}><Trans i18nKey={`view_previous_comments`} defaults={`View Previous Comments`}/></Button>
                </Link>:
                <Button
                    style={{...reportButtonStyle, margin: 10}}
                    onClick={() => {
                        history.push({
                            pathname: '/view/review-comments',
                            state: {
                                start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                                end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                                quarter_date: meta?.reviewQuarter?moment(meta?.reviewQuarter).format('YYYY-MM-DD'):quarterDate,
                                quarter_name: quarter,
                                weeks: weeks.toString()
                            }
                        })
                    }}
                    disabled={!props.locationID}
                ><Trans i18nKey={`view_previous_comments`} defaults={`View Previous Comments`}/></Button>}
            {point && Object.keys(point).length!==0 && <Spin spinning={loading}>
                <Collapse style={{width: '50vw', margin: 10}}>
                    <Panel key={'score'} header={<Trans i18nKey={`certified_scoring`} defaults={`Certification Scoring`}/>}>
                        <Descriptions bordered column={1}>
                            {Object.entries(allocatedPoints || {})?.map(([key, value]: [string, any]) =>
                                <Descriptions.Item label={<Trans i18nKey={key} defaults={processName(key)}/>}>{point[key]} <Trans i18nKey={`of`} defaults={`of`}/> {value}</Descriptions.Item>)}
                            <Descriptions.Item label={<Trans i18nKey={`total`} defaults={`Total`}/>}>{calculateTotalPoints(point) || 0} <Trans i18nKey={`of`} defaults={`of`}/> {calculateTotalPoints(allocatedPoints)}</Descriptions.Item>
                            <Descriptions.Item label={<Trans i18nKey={`certified_location_median`} defaults={`Certified Locations Median`}/>}>{form.getFieldValue('certified_total_median_point') || 0} <Trans i18nKey={`of`} defaults={`of`}/> {calculateTotalPoints(allocatedPoints)}</Descriptions.Item>
                        </Descriptions>
                    </Panel>
                </Collapse>
            </Spin>}
            <Spin tip="Loading data..." spinning={loading}>
                <Form
                    form={form}
                >
                    <Section
                        title={<Trans i18nKey={`frequency`} defaults={`Frequency`}/>}
                        isEdit={isEdit}
                        isCheck={flag?.frequency_flag}
                        onCheck={value => setFlag({...flag, frequency_flag: value})}
                        statusValue={flag?.frequency_status}
                        onSelect={value => setFlag({...flag, frequency_status: value})}
                        value={comment?.frequency_comment}
                        points={allocatedPoints?.frequency_point}
                        onChange={value => setComment({...comment, frequency_comment: value})}
                        pointValue={point?.frequency_point}
                        onPointChange={value => setPoint({...point, frequency_point: value?value:undefined})}
                    >
                        <Item
                            label={labelText(<Trans i18nKey={`avg_setup_reported`} defaults={`Avg setup reported`}/>, FREQUENCY_LABEL_WIDTH)}
                            name="avg_setup_reported"
                        >
                            <CustomInputNumber
                                value={form.getFieldValue('avg_setup_reported')}
                                isEdit={isEdit}
                                onChange={() => calculateInspectionExpectedValues()}
                            />
                        </Item>
                        <Item
                            label={labelText(<Trans i18nKey={`avg_setup_calculated`} defaults={`Avg setup calculated`}/>, FREQUENCY_LABEL_WIDTH)}
                            name="avg_setup_calculated"
                            extra={`${t('reported_should_be')} <= ${t('calculated')}`}
                        >
                            <CustomInputNumber value={form.getFieldValue('avg_setup_calculated')}/>
                        </Item>
                        <Text style={{marginLeft: 10}} type={"secondary"}>
                            <Trans i18nKey={`based_on_text`} defaults={`Based on Crews/Setup Locations and % Floors Reported:`}/>
                        </Text>
                        <Card>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginBottom: 10}}>
                                <Col span={8}>
                                    <Fragment/>
                                </Col>
                                <Col span={4}>
                                    <Text strong><Trans i18nKey={`Expected`} defaults={`Expected`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Text strong><Trans i18nKey={`Actual`} defaults={`Actual`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Text strong><Trans i18nKey={`Expected_per_Week`} defaults={`Expected per Week`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Text strong><Trans i18nKey={`Actual_per_Week`} defaults={`Actual per Week`}/></Text>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={8}>
                                    <Text><Trans i18nKey={`inspections_name`} defaults={`Inspections`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Item name="inspection_expected">
                                        <CustomInputNumber value={form.getFieldValue('inspection_expected')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="inspection_actual">
                                        <CustomInputNumber value={form.getFieldValue('inspection_actual')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="inspection_expected_per_week">
                                        <CustomInputNumber value={form.getFieldValue('inspection_expected_per_week')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="inspection_actual_per_week">
                                        <CustomInputNumber value={form.getFieldValue('inspection_actual_per_week')}/>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={8}>
                                    <Text><Trans i18nKey={`floor_inspections`} defaults={`Floor Inspections`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Item name="floor_inspection_expected">
                                        <CustomInputNumber value={form.getFieldValue('floor_inspection_expected')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="floor_inspection_actual">
                                        <CustomInputNumber value={form.getFieldValue('floor_inspection_actual')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="floor_inspection_expected_per_week">
                                        <CustomInputNumber value={form.getFieldValue('floor_inspection_expected_per_week')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="floor_inspection_actual_per_week">
                                        <CustomInputNumber value={form.getFieldValue('floor_inspection_actual_per_week')}/>
                                    </Item>
                                </Col>
                            </Row>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col span={8}>
                                    <Text><Trans i18nKey={`roof_inspections`} defaults={`Roof Inspections`}/></Text>
                                </Col>
                                <Col span={4}>
                                    <Item name="roof_inspection_expected">
                                        <CustomInputNumber value={form.getFieldValue('roof_inspection_expected')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="roof_inspection_actual">
                                        <CustomInputNumber value={form.getFieldValue('roof_inspection_actual')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="roof_inspection_expected_per_week">
                                        <CustomInputNumber value={form.getFieldValue('roof_inspection_expected_per_week')}/>
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name="roof_inspection_actual_per_week">
                                        <CustomInputNumber value={form.getFieldValue('roof_inspection_actual_per_week')}/>
                                    </Item>
                                </Col>
                            </Row>
                        </Card>
                    </Section>
                    <Section
                        title={<Trans i18nKey={`selection`} defaults={`Selection`}/>}
                        isEdit={isEdit}
                        isCheck={flag?.selection_flag}
                        onCheck={value => setFlag({...flag, selection_flag: value})}
                        statusValue={flag?.selection_status}
                        onSelect={value => setFlag({...flag, selection_status: value})}
                        value={comment?.selection_comment}
                        points={allocatedPoints?.selection_point}
                        onChange={value => setComment({...comment, selection_comment: value})}
                        pointValue={point?.selection_point}
                        onPointChange={value => setPoint({...point, selection_point: value?value:undefined})}
                        btnText={`${t('truss_selection_breakdown')} ${form.getFieldValue('inspection_actual')} ${t('inspections_name')}`}
                    >
                        <Space>
                            <Item
                                label={labelText(<Trans i18nKey={`average_span`} defaults={`Average Span`}/>, SELECTION_LABEL_WIDTH)}
                                name="avg_span"
                                extra=">= 21"
                            >
                                <CustomInputNumber
                                    addonAfter={"Ft"}
                                    value={form.getFieldValue('avg_span')}
                                />
                            </Item>
                            {isEdit?<Link to={'/view/fetch_span_data'} target={"_blank"} rel={"noopener noreferrer"}>
                                    <Button
                                        style={{...reportButtonStyle, margin: '0px 0px 48px 20px'}}
                                        disabled={!(props.locationID && quarter)}
                                    ><Trans i18nKey={`span_breakdown`} defaults={`Span Breakdown`}/></Button>
                                </Link>:
                                <Button
                                    style={{...reportButtonStyle, margin: '0px 0px 48px 20px'}}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/view/fetch_span_data',
                                            state: {
                                                start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                                                end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                                                quarter_date: meta?.reviewQuarter?moment(meta?.reviewQuarter).format('YYYY-MM-DD'):quarterDate,
                                                quarter_name: quarter,
                                                weeks: weeks.toString()
                                            }
                                        })
                                    }}
                                    disabled={!(props.locationID && quarter)}
                                ><Trans i18nKey={`span_breakdown`} defaults={`Span Breakdown`}/></Button>}
                        </Space>
                        <Item
                            label={labelText(<Trans i18nKey={`percentage_for`} defaults={`Percentage of Floor reported (%)`}/>, SELECTION_LABEL_WIDTH)}
                            name="percent_floor_reported"
                        >
                            <CustomInputNumber
                                max={100}
                                isEdit={isEdit}
                                value={form.getFieldValue('percent_floor_reported')}
                                onChange={() => calculateInspectionExpectedValues()}
                            />
                        </Item>
                        <Item
                            label={labelText(<Trans i18nKey={`percentage_for_floor`} defaults={`Percentage of Floor calculated`}/>, SELECTION_LABEL_WIDTH)}
                            name="percent_floor_calculated"
                        >
                            <CustomInputNumber
                                max={100}
                                addonAfter={"%"}
                                value={form.getFieldValue('percent_floor_calculated')}
                            />
                        </Item>
                        <Item
                            label={labelText(<Trans i18nKey={`avg_critical`} defaults={`Avg Critical Joints per Inspection`}/>, SELECTION_LABEL_WIDTH)}
                            name="avg_critical_joints_per_inspection"
                            extra={`${t('reported_should_be')} <= ${t('calculated')}`}
                        >
                            <CustomInputNumber value={form.getFieldValue('avg_critical_joints_per_inspection')}/>
                        </Item>
                    </Section>
                    <Section
                        title={<Trans i18nKey={`inspection_detail`} defaults={`Inspection Detail`}/>}
                        isEdit={isEdit}
                        isCheck={flag?.inspection_detail_flag}
                        onCheck={value => setFlag({...flag, inspection_detail_flag: value})}
                        statusValue={flag?.inspection_details_status}
                        onSelect={value => setFlag({...flag, inspection_details_status: value})}
                        value={comment?.inspection_detail_comment}
                        points={allocatedPoints?.inspection_detail_point}
                        onChange={value => setComment({...comment, inspection_detail_comment: value})}
                        pointValue={point?.inspection_detail_point}
                        onPointChange={value => setPoint({...point, inspection_detail_point: value?value:undefined})}
                    >
                        <Divider orientation={"left"}><Trans i18nKey={`inspections_name`} defaults={`Inspections`}/></Divider>
                        <Item
                            label={labelText(<Trans i18nKey={`number_of_critical`} defaults={`Number of Critical Joints`}/>, INSPECTION_DETAIL_LABEL_WIDTH)}
                            name="joint_count"
                        >
                            <CustomInputNumber value={form.getFieldValue('joint_count')}/>
                        </Item>
                        <Item
                            label={labelText(<Trans i18nKey={`number_of_member`} defaults={`Number of Members contact with Critical Joint`}/>, INSPECTION_DETAIL_LABEL_WIDTH)}
                            name="member_count"
                        >
                            <CustomInputNumber value={form.getFieldValue('member_count')}/>
                        </Item>
                        <Divider orientation={"left"}>
                            <Trans i18nKey={`inspection_ooc`} defaults={`Inspection Out of Conformance`}/>
                            {allocatedPoints?.inspection_ooc_point && <Extra
                                isEdit={false}
                                style={{marginLeft: 10, width: 200}}
                                points={allocatedPoints?.inspection_ooc_point}
                                pointValue={point?.inspection_ooc_point}
                                onPointChange={value => setPoint({...point, inspection_ooc_point: value?value:undefined})}
                            />}
                        </Divider>
                        <Item
                            label={labelText(<Trans i18nKey={`OOC_inspection_total`} defaults={`Out of Conformance Inspection Total`}/>, INSPECTION_DETAIL_LABEL_WIDTH)}
                            name="out_of_conformance_total_count"
                        >
                            <CustomInputNumber value={form.getFieldValue('out_of_conformance_total_count')}/>
                        </Item>
                        <Card title={<Trans i18nKey={`overall_ooc`} defaults={`Overall Out of Conformance`}/>} style={{marginBottom: 20}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Percentage'}
                                seriesField={'category'}
                                animation={{
                                    appear: {
                                        animation: 'path-in',
                                        duration: 2000,
                                    },
                                }}
                                yAxis={{
                                    title: {
                                        text: `${t('percentage')}`
                                    }
                                }}
                                xAxis={{
                                    title: {
                                        text: `${t('quarter')}`
                                    }
                                }}
                                tooltip={{
                                    formatter: ({category, Percentage}) => ({ name: category, value: Percentage }),
                                }}
                                data={oocGraphData()}
                            />
                        </Card>
                        <Table
                            style={{marginBottom: 20}}
                            columns={[
                                {
                                    title: <Trans i18nKey={`quarter`} defaults={`Quarter`}/>,
                                    dataIndex: 'quarter',
                                    key: 'quarter',
                                },
                                {
                                    title: <Trans i18nKey={`start_date`} defaults={`Start date`}/>,
                                    dataIndex: 'start_date',
                                    key: 'start_date',
                                },
                                {
                                    title: <Trans i18nKey={`end_date`} defaults={`End date`}/>,
                                    dataIndex: 'end_date',
                                    key: 'end_date',
                                },
                                {
                                    title: <Trans i18nKey={`ooc`} defaults={`Out of Conformance`}/>,
                                    dataIndex: 'out_of_conformance_percent',
                                    key: 'out_of_conformance_percent',
                                    render: (value: number) => `${value}%`
                                },
                                {
                                    title: <Trans i18nKey={`ooc_certified`} defaults={`Out of Conformance Certified`}/>,
                                    dataIndex: 'out_of_conformance_cert_percent',
                                    key: 'out_of_conformance_cert_percent',
                                    render: (value: number) => `${value}%`
                                },
                                {
                                    title: <Trans i18nKey={`quarter_name`} defaults={`Quarter name`}/>,
                                    dataIndex: 'quarter_name',
                                    key: 'quarter_name',
                                }
                            ]}
                            dataSource={form.getFieldValue('out_of_conformance_percent_history')}
                            pagination={false}
                            bordered
                        />
                        <Space>
                            <Item
                                style={{marginTop: 21}}
                                label={labelText(<Trans i18nKey={`ooc_preliminery`} defaults={`Out of Conformance Preliminary`}/>, OOC_LABEL_WIDTH)}
                                name="out_of_conformance_preliminary_percent"
                            >
                                <div className="review-dashboard-addon-input-style">
                                    <InputNumber
                                        disabled={true}
                                        style={{width: 125}}
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('out_of_conformance_preliminary_percent')}
                                    />
                                </div>
                            </Item>
                            {allocatedPoints?.preliminary_ooc_point && <Extra
                                isEdit={false}
                                points={allocatedPoints?.preliminary_ooc_point}
                                pointValue={point?.preliminary_ooc_point}
                                onPointChange={value => setPoint({...point, preliminary_ooc_point: value?value:undefined})}
                            />}
                        </Space>
                        <br/>
                        <Space>
                            <Item
                                style={{marginTop: 21}}
                                label={labelText(<Trans i18nKey={`ooc_joints`} defaults={`Out of Conformance Joints`}/>, OOC_LABEL_WIDTH)}
                                name="out_of_conformance_detiled_percent"
                            >
                                <div className="review-dashboard-addon-input-style">
                                    <InputNumber
                                        disabled={true}
                                        style={{width: 125}}
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('out_of_conformance_detailed_percent')}
                                    />
                                </div>
                            </Item>
                            {allocatedPoints?.detailed_ooc_point && <Extra
                                isEdit={false}
                                points={allocatedPoints?.detailed_ooc_point}
                                pointValue={point?.detailed_ooc_point}
                                onPointChange={value => setPoint({...point, detailed_ooc_point: value?value:undefined})}
                            />}
                        </Space>
                        <Divider orientation={"left"}><Trans i18nKey={`plates`} defaults={`Plates`}/></Divider>
                        <Space>
                            {plateGraph(<Trans i18nKey={`distance`} defaults={`Distance`}/>, [
                                {
                                    name: t('LOCATION_AVERAGE'),
                                    'Offset': t('MISPLACEMENT'),
                                    'Average': form.getFieldValue('avg_misplacement')
                                },
                                {
                                    name: t('CERTIFIED_AVERAGE'),
                                    'Offset': t('MISPLACEMENT'),
                                    'Average': form.getFieldValue('avg_certified_misplacement')
                                }
                            ])}
                            {plateGraph(<Trans i18nKey={`rotation`} defaults={`Rotation`}/>, [
                                {
                                    name: t('LOCATION_AVERAGE'),
                                    'Offset': t('ROTATION'),
                                    'Average': form.getFieldValue('avg_rotation')
                                },
                                {
                                    name: t('CERTIFIED_AVERAGE'),
                                    'Offset': t('ROTATION'),
                                    'Average': form.getFieldValue('avg_certified_rotation')
                                }
                            ])}
                        </Space>
                        <Space style={{marginBottom: 20}}>
                            <Card title={<Trans i18nKey={`distance`} defaults={`Distance`}/>} style={{width: PLATE_BOXES_OUTER_WIDTH}}>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_distance_c`} defaults={`Non zero distance count`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_distance_count"
                                >
                                    <CustomInputNumber value={form.getFieldValue('non_zero_distance_count')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_misplacement`} defaults={`Average misplacement`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_misplacement"
                                >
                                    <CustomInputNumber
                                        addonAfter={"/16"}
                                        value={form.getFieldValue('avg_misplacement')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_distance_p`} defaults={`Non zero distance percentage`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_distance_percent"
                                >
                                    <CustomInputNumber
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('non_zero_distance_percent')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_distance`} defaults={`Average distance of non zero`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_distance_of_non_zero"
                                >
                                    <CustomInputNumber
                                        addonAfter={"/16"}
                                        value={form.getFieldValue('avg_distance_of_non_zero')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`standard_deviation`} defaults={`Standard Deviation Distance`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="sd_distance"
                                >
                                    <CustomInputNumber value={form.getFieldValue('sd_distance')}/>
                                </Item>
                            </Card>
                            <Card title ={<Trans i18nKey={`rotation`} defaults={`Rotation`}/>} style={{width: PLATE_BOXES_OUTER_WIDTH}}>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_rotation_c`} defaults={`Non zero rotation count`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_rotation_count"
                                >
                                    <CustomInputNumber value={form.getFieldValue('non_zero_rotation_count')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_rotation`} defaults={`Average rotation`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_rotation"
                                >
                                    <CustomInputNumber
                                        addonAfter={"°"}
                                        value={form.getFieldValue('avg_rotation')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_rotation_p`} defaults={`Non zero rotation percentage`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_rotation_percent"
                                >
                                    <CustomInputNumber
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('non_zero_rotation_percent')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_rotation_of_nonzero`} defaults={`Average rotation of non zero`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_rotation_of_non_zero"
                                >
                                    <CustomInputNumber
                                        addonAfter={"°"}
                                        value={form.getFieldValue('avg_rotation_of_non_zero')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`standard_deviation_rotation`} defaults={`Standard Deviation Rotation`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="sd_rotation"
                                >
                                    <CustomInputNumber value={form.getFieldValue('sd_rotation')}/>
                                </Item>
                            </Card>
                        </Space>
                        <Item
                            label={<Trans i18nKey={`upsized_plates`} defaults={`Upsized Plates`}/>}
                            name="plates_upsized_percent"
                        >
                            <CustomInputNumber
                                max={100}
                                addonAfter={"%"}
                                value={form.getFieldValue('plates_upsized_percent')}
                            />
                        </Item>
                        <Divider orientation={"left"}><Trans i18nKey={`member-defects`} defaults={`Members - Defects`}/></Divider>
                        <Space style={{marginBottom: 20}}>
                            <Card title={<Trans i18nKey={`Lumber_Defects`} defaults={`Lumber Defects`}/>} style={{width: PLATE_BOXES_OUTER_WIDTH}}>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_lumber`} defaults={`Non zero lumber defect count`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_lumber_count"
                                >
                                    <CustomInputNumber value={form.getFieldValue('non_zero_lumber_count')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_lumber`} defaults={`Average lumber defects`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_lumber"
                                >
                                    <CustomInputNumber value={form.getFieldValue('avg_lumber')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_lumber_p`} defaults={`Non zero lumber defect percentage`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_lumber_percent"
                                >
                                    <CustomInputNumber
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('non_zero_lumber_percent')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average-lumber_of_non_zero`} defaults={`Average lumber of non zero`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_lumber_of_non_zero"
                                >
                                    <CustomInputNumber value={form.getFieldValue('avg_lumber_of_non_zero')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`standard_deviation_lumber`} defaults={`Standard Deviation Lumber`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="sd_lumber"
                                >
                                    <CustomInputNumber value={form.getFieldValue('sd_lumber')}/>
                                </Item>
                            </Card>
                            <Card title={<Trans i18nKey={`plate_defects`} defaults={`Plate Defects`}/>} style={{width: PLATE_BOXES_OUTER_WIDTH}}>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_plate_d_count`} defaults={`Non zero plate defect count`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_plate_count"
                                >
                                    <CustomInputNumber value={form.getFieldValue('non_zero_plate_count')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`average_plate_defects`} defaults={`Average plate defects`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_plate"
                                >
                                    <CustomInputNumber value={form.getFieldValue('avg_plate')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`non_zero_plate_d_p`} defaults={`Non zero plate defect percentage`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="non_zero_plate_percent"
                                >
                                    <CustomInputNumber
                                        max={100}
                                        addonAfter={"%"}
                                        value={form.getFieldValue('non_zero_plate_percent')}
                                    />
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`avg_plate_non_zero`} defaults={`Average plates of non zero`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="avg_plate_of_non_zero"
                                >
                                    <CustomInputNumber value={form.getFieldValue('avg_plate_of_non_zero')}/>
                                </Item>
                                <Item
                                    label={labelText(<Trans i18nKey={`standard_deviation_plates`} defaults={`Standard Deviation Plates`}/>, PLATE_BOXES_LABEL_WIDTH)}
                                    name="sd_plate"
                                >
                                    <CustomInputNumber value={form.getFieldValue('sd_plate')}/>
                                </Item>
                            </Card>
                        </Space>
                        <Divider orientation={"left"}><Trans i18nKey={`members_tooth_defects`} defaults={`Members - Tooth Defect`} /></Divider>
                        <Item
                            label={<Trans i18nKey={`ooc_members_with_defects`} defaults={`Out of conformance members found with tooth defects`}/>}
                            name="member_found_with_tooth_defect"
                        >
                            <CustomInputNumber value={form.getFieldValue('member_found_with_tooth_defect')}/>
                        </Item>
                    </Section>
                    <Section
                        title={<Trans i18nKey={`documentation`} defaults={`Documentation`}/>}
                        isEdit={isEdit}
                        isCheck={flag?.documentation_flag}
                        onCheck={value => setFlag({...flag, documentation_flag: value})}
                        statusValue={flag?.documentation_status}
                        onSelect={value => setFlag({...flag, documentation_status: value})}
                        value={comment?.documentation_comment}
                        points={allocatedPoints?.documentation_point}
                        onChange={value => setComment({...comment, documentation_comment: value})}
                        pointValue={point?.documentation_point}
                        onPointChange={value => setPoint({...point, documentation_point: value?value:undefined})}
                    >
                        {isEdit?<Link to='/view/inspection-comments'
                                      target={"_blank"} rel={"noopener noreferrer"}
                            >
                                <Button
                                    style={{...reportButtonStyle, margin: 5}}
                                    onClick={() => {
                                        localStorage.setItem(SCHEMA, 'inspection_comments')
                                        localStorage.setItem(IS_OOC, '')
                                        localStorage.setItem(TITLE, `${t('inspection_comments')}` )
                                    }}
                                    disabled={!(props.locationID && quarter)}
                                ><Trans i18nKey={`inspection_comments`} defaults={`Inspection Comments`} /></Button>
                            </Link>:
                            <Button
                                style={{...reportButtonStyle, margin: 5}}
                                onClick={() => {
                                    history.push({
                                        pathname: '/view/inspection-comments',
                                        state: {
                                            start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                                            end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                                            quarter_date: meta?.reviewQuarter?moment(meta?.reviewQuarter).format('YYYY-MM-DD'):quarterDate,
                                            quarter_name: quarter,
                                            weeks: weeks.toString(),
                                            title: `${t('inspection_comments')}`,
                                            schema: 'inspection_comments',
                                            isEdit: false,
                                            isOOC: false
                                        }
                                    })
                                }}
                                disabled={!(props.locationID && quarter)}
                            ><Trans i18nKey={`inspection_comments`} defaults={`Inspection Comments`} /></Button>}
                        {isEdit?<Link to='/view/review-ooc-comments'
                                      target={"_blank"} rel={"noopener noreferrer"}>
                                <Button
                                    style={{...reportButtonStyle, margin: 5}}
                                    onClick={() => {
                                        localStorage.setItem(SCHEMA, 'review_ooc_comments')
                                        localStorage.setItem(IS_OOC, 'true')
                                        localStorage.setItem(TITLE, `${t('ooc_comments')}`)
                                    }}
                                    disabled={!(props.locationID && quarter)}
                                ><Trans i18nKey={`ooc_comments`} defaults={`Out Of Conformance Comments`} /></Button>
                            </Link>:
                            <Button
                                style={{...reportButtonStyle, margin: 5}}
                                onClick={() => {
                                    history.push({
                                        pathname: '/view/review-ooc-comments',
                                        state: {
                                            start_date: meta?.reviewQuarter?.startOf("quarter").format("YYYY-MM-DD") || startDate,
                                            end_date: meta?.reviewQuarter?.endOf("quarter").format("YYYY-MM-DD") || endDate,
                                            quarter_date: meta?.reviewQuarter?moment(meta?.reviewQuarter).format('YYYY-MM-DD'):quarterDate,
                                            quarter_name: quarter,
                                            weeks: weeks.toString(),
                                            title: `${t('ooc_comments')}`,
                                            schema: 'review_ooc_comments',
                                            isEdit: false,
                                            isOOC: true
                                        }
                                    })
                                }}
                                disabled={!(props.locationID && quarter)}
                            ><Trans i18nKey={`ooc_comments`} defaults={`Out Of Conformance Comments`} /></Button>}
                    </Section>
                    <Section
                        title={<Trans i18nKey={`Overall_problem`} defaults={`Overall (Problem Summary)`}/>}
                        isEdit={isEdit}
                        isCheck={flag?.overall_flag}
                        onCheck={value => setFlag({...flag, overall_flag: value})}
                        statusValue={flag?.overall_status}
                        onSelect={value => setFlag({...flag, overall_status: value})}
                        value={comment?.overall_comment}
                        onChange={value => setComment({...comment, overall_comment: value})}
                    >
                        <Item
                            label={labelText(<Trans i18nKey={`areas_to_improve`} defaults={`Areas For Improvement`}/>, OVERALL_LABEL_WIDTH)}
                            name="areas_for_improvement"
                            extra={<Trans i18nKey={`complement_what_do_they_will`} defaults={`(Complement what they do well)`}/>}
                        >
                            <CustomTextArea
                                value={form.getFieldValue('areas_for_improvement')}
                                rows={4}
                                style={{width: '50vw'}}
                                isEdit={isEdit}
                            />
                        </Item>
                        <Item
                            label={labelText(<Trans i18nKey={`other_comments`} defaults={`Other Comments`}/>, OVERALL_LABEL_WIDTH)}
                            name="other_comments"
                        >
                            <CustomTextArea
                                value={form.getFieldValue('other_comments')}
                                rows={4}
                                style={{width: '50vw'}}
                                isEdit={isEdit}
                            />
                        </Item>
                        <Button
                            style={addTextButtonStyle}
                            size={"small"}
                            onClick={() => {
                                const curValue = form.getFieldValue('other_comments') || ''
                                const newValue = curValue + `${t('contact_us')}`
                                form.setFieldsValue({ other_comments: newValue })
                            }}
                            disabled={!isEdit}
                        ><Trans i18nKey={`add_text`} defaults={`Add text to Other Comments`}/></Button>
                    </Section>
                </Form>
            </Spin>
        </div>
    </Fragment>
}

export default Dashboard
