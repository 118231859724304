import React, {useState} from "react";
import {Checkbox} from 'antd';
import {Trans} from "./CustomTransComponent";

export interface SingleSelectCustomCheckBoxProps{
    schema: string,
    options: [string, string][],
    value: string,
    onChange?: (data?: any) => void
}

const SingleSelectCustomCheckBox: React.FunctionComponent<SingleSelectCustomCheckBoxProps> = (props) => {
    const [selected, setSelected] = useState<string|null>(props.value);

    const onChange = (item: string) =>{
        setSelected((prevState => prevState===item?null:item));
        props.onChange?.(selected===item?null:item);
    }

    return <>
        {props.options.map(([key, value])=><Checkbox onChange={() => onChange(key)} checked={key===selected}>
            <Trans i18nKey={`${props.schema}.${key}`} defaults={key} />
        </Checkbox>)}
    </>
}

export default SingleSelectCustomCheckBox;