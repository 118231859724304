import React, {Fragment} from "react";
import {Card, Collapse, Empty, Space, Typography} from "antd";
import {
    fillColor, graphValues,
    POSITION_OF_GRAPH_VALUES,
    summaryReports
} from "../../utils/reportConstants";
import {Bullet, Column, DualAxes, Pie} from "@ant-design/plots";
import {OutOfConformance} from "./Reports";
import {mapTitle} from "../../utils/report";
import {useTranslation} from "react-i18next";
import {Trans} from "../lib/CustomTransComponent";

interface OverallQCInspectionsProps {
    overallQCInspections: any
}

export const OverallQCInspections: React.FunctionComponent<OverallQCInspectionsProps> = ({overallQCInspections}) => {

    const {total_number_inspected: {trusses, preliminary, joints, members}} = overallQCInspections
    const {numberOutOfConformance: {trusses: outOfTrusses, preliminary: outOfPreliminary, joints: outOfJoints, members: outOfMembers}} = overallQCInspections
    const {percentageOfOutOfConformance: {trusses: outOfTrussesPercent, preliminary: outOfPreliminaryPercent, joints: outOfJointsPercent, members: outOfMembersPercent}} = overallQCInspections
    const {t} = useTranslation();

    return <Fragment>
        {trusses?<Fragment>
            <Column
                color={fillColor}
                isGroup={true}
                seriesField={'name'}
                xField={'Trusses or Joints'}
                yField={'Count'}
                meta={{
                    'Count':{
                        alias: `${t('summary_report.COUNT', 'Count')}`
                    }
                }}
                yAxis={{
                    title: {
                        text: `${t(summaryReports.COUNT)}`
                    }
                }}
                data={[
                    {
                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                        'Trusses or Joints': `${t(summaryReports.TRUSSES)}`,
                        'Count': trusses
                    },
                    {
                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                        'Trusses or Joints': `${t(summaryReports.PRELIMINARY)}`,
                        'Count': preliminary
                    },
                    {
                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                        'Trusses or Joints': `${t(summaryReports.JOINTS)}`,
                        'Count': joints
                    },
                    {
                        name: `${t(summaryReports.NO_OF_INSPECTED)}`,
                        'Trusses or Joints': `${t(summaryReports.MEMBERS)}`,
                        'Count': members
                    },
                    {
                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                        'Trusses or Joints': `${t(summaryReports.TRUSSES)}`,
                        'Count': outOfTrusses
                    },
                    {
                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                        'Trusses or Joints': `${t(summaryReports.PRELIMINARY)}`,
                        'Count': outOfPreliminary
                    },
                    {
                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                        'Trusses or Joints': `${t(summaryReports.JOINTS)}`,
                        'Count': outOfJoints
                    },
                    {
                        name: `${t(summaryReports.OUT_OF_CONFORMANCE)}`,
                        'Trusses or Joints': `${t(summaryReports.MEMBERS)}`,
                        'Count': outOfMembers
                    }
                ]}
            />
            <Space style={{maxWidth: '100%', overflowX: 'auto'}}>
                {[
                    {name: t(summaryReports.TRUSSES), value: outOfTrussesPercent},
                    {name: t(summaryReports.PRELIMINARY), value: outOfPreliminaryPercent},
                    {name: t(summaryReports.JOINTS), value: outOfJointsPercent},
                    {name: t(summaryReports.MEMBERS), value: outOfMembersPercent}
                ].map(({name, value}) => <Card
                    title={`${t('summary_report.overview', {name})}`}
                    style={{width: '25vw'}}
                >
                    <Pie
                        angleField={'Percentage'}
                        colorField={'Out of Conformance'}
                        label={{
                            type: 'inner',
                            offset: '-30%',
                            content: ({ percent }) => `${(percent * 100).toFixed(2)}%`,
                            style: {
                                fontSize: 14,
                                textAlign: 'center',
                            },
                        }}
                        data={[
                            {
                                'Out of Conformance': `${t('summary_report.passed', {name})}`,
                                'Percentage': 100 - value
                            },
                            {
                                'Out of Conformance': `${t('summary_report.failed', {name})}`,
                                'Percentage': value
                            }
                        ]}
                    />
                </Card>)}
            </Space>
        </Fragment>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

interface SummaryReportProps {
    overallQCInspections: any
    outOfConformanceVisual: any
    outOfConformanceDetailed: any
    inspectionByRange: any
    outOfConformanceMembers: any
    platesUpsized: any
}

const SummaryReport: React.FunctionComponent<SummaryReportProps> = ({
                                                                        overallQCInspections,
                                                                        outOfConformanceVisual,
                                                                        outOfConformanceDetailed,
                                                                        outOfConformanceMembers,
                                                                        platesUpsized,
                                                                        inspectionByRange
                                                                    }) => {
    const { Panel } = Collapse;
    const {totalPlates, upsizedPlates} = platesUpsized
    const {title, data} = inspectionByRange
    const {t} = useTranslation();
    const getInspectionTitle = (title: String) => {
        let key, defaults;
        switch (title) {
            case summaryReports.INSPECTIONS_BY_DAY_TXT:
                key = summaryReports.INSPECTIONS_BY_DAY;
                defaults = 'Inspections By Day';
                break;
            case summaryReports.INSPECTIONS_BY_WEEK_TXT:
                key = summaryReports.INSPECTIONS_BY_WEEK;
                defaults = 'Inspections By Week';
                break;
            case summaryReports.INSPECTIONS_BY_QUARTER_TXT:
                key = summaryReports.INSPECTIONS_BY_QUARTER;
                defaults = 'Inspections By Quarter';
                break;
            default:
                key = '';
                defaults = '';
                break;
        }
        return <Trans i18nKey={key} defaults={defaults} />
    }
    return <Collapse defaultActiveKey={['overallQCInspections']}>
        {overallQCInspections && <Panel key={'overallQCInspections'} header={<Trans i18nKey={summaryReports.OVERALL_QC_INSPECTIONS} defaults={`Overall QC Inspections`} />}>
            <OverallQCInspections overallQCInspections={overallQCInspections}/>
        </Panel>}
        {outOfConformanceVisual && <Panel key={'outOfConformanceVisual'} header={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_PRELIMINARY} defaults={`Out Of Conformance Preliminary`} />}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: `${t(summaryReports.INSPECTION_COUNT)}`
                    }
                }}
                meta={{
                    'No of Conformance':{
                        alias: `${t('summary_report.no_of_conformance', 'Count')}`
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceVisual)?.map(visuals => {
                    const outOfConformanceVisuals : OutOfConformance = visuals[1] as OutOfConformance
                    return {
                        'Type': t(mapTitle(visuals[0])),
                        ['No of Conformance']: outOfConformanceVisuals.count,
                        'percent':  outOfConformanceVisuals.percentage
                    }
                })}
            />
        </Panel>}
        {outOfConformanceDetailed && <Panel key={'outOfConformanceDetailed'} header={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_JOINTS} defaults={`Out Of Conformance Joints`} />}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: `${t(summaryReports.JOINT_COUNT)}`
                    }
                }}
                meta={{
                    'No of Conformance':{
                        alias: `${t('summary_report.no_of_conformance', 'Count')}`
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceDetailed)?.map(detailedJoints => {
                    const outOfConformanceDetailedJoints : OutOfConformance = detailedJoints[1] as OutOfConformance
                    return {
                        'Type': t(mapTitle(detailedJoints[0])),
                        ['No of Conformance']: outOfConformanceDetailedJoints.count,
                        'percent':  outOfConformanceDetailedJoints.percentage
                    }
                })}
            />
        </Panel>}
        {outOfConformanceMembers && <Panel key={'outOfConformanceMembers'} header={<Trans i18nKey={summaryReports.OUT_OF_CONFORMANCE_MEMBERS} defaults={`Out Of Conformance Members`} />}>
            <Column
                color={fillColor}
                xField={'Type'}
                yField={'No of Conformance'}
                yAxis={{
                    title: {
                        text: `${t(summaryReports.MEMBER_COUNT)}`
                    }
                }}
                meta={{
                    'No of Conformance':{
                        alias: `${t('summary_report.no_of_conformance', 'Count')}`
                    }
                }}
                label={{
                    position: POSITION_OF_GRAPH_VALUES,
                    style: {
                        fill: graphValues.COLOR,
                        OPACITY: graphValues.OPACITY
                    },
                    content: ({percent}) => `${percent || '0'} %`,
                }}
                data={Object.entries(outOfConformanceMembers)?.map(members => {
                    const outOfConformanceDetailedMembers : OutOfConformance = members[1] as OutOfConformance
                    return {
                        'Type': t(mapTitle(members[0])),
                        ['No of Conformance']: outOfConformanceDetailedMembers.count,
                        'percent':  outOfConformanceDetailedMembers.percentage
                    }
                })}
            />
        </Panel>}
        {platesUpsized && <Panel key={'platesUpsized'} header={<Trans i18nKey={summaryReports.PLATES_UPSIZED_FROM_SPECIFIED} defaults={`Plates Upsized From Specified`} />}>
            {totalPlates?<Bullet
                measureField={'measures'}
                rangeField={'ranges'}
                targetField={'Total plates'}
                xField={'title'}
                data={[
                    {
                        title: `${t(summaryReports.PLATES_UPSIZED)}`,
                        ranges: [totalPlates],
                        measures: [upsizedPlates],
                        'Total plates': totalPlates,
                    }
                ]}
            />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </Panel>}
        {inspectionByRange && <Panel key={'inspectionByRange'} header={getInspectionTitle(title)}>
            <DualAxes
                xField={'range'}
                yField={['inspections', 'passPercentage']}
                meta={{
                    'inspections':{
                        alias: `${t('summary_report.inspections', 'inspections')}`
                    },
                    'passPercentage':{
                        alias: `${t('summary_report.passPercentage', 'passPercentage')}`
                    }
                }}
                data={[data, data]}
                geometryOptions={[
                    {
                        geometry: 'column'
                    },
                    {
                        geometry: 'line',
                        lineStyle: {
                            lineWidth: 2
                        },
                    },
                ]}
            />
        </Panel>}
    </Collapse>
}

export default SummaryReport;