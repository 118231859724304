import React, {Fragment} from "react";
import {Card, Descriptions, Empty, Typography} from "antd";
import moment from "moment";
import {ConfigsContext} from "../configs/ConfigsProvider";
import {Trans} from "../lib/CustomTransComponent";

interface CommentData {
    id: number,
    job_name: string,
    status: boolean,
    date: string,
    inspector_id: number,
    local_uuid: string,
    first_name: string,
    last_name: string,
    line_id: number,
    line_name: string,
    shift_id: number,
    shift_name: string,
    crew_id: number,
    crew_name: string,
    truss_id: string,
    type: string
    comments: string
}

interface CommentReportProps {
    data: CommentData[]
    column?: number
}

const CommentReport: React.FunctionComponent<CommentReportProps> = ({data, column}) => {
    const {Item} = Descriptions
    const {Text} = Typography
    return <Fragment>
        {data?.length>0?data?.map(({
                        id,
                        job_name,
                        status,
                        date,
                        local_uuid,
                        first_name,
                        last_name,
                        line_name,
                        shift_name,
                        crew_name,
                        truss_id,
                        type,
                        comments
                    }) => <Card style={{backgroundColor: '#f5f5f5', margin: 10}}>
            <Descriptions
                title={<Text><Trans i18nKey={'comment_report.title'}>Job: {{job_name}}</Trans></Text>}
                column={column}
                extra={<Text type={"secondary"}><Trans i18nKey={'comment_report.extra'}>Id: {{id}}</Trans></Text>}
                labelStyle={{fontWeight: 'bold'}}
            >
                <Item label={<Trans i18nKey={'comment_report.date'}>Date</Trans>}><ConfigsContext.Consumer>{configs=>moment(date).format(configs.dateTimeFormat)}</ConfigsContext.Consumer></Item>
                <Item label={<Trans i18nKey={'comment_report.inspector'}>Inspector</Trans>}>{first_name + ' ' + last_name}</Item>
                <Item label={<Trans i18nKey={'comment_report.setup'}>Setup</Trans>}>{line_name}</Item>
                <Item label={<Trans i18nKey={'comment_report.shift'}>Shift</Trans>}>{shift_name}</Item>
                <Item label={<Trans i18nKey={'comment_report.crew'}>Crew</Trans>}>{crew_name}</Item>
                <Item label={<Trans i18nKey={'comment_report.inspection_id'}>Inspection Id</Trans>}>{local_uuid}</Item>
                <Item label={<Trans i18nKey={'comment_report.truss_id'}>Truss Id</Trans>}>{truss_id}</Item>
                <Item label={<Trans i18nKey={'comment_report.type'}>Type</Trans>}>{type}</Item>
                <Item label={<Trans i18nKey={'comment_report.status'}>Status</Trans>}>{status?<Trans i18nKey={'comment_report.pass'}>Pass</Trans>:<Trans i18nKey={'comment_report.fail'}>Fail</Trans>}</Item>
            </Descriptions>
            <Card>
                <Descriptions>
                    <Item>{comments}</Item>
                </Descriptions>
            </Card>
        </Card>):<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

export default CommentReport