import React, {FunctionComponent} from "react";
import {Card, Descriptions, PageHeader, Statistic} from "antd";
import {certificationReports} from "../../utils/reportConstants";
import {processTitle} from "../../utils/report";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface StatProps {
    name: string
    value: number
}

const Stat: FunctionComponent<StatProps> = ({name, value}) => {
    return <Card style={{backgroundColor: '#ececec'}}>
        <Card style={{width: 200, textAlign: "center"}}>
            <Statistic title={name} value={value} />
        </Card>
    </Card>
}

interface Breakdown {
    inspection_out_of_conformance: number
    preliminary_out_of_conformance: number
    critical_joints_out_of_conformance: number
    frequency: number
    truss_selection: number
    inspection_detail: number
}

interface ScoreProps {
    breakdown: Breakdown
    multiplier: number
    reducer_score: number
    total: number
    final_score: number
}

interface Data {
    data: ScoreProps
    column: number
}

const Score: React.FunctionComponent<Data> = ({data: {breakdown, multiplier, reducer_score, total, final_score}, column}) => {

    const {t} = useTranslation();
    const mapTitle = (title: string) => {
        switch (title) {
            case "inspection_out_of_conformance":
                return <Trans i18nKey={'certification_summary.inspection_ooc'} defaults={'Inspection Out Of Conformance'} />;
            case "preliminary_out_of_conformance":
                return <Trans i18nKey={'certification_summary.prelim_ooc'} defaults={'Preliminary Out Of Conformance'} />;
            case "critical_joints_out_of_conformance":
                return <Trans i18nKey={'certification_summary.critical_ooc'} defaults={'Critical Joints Out Of Conformance'} />;
            case "frequency":
                return <Trans i18nKey={'certification_summary.frequency'} defaults={'Frequency'} />;
            case "truss_selection":
                return <Trans i18nKey={'certification_summary.truss_selection'} defaults={'Truss Selection'} />;
            case "inspection_detail":
                return <Trans i18nKey={'certification_summary.inspection_detail'} defaults={'Inspection Detail'} />;
            default:
                return title;
        }
    }
    const {Item} = Descriptions
    return <Card style={{backgroundColor: '#f5f5f5'}}>
        <Card>
            <PageHeader title={<Trans i18nKey={certificationReports.CERTIFICATION_SCORING} defaults={`Certification Scoring`} />}>
                <Descriptions title={<Trans i18nKey={'certification_summary.score_breakdown'} defaults={'Score Breakdown'} />} column={1} bordered>
                    {Object.entries(breakdown)?.map(([name, value]) => <Item label={mapTitle(name)}>{value}</Item>)}
                </Descriptions>
                <Descriptions column={column} style={{margin: 20}}>
                    <Item><Stat name={t('certification_summary.multiplier')} value={multiplier} /></Item>
                    <Item><Stat name={t('certification_summary.reducer_score')} value={reducer_score} /></Item>
                    <Item><Stat name={t('certification_summary.total')} value={total} /></Item>
                    <Item><Stat name={t('certification_summary.final_score')} value={final_score} /></Item>
                </Descriptions>
            </PageHeader>
        </Card>
    </Card>
}

export default Score