import React, {FunctionComponent} from "react";
import {Tag} from "antd";
import {configs, fetchInspectionComments, fetchOutOfConformanceComments} from "../../api";
import {ConfigResponse, Response, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {handleScroll} from "../../utils/util";
import {useLocation} from "react-router-dom";
import moment from "moment";
import CustomPageHeader from "./CustomPageHeader";
import {
    END_DATE_VALUE,
    IS_OOC_VALUE,
    LOCATION_ID_VALUE,
    SCHEMA_VALUE,
    START_DATE_VALUE
} from "../../utils/constants";
import {Trans} from "../lib/CustomTransComponent";

interface ReviewOOCCommentsProps {
    location_id: number | undefined
}

const ReviewOOCComments: FunctionComponent<ReviewOOCCommentsProps> = ({location_id = LOCATION_ID_VALUE}) => {
    const title = localStorage.getItem("title") || '';
    const location: {pathname: string, state: {[key: string]: any}} = useLocation()
    let editMode = location?.state?.isEdit;

    return <TableV2
        header={<CustomPageHeader
            title={location?.state?.title || title}
            start_date={location?.state?.start_date}
            end_date={location?.state?.end_date}
            quarter_date={location?.state?.quarter_date}
            quarter_name={location?.state?.quarter_name}
            weeks={location?.state?.weeks}
            remove_back_button={location?.state?.start_date===undefined}
        />}
        schema={location?.state?.schema || SCHEMA_VALUE}
        fetchConfig={async (schema: string) => {
            const response = await configs(schema) || {}
            response.data.rows = response.data.rows.map((row: any) => {
                if (row.name) {
                    row.title = <Trans i18nKey={row.name} defaults={row.title}/>;
                }
                return row;
            });
            return response as ConfigResponse
        }}
        fetchData={async (request) => {
            handleScroll()
            if(location_id) {
                const payload = {
                    location_id,
                    start_date: location?.state?.start_date || START_DATE_VALUE,
                    end_date: location?.state?.end_date || END_DATE_VALUE
                }
                let response
                let isOOC
                if(editMode === false) {
                    isOOC = location?.state?.isOOC
                } else {
                    isOOC = IS_OOC_VALUE
                }
                if(isOOC) {
                    response = await fetchOutOfConformanceComments(payload)
                } else {
                    response = await fetchInspectionComments(payload)
                }
                return response as Response
            } else {
                return {
                    data: {
                        statusCode: 200,
                        rows: []
                    }
                }
            }
        }}
        customRenderer={{
            user: (value, row, index, column) => `${row?.first_name || ''} ${row?.last_name || ''}`,
            date: (value, row, index, column) => moment(value).utc().format('YYYY-MM-DD'),
            status: (value, row, index, column) => <Tag color={value?"success":"error"}>
                {value?'Passed':'Failed'}
            </Tag>
        }}
        scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
    />
}

export default ReviewOOCComments;
