import React, {Fragment} from "react";
import {Badge, Button, Card, Empty, Space, Statistic, Tabs, Tag, Typography} from "antd";
import {ConfigResponse, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {configs, fetch_inspection} from "../../api";
import {DownloadOutlined} from "@ant-design/icons";
import {ConfigsContext} from "../configs/ConfigsProvider";
import moment from "moment/moment";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

export interface plantLineSetting {
    inspection_count: number
    line_id: number
    active_line: boolean
    line_name: string
    actual_value: number
    expected_value: number
    isAmount: boolean
    percentageValue: number
    statusValue: boolean
    line_hours: string
    inspections: { [key: string]: any }[]
}

export interface plantShift {
    shift_id: number
    shift_name: string
    active_shift: boolean
    shift_hours: string
    lines: plantLineSetting[]
}
interface dataInterface {
    shiftData: plantShift[];
}

interface PlantReportProps {
    data: dataInterface;
}

interface HeaderProps {
    shift_name: string
    active_shift: boolean
    line_name: string
    active_line: boolean
    statusValue: boolean
    percentageValue: number
    inspection_count: number
    actual_value: number
    expected_value: number
    isAmount: boolean
}

interface InspectionDataProps{
    meta: HeaderProps
    data: { [key: string]: any }[]
}

const Header: React.FunctionComponent<HeaderProps> = ({
                                                          shift_name,
                                                          active_shift,
                                                          line_name,
                                                          active_line,
                                                          statusValue,
                                                          inspection_count,
                                                          isAmount,
                                                          actual_value,
                                                          percentageValue,
                                                          expected_value
                                                      }) => {
    const {t} = useTranslation()
    const expectedValueIcon = isAmount? 'A' : '%';
    return (
    <Space style={{padding: 10, background: '#ececec', marginBottom: 10}}>
    <Card><Statistic title={<Trans i18nKey={'plant_report.total_count'}>Total Count</Trans>} value={inspection_count} /></Card>
    <Card><Statistic title={<Trans i18nKey={'plant_report.shift_name'}>Shift Name</Trans>} value={shift_name} /></Card>
    <Card><Statistic title={<Trans i18nKey={'plant_report.shift_status'}>Shift Status</Trans>} value={active_shift?`${t('plant_report.active', 'Active')}`:`${t('plant_report.inactive', 'Inactive')}`} prefix={<Badge status={active_shift?'success':'error'} />} /></Card>
    <Card><Statistic title={<Trans i18nKey={'plant_report.setup_name'}>Setup Name</Trans>} value={line_name} /></Card>
    <Card><Statistic title={<Trans i18nKey={'plant_report.setup_status'}>Setup Status</Trans>} value={active_line?`${t('plant_report.active', 'Active')}`:`${t('plant_report.inactive', 'Inactive')}`} prefix={<Badge status={active_line?'success':'error'} />} /></Card>
    <Card><Statistic title={<Trans i18nKey={'plant_report.failed_count'}>Failed Count</Trans>} value={actual_value} /></Card>
    <Card><Statistic title={isAmount ? <Trans i18nKey={'plant_report.threshold_value'}>Threshold Value</Trans>: <Trans i18nKey={'plant_report.threshold_percentage'}>Threshold Percentage</Trans>} value={`${expected_value} ${expectedValueIcon}`} /></Card>
    <Card style={{ border: statusValue? '1px solid green' : '1px solid red', backgroundColor: statusValue? '#C1E1C1': '#FAA0A0' }}>
        <Statistic title={<Trans i18nKey={'plant_report.inspection_status'}>Inspection Status</Trans>} value={statusValue?`${t('plant_report.passed', 'Passed')}`:`${t('plant_report.failed', 'Failed')}`} prefix={<Badge status={statusValue?'success':'error'} />} />
    </Card>
</Space>) }

const InspectionData: React.FunctionComponent<InspectionDataProps> = ({meta, data}) => {
    return <Fragment>
        <TableV2
            header={<Header
                shift_name={meta.shift_name}
                active_shift={meta.active_shift}
                line_name={meta.line_name}
                active_line={meta.active_line}
                percentageValue={meta.percentageValue}
                statusValue={meta.statusValue}
                actual_value={meta.actual_value}
                expected_value={meta.expected_value}
                isAmount={meta.isAmount}
                inspection_count={meta.inspection_count}
            />}
            schema={'quality_control_log'}
            fetchConfig={async (schema: string) => {
                const response = await configs(schema) || {}
                response.data.rows = response.data?.rows?.map((row: any) => {
                    if (row.name) {
                        row.title = <Trans i18nKey={`plant_report.table.${row.name}`} defaults={row.title}/>;
                    }
                    return row;
                });
                return response as ConfigResponse
            }}
            fetchData={async () => ({
                data: {
                    statusCode: 200,
                    rows: data
                }
            })}
            customRenderer={{
                action: (value, {inspections_id}, index, column) => {
                    return <div>
                        <Space>
                            <Button onClick={async () => {
                                await fetch_inspection(inspections_id, "html")
                            }}><Trans i18nKey={'plant_report.view'}>View</Trans></Button>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={async () => {
                                await fetch_inspection(inspections_id, "pdf")
                            }}><Trans i18nKey={'plant_report.pdf'}>PDF</Trans></Button>
                        </Space>
                    </div>
                },
                created_at: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                },
                status: (value, row, index, column) => {
                    const nameCard = (name: string, color: string) => <Tag color={color} style={{margin: 2}}><Trans i18nKey={`plant_report.${name}`}>{name}</Trans></Tag>
                    return <Space>
                        {value ? nameCard('passed', 'green') : nameCard('not_conforming', 'red')}
                        {row.sign_off && nameCard('corrected', 'blue')}
                    </Space>
                },
                date: (value, row, index, column) => {
                    return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                }
            }}
        />
    </Fragment>
}

const PlantReport: React.FunctionComponent<PlantReportProps> = (props) => {
    const { TabPane } = Tabs;
    return <Fragment>
        {props.data?.shiftData?.length>0?<Tabs type="card">
            {props.data.shiftData.map(({shift_id, shift_name, active_shift, shift_hours, lines}) => <TabPane tab={shift_name} key={shift_id}>
                <Tabs tabPosition={'left'}>
                    {lines?.map(({
                                     inspection_count,
                                     line_id,
                                     active_line,
                                     line_name,
                                     actual_value,
                                     expected_value,
                                     statusValue,
                                     isAmount,
                                     percentageValue,
                                     inspections
                                 }) => <TabPane tab={line_name} key={line_id}>
                        <InspectionData
                            meta={{shift_name, active_shift, line_name, active_line, inspection_count, statusValue, percentageValue, actual_value, expected_value, isAmount}}
                            data={inspections}
                        />
                    </TabPane>)}
                </Tabs>
            </TabPane>)}
        </Tabs>:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Fragment>
}

export default PlantReport;