import React, {Fragment, FunctionComponent} from "react";
import {Card, Collapse, Space} from "antd";
import {
    ACTUAL,
    certificationReports, CRITICAL_INSPECTIONS,
    fillColor, FLOOR_TRUSSES, LUMBER, MISPLACED_PLATES, PLATE, ROOF_TRUSSES, ROTATED_PLATES,
    TARGET, UPSIZED_PLATES, VISUAL_INSPECTIONS,
} from "../../utils/reportConstants";
import {Bullet, Column, Line} from "@ant-design/plots";
import {
    FrequencySummary,
    inchToFeet, InspectionDetail,
    processData,
    processDefectFoundData, processFrequencySummaryData,
    processInspectionDetailData,
    processTrussCountData,
    processTrussPercentData,
    processUpsizedPlatesData, TrussSelection
} from "../../utils/report";
import Score from "./Score";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface CertificationReportBody {
    overallOutOfConformance: any
    defectsFound: any
    upsizedPlates: any
    placementTolerance: any
    frequencySummary: any
    trussSelection: any
    inspectionDetail: any
    scoring: any
}

const CertificationReport:FunctionComponent<CertificationReportBody> = ({
                                                                            overallOutOfConformance,
                                                                            defectsFound,
                                                                            upsizedPlates,
                                                                            placementTolerance,
                                                                            frequencySummary,
                                                                            trussSelection,
                                                                            inspectionDetail,
                                                                            scoring
                                                                        }) => {

    const { Panel } = Collapse
    const {t} = useTranslation();
    const mapDefectFoundData = (defectsFound: any, type: 'defect' | 'defect_out' | 'joint' | 'inspection') => {
        let data = processDefectFoundData(defectsFound, type);
        return data.map(item => ({...item, type: t(`certification_summary.${item.type}`, item.type)}));
    }
    const mapUpsizedPlatesData = (inputData: any) => {
        let data = processUpsizedPlatesData(inputData);
        return data.map(item => ({...item, type: t(`certification_summary.${mapToKey(item.type)}`, item.type)}));
    }
    const mapFrequencySummaryData = (inputData: FrequencySummary[], isTruss: boolean) => {
        let data = processFrequencySummaryData(inputData, isTruss);
        return data.map(item => ({...item, category: t(`certification_summary.${item.category}`, item.category)}));
    }
    const mapTrussCountData = (inputData: TrussSelection[]) => {
        let data = processTrussCountData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapTrussPercentData = (inputData: TrussSelection[]) => {
        let data = processTrussPercentData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapInspectionDetailData = (inputData: InspectionDetail[]) => {
        let data = processInspectionDetailData(inputData);
        return data.map(item => ({...item, category: t(`certification_summary.${mapToKey(item.category)}`, item.category)}));
    }
    const mapData = (inputData: any, isUnitConvert?: boolean) => {
        let data = processData(inputData, isUnitConvert);
        return data.map(item => ({...item, category: t(`certification_summary.${item.category}`, item.category)}));
    }
    const mapToKey = (phrase: string) => {
        return phrase.replace(/\s+/g, '_').toUpperCase();
    }
    return <Fragment>
        <Collapse defaultActiveKey={['overallOutOfConformance']}>
            {overallOutOfConformance && <Panel key={'overallOutOfConformance'} header={<Trans i18nKey={certificationReports.OVERALL_OUT_OF_CONFORMANCE} defaults={`Overall Out Of Conformance`} />}>
                <Line
                    xField={'Quarter'}
                    yField={'Average'}
                    seriesField={'category'}
                    animation={{
                        appear: {
                            animation: 'path-in',
                            duration: 2000,
                        },
                    }}
                    yAxis={{
                        title: {
                            text: `${t(certificationReports.PERCENTAGE)}`
                        }
                    }}
                    tooltip={{
                        formatter: ({category, Average}) => ({ name: category, value: Average + '%' }),
                    }}
                    data={mapData(overallOutOfConformance)}
                />
            </Panel>}
            {defectsFound && <Panel key={'defectsFound'} header={<Trans i18nKey={certificationReports.DEFECTS_FOUND} defaults={`Defects Found`} />}>
                <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_ALL_INSPECTIONS} defaults={`Total defects found in all inspections`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.COUNT)}`
                            }
                        }}
                        data={mapDefectFoundData(defectsFound, "defect")}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.TOTAL_DEFECTS_FOUND_FAILED_INSPECTIONS} defaults={`Total defects found in failed inspections`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.COUNT)}`
                            }
                        }}
                        data={mapDefectFoundData(defectsFound, "defect_out")}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.PERCENT_DEFECTS_OF_JOINTS} defaults={`Percentage of defects of all critical joints`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={mapDefectFoundData(defectsFound, "joint")}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.PERCENT_DEFECTS_OF_INSPECTIONS} defaults={`Percentage of defects of total inspections`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={mapDefectFoundData(defectsFound, "inspection")}
                    />
                </Card>
            </Panel>}
            {upsizedPlates && <Panel key={'upsizedPlates'} header={<Trans i18nKey={certificationReports.UPSIZED_PLATES} defaults={`Upsized Plates`} />}>
                <Card title={<Trans i18nKey={certificationReports.PERCENT_OF_PLATES_UPSIZED} defaults={`Percentage of plates upsized`} />}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: t(`certification_summary.UPSIZED_PLATES`), value: count + '%' })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            name: quarter.range,
                            count: quarter.upsized_trusses
                        }))}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES} defaults={`Additional Square Inches of Upsized Plates`} />}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.SQUARE_INCHES)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: t('certification_summary.ADDITIONAL_SQ_INCHES'), value: count + ' sq in' })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            name: quarter.range,
                            count: quarter.additional_square
                        }))}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.RATIO_OF_SQUARE_INCHES} defaults={`Ratio of square inches`} />}>
                    <Bullet
                        measureField={'measures'}
                        rangeField={'ranges'}
                        targetField={'target'}
                        xField={'title'}
                        tooltip={{
                            formatter: ({measures}) => ({ name: t('certification_summary.ACTUAL_SPECIFIED_RATIO'), value: measures })
                        }}
                        data={upsizedPlates?.map((quarter: any) => ({
                            title: quarter.range,
                            ranges: [1, quarter.area_ratio * 1.2],
                            measures: [quarter.area_ratio],
                            target: 1
                        }))}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.PERCENT_TRUSSES_OF_UPSIZED_PLATES} defaults={`Percentage of trusses with upsized plates`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'type'}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({type, count}) => ({ name: type, value: count + '%' })
                        }}
                        data={mapUpsizedPlatesData(upsizedPlates)}
                    />
                </Card>
            </Panel>}
            {placementTolerance && <Panel key={'placementTolerance'} header={<Trans i18nKey={certificationReports.PLACEMENT_TOLERANCE} defaults={`Placement Tolerance`} />}>
                <Card title={<Trans i18nKey={certificationReports.AVERAGE_DISTANCE} defaults={`Average Distance`} />}>
                    <Space>
                        <Card title={<Trans i18nKey={certificationReports.FRONT} defaults={`Front Side`} />} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(placementTolerance?.map((quarter: any) => quarter.distance.front))}
                            />
                        </Card>
                        <Card title={<Trans i18nKey={certificationReports.BACK} defaults={`Back Side`} />} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(placementTolerance?.map((quarter: any) => quarter.distance.back))}
                            />
                        </Card>
                    </Space>
                </Card>
                <Card title={<Trans i18nKey={certificationReports.AVERAGE_ROTATION} defaults={`Average Rotation`} />}>
                    <Space>
                        <Card title={<Trans i18nKey={certificationReports.FRONT} defaults={`Front Side`} />} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(placementTolerance?.map((quarter: any) => quarter.rotation.front))}
                            />
                        </Card>
                        <Card title={<Trans i18nKey={certificationReports.BACK} defaults={`Back Side`} />} type={"inner"} style={{width: '40vw'}}>
                            <Line
                                xField={'Quarter'}
                                yField={'Average'}
                                seriesField={'category'}
                                lineStyle={({category}) => {
                                    if(category==='goal'){
                                        return {
                                            lineDash: [4, 4]
                                        }
                                    }
                                }}
                                data={mapData(placementTolerance?.map((quarter: any) => quarter.rotation.back))}
                            />
                        </Card>
                    </Space>
                </Card>
            </Panel>}
            {frequencySummary && <Panel key={'frequencySummary'} header={<Trans i18nKey={certificationReports.FREQUENCY_SUMMARY} defaults={`Frequency Summary`} />}>
                <Space>
                    <Card title={<Trans i18nKey={certificationReports.TRUSSES_PER_SHIFT_PER_WEEK} defaults={`Trusses per Shift per Week`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.TRUSSES)}`
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            data={mapFrequencySummaryData(frequencySummary, true)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.HOURS_PER_WEEK} defaults={`Hours per Week`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Count'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.HOURS)}`
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category===TARGET){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Count}) => ({ name: category, value: `${Count}h` })
                            }}
                            data={mapFrequencySummaryData(frequencySummary, false)}
                        />
                    </Card>
                </Space>
            </Panel>}
            {trussSelection && <Panel key={'trussSelection'} header={<Trans i18nKey={certificationReports.TRUSS_SELECTION} defaults={`Truss Selection`} />}>
                <Space>
                    <Card title={<Trans i18nKey={certificationReports.TRUSS_COUNT} defaults={`Truss Count`} />} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.COUNT)}`
                                }
                            }}
                            data={mapTrussCountData(trussSelection)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.TRUSSES_AS_PERCENTAGE} defaults={`Trusses as Percentage`} />} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            isGroup={true}
                            seriesField={'category'}
                            xField={'Quarter'}
                            yField={'Percent'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.PERCENTAGE)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Percent}) => ({ name: category, value: Percent?`${Percent}%`:0 })
                            }}
                            data={mapTrussPercentData(trussSelection)}
                        />
                    </Card>
                </Space>
                <Space>
                    <Card title={<Trans i18nKey={certificationReports.ROOF_SPAN} defaults={`Average Span - Roof Trusses`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.AVERAGE_FEET)}`
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            data={mapData(trussSelection?.map((quarter: any) => quarter.roof.span), true)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.FLOOR_SPAN} defaults={`Average Span - Floor Trusses`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.AVERAGE_FEET)}`
                                }
                            }}
                            lineStyle={({category}) => {
                                if(category==='goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: inchToFeet(Average * 12) })
                            }}
                            data={mapData(trussSelection?.map((quarter: any) => quarter.floor.span), true)}
                        />
                    </Card>
                </Space>
                <Space>
                    <Card title={<Trans i18nKey={certificationReports.ROOF_CRITICAL} defaults={`Average Critical Joints - Roof Trusses`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={mapData(trussSelection?.map((quarter: any) => quarter.roof.critical), false)}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.FLOOR_CRITICAL} defaults={`Average Critical Joints - Floor Trusses`} />} style={{width: '40vw'}}>
                        <Line
                            xField={'Quarter'}
                            yField={'Average'}
                            seriesField={'category'}
                            lineStyle={({category}) => {
                                if(category==='minimum_required_goal' || category==='certification_minimum_goal'){
                                    return {
                                        lineDash: [4, 4]
                                    }
                                }
                            }}
                            tooltip={{
                                formatter: ({category, Average}) => ({ name: category, value: Average })
                            }}
                            data={mapData(trussSelection?.map((quarter: any) => quarter.floor.critical), false)}
                        />
                    </Card>
                </Space>
            </Panel>}
            {inspectionDetail && <Panel key={'inspectionDetail'} header={<Trans i18nKey={certificationReports.INSPECTION_DETAIL} defaults={`Inspection Detail`} />}>
                <Space>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_DISTANCE_FROM_DESIGNED_CENTER} defaults={`Average Distance From Designed Center`} />} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.DISTANCE_IN_INCHES)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: t('certification_summary.avg_distance'), value: `${Count}in` })
                            }}
                            data={inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_center_misplaced_distance,
                            }))}
                        />
                    </Card>
                    <Card title={<Trans i18nKey={certificationReports.AVERAGE_ROTATION_FROM_SPECIFIED_LOCATION} defaults={`Average Rotation From Specified Location`} />} style={{width: '40vw'}}>
                        <Column
                            color={fillColor}
                            xField={'Quarter'}
                            yField={'Count'}
                            yAxis={{
                                title: {
                                    text: `${t(certificationReports.ROTATION_IN_DEGREES)}`
                                }
                            }}
                            tooltip={{
                                formatter: ({Count}) => ({ name: t('certification_summary.avg_rotation'), value: `${Count}°` })
                            }}
                            data={inspectionDetail?.map((detail: any) => ({
                                Quarter: detail?.detailed.range,
                                Count: detail?.detailed.avg_rotation_from_specified,
                            }))}
                        />
                    </Card>
                </Space>
                <Card title={<Trans i18nKey={certificationReports.OVERVIEW_AS_PERCENTAGE} defaults={`Overview as percentage`} />}>
                    <Column
                        color={fillColor}
                        isGroup={true}
                        seriesField={'category'}
                        xField={'Quarter'}
                        yField={'Percent'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.PERCENTAGE)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({category, Percent}) => ({ name: category, value: `${Percent}%` })
                        }}
                        data={mapInspectionDetailData(inspectionDetail)}
                    />
                </Card>
                <Card title={<Trans i18nKey={certificationReports.ADDITIONAL_SQUARE_INCHES_OF_UPSIZED_PLATES} defaults={`Additional Square Inches of Upsized Plates`} />}>
                    <Column
                        color={fillColor}
                        xField={'name'}
                        yField={'count'}
                        yAxis={{
                            title: {
                                text: `${t(certificationReports.SQUARE_INCHES)}`
                            }
                        }}
                        tooltip={{
                            formatter: ({count}) => ({ name: t('certification_summary.additional_sq_inches'), value: count + ' sq in' })
                        }}
                        data={inspectionDetail?.map((detail: any) => ({
                            name: detail?.detailed.range,
                            count: detail?.detailed.additional_square
                        }))}
                    />
                </Card>
            </Panel>}
            {scoring && <Panel key={'scoring'} header={<Trans i18nKey={certificationReports.CERTIFICATION_SCORING} defaults={`Certification Scoring`} />}>
                <Score data={scoring} column={4}/>
            </Panel>}
        </Collapse>
    </Fragment>
}

export default CertificationReport