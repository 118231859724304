import React, {FunctionComponent, useState} from "react";
import {Button, Card, Input, Space, Typography} from "antd";
import Header, {HeaderProps} from "./Header";
import Extra, {ExtraProps} from "./Extra";
import {addTextButtonStyle} from "../../utils/constants";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface SectionProps extends HeaderProps, ExtraProps{
    value: string | undefined
    onChange: (value: string) => void
    btnText?: string
}

const Section: FunctionComponent<SectionProps> = ({
                                                      children,
                                                      title,
                                                      onChange,
                                                      isEdit,
                                                      value,
                                                      points,
                                                      isCheck,
                                                      onCheck,
                                                      statusValue,
                                                      onSelect,
                                                      pointValue,
                                                      onPointChange,
                                                      btnText
                                                  }) => {

    const { TextArea } = Input
    const { Text } = Typography
    const {t} = useTranslation();
    return <Card
        title={Header({title, isEdit, isCheck, onCheck, statusValue, onSelect})}
        extra={points?Extra({points, pointValue, isEdit, onPointChange}):undefined}
        bordered={false}
    >
        <div style={{margin: 10}}>
            {children}
        </div>
        <Space direction={"vertical"}>
            <Text type="secondary" style={{marginLeft: 5}}>{title} <Trans i18nKey={`comments`} defaults={`Comments`}/></Text>
            <TextArea
                style={{width: '50vw', backgroundColor: isEdit?'#EBF5FB':'#FFFFFF', color: "black"}}
                rows={4}
                placeholder={`${t('enter_the_comments_here')}`}
                value={value}
                onChange={e => isEdit && onChange(e.target.value)}
                disabled={!isEdit}
            />
            {btnText && <Button
                style={addTextButtonStyle}
                size={"small"}
                onClick={() => onChange((value || '') + btnText)}
                disabled={!isEdit}
            ><Trans i18nKey={`add_text_to`} defaults={`Add text to`}/>&nbsp;{title}</Button>}
        </Space>
    </Card>
}

export default Section
