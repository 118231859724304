import React, {Fragment} from "react";
import {Card, Collapse, Space} from "antd";
import {OverallQCInspections} from "./SummaryReport";
import {
    fillColor,
    graphValues,
    crewSummaryReport,
} from "../../utils/reportConstants";
import {Bullet, Column, Pie} from "@ant-design/plots";
import {mapTitleCrew, processTitle} from "../../utils/report";
import {Trans} from "../lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface OverviewBody {
    total: number
    out: number
    percent: number
}

interface OverviewProps {
    title: string
    data: OverviewBody
    isPDFMode?: boolean
}

export const Overview: React.FunctionComponent<OverviewProps> = ({title, data, isPDFMode}) => {
    const {total, out, percent} = data
    const {t} = useTranslation();

    return <Fragment>
        <Space>
            <Card type={"inner"} title={`${t('crew_summary.OVERVIEW', {title})}`} style={{width: isPDFMode?350:'30vw', pageBreakInside: "avoid"}}>
                <Column
                    color={fillColor}
                    style={{height: isPDFMode?350:undefined}}
                    xField={'name'}
                    yField={'count'}
                    meta={{
                        'count':{
                            alias: `${t('crew_summary.count', 'count')}`
                        }
                    }}
                    yAxis={{
                        title: {
                            text: `${t(crewSummaryReport.COUNT)}`
                        }
                    }}
                    data={[
                        {
                            name: `${t(crewSummaryReport.NO_OF_INSPECTED, {title})}`,
                            count: total
                        },
                        {
                            name: `${t(crewSummaryReport.OUT_OF_CONFORMANCE)}`,
                            count: out
                        }
                    ]}
                />
            </Card>
            <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.OVERVIEW_AS_PERCENTAGE} defaults={`Overview as percentage`} />} style={{width: isPDFMode?300:'30vw', pageBreakInside: "avoid"}}>
                <Pie
                    style={{height: isPDFMode?350:undefined}}
                    angleField={'count'}
                    colorField={'name'}
                    radius={0.8}
                    label={{
                        type: 'inner',
                        offset: '-30%',
                        content: ({ count }) => `${count}%`,
                        style: {
                            fontSize: 14,
                            textAlign: 'center',
                        },
                    }}
                    legend={{
                        layout: 'vertical',
                        position: isPDFMode?'bottom':'right'
                    }}
                    tooltip={{
                        formatter: ({name, count}) => ({ name, value: `${count}%` })
                    }}
                    data={[
                        {
                            'name': `${t(crewSummaryReport.OUT_OF_CONFORMANCE)}`,
                            'count': percent
                        },
                        {
                            'name': `${t(crewSummaryReport.PASS, {title})}`,
                            'count': parseFloat((100 - percent).toFixed(2))
                        }
                    ]}
                />
            </Card>
        </Space>
    </Fragment>
}

interface BreakdownProps {
    data: any
    isPDFMode?: boolean
}

export const Breakdown: React.FunctionComponent<BreakdownProps> = ({data, isPDFMode}) => {

    const positionOfGraphValues = 'bottom'
    const certificationReportLabelColor = '#566573'
    const {t} = useTranslation();

    return <Column
        color={fillColor}
        xField={'name'}
        yField={'count'}
        meta={{
            'count':{
                alias: `${t('crew_summary.count', 'count')}`
            }
        }}
        yAxis={{
            title: {
                text: `${t(crewSummaryReport.COUNT)}`
            }
        }}
        label={{
            content: ({percent}) => `${percent || 0}%`,
            position: positionOfGraphValues,
            style: {
                fill: certificationReportLabelColor,
                OPACITY: graphValues.OPACITY
            },
        }}
        data={Object.entries(data)?.map((field: any) => ({
            name: isPDFMode?field[0].substring(0, 3):t(mapTitleCrew(field[0])),
            count: field[1].count,
            percent: field[1].percent
        }))}
    />
}

export interface SummaryByCrew {
    id: number
    name: string
    is_active: boolean
    inspection: any
    preliminary: any
    joint: any
    member: any
    upsized: any
}

interface CrewSummaryReportProps {
    overallQCInspections: any
    crewSummary: SummaryByCrew[]
}

const CrewSummaryReport: React.FunctionComponent<CrewSummaryReportProps> = ({overallQCInspections, crewSummary}) => {

    const { Panel } = Collapse
    const {t} = useTranslation();
    return <Collapse defaultActiveKey={['overallQCInspections']}>
        {overallQCInspections && <Panel key={'overallQCInspections'} header={<Trans i18nKey={crewSummaryReport.OVERALL_QC_INSPECTIONS} defaults={`Overall QC Inspections`} />} style={{margin: 5}}>
            <OverallQCInspections overallQCInspections={overallQCInspections}/>
        </Panel>}
        {crewSummary?.map(({id, name, is_active, inspection, preliminary, joint, member, upsized}) => <Panel key={id} header={name} style={{margin: 5}}>
            <Card title={<Trans i18nKey={crewSummaryReport.TRUSSES_IN_DETAIL} defaults={'Trusses In Detail'} />}>
                <Overview title={`${t(crewSummaryReport.TRUSSES, 'Trusses')}`} data={inspection}/>
            </Card>
            <Card title={<Trans i18nKey={crewSummaryReport.PRELIMINARY_IN_DETAIL} defaults={'Preliminary In Detail'} />}>
                <Overview title={`${t(crewSummaryReport.PRELIMINARY)}`} data={preliminary}/>
                <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.PRELIMINARY_BREAKDOWN} defaults={'Preliminary Breakdown'} />}>
                    <Breakdown data={preliminary.breakdown}/>
                </Card>
            </Card>
            <Card title={<Trans i18nKey={crewSummaryReport.JOINTS_IN_DETAIL} defaults={'Joints In Detail'} />}>
                <Overview title={`${t(crewSummaryReport.JOINTS, "Joints")}`} data={joint}/>
                <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.JOINT_BREAKDOWN} defaults={"Joint Breakdown"} />}>
                    <Breakdown data={joint.breakdown}/>
                </Card>
            </Card>
            <Card title={<Trans i18nKey={crewSummaryReport.MEMBERS_IN_DETAIL} defaults={'Members In Detail'} />}>
                <Overview title={`${t(crewSummaryReport.MEMBERS)}`} data={member}/>
            </Card>
            <Card title={<Trans i18nKey={crewSummaryReport.PLATES_UPSIZED} defaults={'Plates Upsized'} />}>
                <Space>
                    <Card type={"inner"} title={<Trans i18nKey={crewSummaryReport.PLATES_UPSIZED_OVERVIEW} defaults={'Plates Upsized Overview'} />} style={{width: '30vw'}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'Total plates'}
                            xField={'title'}
                            data={[
                                {
                                    title: `${t(crewSummaryReport.PLATES_UPSIZED)}`,
                                    ranges: [upsized.total],
                                    measures: [upsized.upsized],
                                    'Total plates': upsized.total
                                }
                            ]}
                        />
                    </Card>
                    <Card type={"inner"} title={`${t(crewSummaryReport.OVERVIEW_AS_PERCENTAGE, 'Overview as percentage')}`+' %'} style={{width: '30vw'}}>
                        <Bullet
                            measureField={'measures'}
                            rangeField={'ranges'}
                            targetField={'Total plates'}
                            xField={'title'}
                            data={[
                                {
                                    title: `${t(crewSummaryReport.PLATES_UPSIZED)}`,
                                    ranges: [100],
                                    measures: [upsized.percent],
                                    'Total plates': 100
                                }
                            ]}
                        />
                    </Card>
                </Space>
            </Card>
        </Panel>)}
    </Collapse>
}

export default CrewSummaryReport