import React, {Fragment, useEffect, useState} from "react";
import {companyAcquire, configs, fetch_locations} from "../api";
import {Form} from "@q4us-sw/q4us-ui";
import {Modal, notification, PageHeader, Typography} from "antd";
import {FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {Trans} from "./lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface CompanyAcquisitionBody {
    acquirer_company: string,
    acquired_company: string
}

export const CompanyAcquisition: React.FunctionComponent = () => {
    const [config,setConfig] = React.useState([] as FormElementProps[])
    const [opt, setOpt] = useState<{acquirer_company?: string, acquired_company?: string, open: boolean}>({open: false})
    const {t} = useTranslation()

    const load = async () => {
        const res = await configs('company_acquisition')
        setConfig(res.data?.rows || [])
    }

        const fetchLocations = async(acquired_company: string) =>{
            const id = parseInt(opt.acquired_company || "")
            const {data: {rows = []} = {}} = await fetch_locations(id);
            let inactiveLocations = false;
            if (rows === null || rows.length === 0) {
                inactiveLocations = true;
            }
            return inactiveLocations;
        }

    const getCompanyName = (id: string) => {
        return config?.[0].enums?.find((element:[string, string]) => element[0] === id)?.[1] || '';
    }

    useEffect(() => {
        load()
    }, [])

    const handleOk = async () => {
        if (opt.acquirer_company && opt.acquired_company) {
            const inactiveLocationsValue = await fetchLocations(opt.acquired_company)
            if (opt.acquirer_company === opt.acquired_company) {
                notification.warning({message: <Trans i18nKey={`company_acquire_page.company_acquired_company_not_same`} defaults={`Acquire company and the acquired company cannot be the same`} />})
                setOpt({...opt, open: false})
            }
            else if (inactiveLocationsValue) {
                notification.warning({message: <Trans i18nKey={`company_acquire_page.company_has_no_location`} defaults={`Company doesn’t have any active locations. Please add at least one active location before acquiring`} />})
                setOpt({...opt, open: false})
            } else {
                const {data: {statusCode} = {}} = await companyAcquire({
                    acquirer_company: opt.acquirer_company,
                    acquired_company: opt.acquired_company
                })
                if (statusCode === 200) {
                    notification.success({
                        message: `${getCompanyName(opt.acquired_company)} ${t('company_acquire_page.acquired_by')} ${getCompanyName(opt.acquirer_company)}`
                    })
                    setOpt({...opt, open: false})
                    load()
                }
            }
        }
    }

    const handleCancel = () => {
        setOpt({...opt, open: false})
    }

    const {Text} = Typography;
    return <Fragment>
        <PageHeader title={<Trans i18nKey={`company_acquire_page.company_acquisition`} defaults={`Company Acquisition`}/>}/>
        <Form
            schema={'company_acquisition'}
            layout={'inline'}
            config={async () => {
                return config
            }}
            submit={async (values: CompanyAcquisitionBody) => {
                setOpt({...values, open: true})
                return true
            }}
        />
        <Modal title={<Trans i18nKey={`company_acquire_page.conformation`} defaults={`Conformation`}/>}
               visible={opt.open}
               onOk={handleOk}
               onCancel={handleCancel}
               okText={`${t('company_acquire_page.ok')}`}
               cancelText={`${t('company_acquire_page.cancel')}`}>
            {opt.acquired_company && opt.acquirer_company && <Fragment>
                <Text><Trans i18nKey={`company_acquire_page.please_confirm_to_acquire`} defaults={`Please confirm to
                    acquire`} /> </Text><Text strong>{getCompanyName(opt.acquired_company)}</Text><Text> <Trans i18nKey={`company_acquire_page.by`} defaults={`by`} /> </Text>
                   <Text strong>{getCompanyName(opt.acquirer_company)}</Text>
            </Fragment>}
        </Modal>
    </Fragment>
}