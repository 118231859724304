import React from "react";
import {AuthContext} from "./user/AuthProvider";
import {Page, Response, TableContext, TableV2} from "@q4us-sw/q4us-ui/lib/TableV2";
import {configs, download_crash_report, fetch, update} from "../api";
import {Button, Col, Modal, notification, PageHeader, Row, Space, Typography} from "antd";
import {MapToFilter} from "./filters/mapping";
import {TableFilter} from "./filters/TableFilter";
import {getOrdering} from "./filters/ordering";
import {Form} from "@q4us-sw/q4us-ui";
import {CustomFormInput, FormElementProps} from "@q4us-sw/q4us-ui/lib/Form";
import {FormInstance} from "antd/lib/form/hooks/useForm";
import {unstable_batchedUpdates} from "react-dom";
import {AxiosResponse} from "axios";
import {handleScroll, validateVersion} from "../utils/util";
import moment from "moment";
import { ConfigsContext } from "./configs/ConfigsProvider";
import {DateRangePickerFormatted} from "./lib/DatePickerFormatted";
import {Trans} from "./lib/CustomTransComponent";
import {useTranslation} from "react-i18next";

interface UpdateStatusProps{
    title: string | React.ReactNode,
    btnText: string | React.ReactNode,
    schema: string,
    tableName: string,
    fetchData: (values: {[key:string]: any}, page?: Page, {column, direction}?:{column:string, direction:string}) => Promise<AxiosResponse<any>>,
    row: any
}

const UpdateStatus:React.FunctionComponent<UpdateStatusProps> = (props) =>{
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    return <TableContext.Consumer>{
        table=><React.Fragment>
            <Button style={{ margin: 10 }} onClick={()=>{setIsModalVisible(true)}}>{props.btnText}</Button>
            <Modal
                title={props.title}
                width={'50vw'}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                destroyOnClose
            >
                <Form
                        schema={props.schema}
                        initialValue={props.row}
                        config={async (schema:any) => {
                            const res = await configs(props.schema)
                            return res.data.rows;
                        }}
                        validateField={async  (rule:any, value:any, schema:string, column:FormElementProps, form:FormInstance<any>) =>{
                            if (value!=null){
                                if (column.name === 'jira_link'){
                                    if (value && !value.includes('atlassian')){
                                        return Promise.reject(<Trans i18nKey={`error_report_page.invalid_jira_link`} defaults={`Invalid JIRA Link`} />)
                                    }
                                }
                                else if (column.name === 'fix_version'){
                                    if (!validateVersion(value)){
                                        return Promise.reject(<Trans i18nKey={`error_report_page.invalid_version_number`} defaults={`Invalid version number`} />)
                                    }
                                }
                            }
                            return Promise.resolve()
                        }}
                        disabledValidator={(schema: string, element: FormElementProps, value: FormInstance<any>)=>{
                            if (element.name === 'id'){
                                return true;
                            }
                        }}
                        overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                            if (element.name === 'status') {
                                let availableStatus:[string, string][];
                                switch (props.row.status){
                                    case 'OPEN':
                                        availableStatus = [['OPEN', 'OPEN'], ['IN PROGRESS', 'IN PROGRESS'], ['RESOLVED', 'RESOLVED'], ['CLOSED', 'CLOSED']];
                                        break;
                                    case 'IN PROGRESS':
                                    case 'RESOLVED':
                                    case 'RE OPENED':
                                        availableStatus = [['IN PROGRESS', 'IN PROGRESS'], ['RESOLVED', 'RESOLVED'], ['CLOSED', 'CLOSED'],['RE OPENED', 'RE OPENED']];
                                        break;
                                    case 'CLOSED':
                                        availableStatus = [['RE OPENED', 'RE OPENED'], ['CLOSED', 'CLOSED']];
                                        break;
                                    default:
                                        availableStatus = [['OPEN', 'OPEN'], ['IN PROGRESS', 'IN PROGRESS'], ['RESOLVED', 'RESOLVED'], ['RE OPENED', 'RE OPENED'], ['CLOSED', 'CLOSED']];
                                }
                                // @ts-ignore
                                return  <CustomFormInput
                                    type={'STRING'}
                                    name={element.name}
                                    schema={props.schema}
                                    enums={availableStatus}
                                />
                            }
                        }
                        }
                        submit={async (values:any) => {
                            const response = await update({
                                tableName: props.tableName,
                                data: {...values, id: props.row.id}
                            })
                            if(response?.data?.statusCode===200){
                                notification.success({message: <Trans i18nKey={`error_report_page.success`} defaults={`Success`}/>})
                                table?.setLoading(true);
                                const res = await props.fetchData(table?.customData?.values || {}, table?.page, table?.customData?.inbuilt?.sort)
                                unstable_batchedUpdates(() => {
                                    setIsModalVisible(false);
                                    table?.setData(res.data?.rows || []);
                                    table?.setLoading(false);
                                    table?.setPage({
                                        current: res.data?.page?.current ?? table?.page?.current ?? 0,
                                        total: res.data?.page?.total ?? table?.page?.total ?? 0,
                                        size: res.data?.page?.size ?? table?.page?.size ?? 100
                                    });
                                })
                                return true
                            }else{
                                notification.error({message: response?.data?.message || <Trans i18nKey={`error_report_page.error`} defaults={`Error`}/>})
                            }
                        }}
                    />
            </Modal>
        </React.Fragment>
    }</TableContext.Consumer>
}

export const CrashReports:React.FunctionComponent = (props) => {
    const {t} = useTranslation()

    const fetchData = async (values: {[key:string]: any}, page?: Page, {column='id', direction='desc'}:{column?:string, direction?:string}={})=>{
        const filters = MapToFilter(values)
        const orderBy = getOrdering(column, direction);
        return await fetch({
            tableName: 'error_report_view',
            filter:[...filters],
            orderBy: orderBy,
            page: { size: page?.size || 100, from: ((page?.current || 1) - 1) * (page?.size || 100)}
        }) || {}
    }

    return <React.Fragment>
        <AuthContext.Consumer>{auth =>
            <TableV2
                header={
                    <div style={{textAlign: 'right', paddingRight: 7, width: '100%'}}>
                        <Row>
                            <Col span={12}>
                                <PageHeader title={<Trans i18nKey={`error_report_page.header`} defaults={`Crash Reports`} />}/>
                            </Col>
                        </Row>
                        <TableFilter
                            schema={'error_report_filter'}
                            fetch={fetchData}
                            overrideComponent={(schema: string, element: FormElementProps, form: FormInstance<any>) => {
                                if (element.name === 'timestamp') {
                                    return <DateRangePickerFormatted/>
                                } else {
                                    return undefined
                                }
                            }}
                        />
                    </div>
                }
                schema={'error_report'}
                maxColumnWidth={400}
                fetchConfig={async (schema) => {
                    const response = await configs(schema)
                    response.data.rows = response.data.rows.map((row: any) => {
                        if (row.name) {
                            row.title = <Trans i18nKey={`error_report.${row.name}`} defaults={row.title}/>;
                        }
                        return row;
                    });
                    return  response
                }}
                fetchData={async (request) => {
                    handleScroll()
                    const response = await fetchData(request.options?.values || {}, request.page, request.options.inbuilt?.sort)
                    return response as Response
                }}
                scroll={{y: 'calc(100vh - 441px)', x: '100%'}}
                customRenderer={{
                    status:(value, row, index, column) => {
                        return <Trans i18nKey={`error_report.${value}`} defaults={value}/>
                    },
                    download: (value, row, index, column) => {
                        return <Space>
                            <Button onClick={async () => {await download_crash_report(row.id, row.file_name)}}><Trans i18nKey={`error_report_page.download`} defaults={`Download`} /></Button>
                            <UpdateStatus
                                row={row}
                                title={<Trans i18nKey={`error_report_page.update_status_title`} defaults={`Update Status`} />}
                                btnText={<Trans i18nKey={`error_report_page.update_status`} defaults={`Update Status`} />}
                                schema={'error_report_update_status'}
                                tableName={'error_report'}
                                fetchData={fetchData}
                            />
                        </Space>
                    },
                    timestamp: (value, row, index, column) => {
                        return <ConfigsContext.Consumer>{configs=><Typography.Text>{moment(value).format(configs.dateFormat)}</Typography.Text>}</ConfigsContext.Consumer>
                    }
                }}
            />
        }</AuthContext.Consumer>
    </React.Fragment>
}

export default CrashReports

